import { requestGet } from "../utils/dva16";
import { NStream, EGetCollect, EGet, EGetOne, RSetState } from "./contants";

const Stream = {
	namespace: NStream,
	state: {
		loading: false,
		gameStreamList: null,
		StreamList: null,
		StreamCollect: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGetCollect](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("stream/nikoIndex", payload);
			reducer(RSetState, { StreamCollect: res, loading: false });
		},

		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("stream/rankList", payload);
			reducer(RSetState, { StreamList: res, loading: false });
		},
		async [EGetOne]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("gameStreaming", payload);
			reducer(RSetState, { gameStreamList: res, loading: false });
		},
	},
};
export default Stream;
