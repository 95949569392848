import { Button, Empty } from "antd";

//获取本地存储中的“xf3Token”值，接口请求时需要携带token | Obtain the "xf3Token" value in local storage, which needs to be carried with the token during interface requests
export function getToken() {
	return localStorage.getItem("xf3Token");
}
//将传入的“token”保存到本地存储的“xf3Token”中，以便在接口请求时使用 | Save the incoming 'token' to the locally stored 'xf3Token' for use in interface requests
export function setToken(token) {
	return localStorage.setItem("xf3Token", token);
}

export function customizeRenderEmpty() {
	return (
		<div style={{ textAlign: "center" }}>
			<Empty
				image={require("@/assets/images/check_you.png").default}
				imageStyle={{
					height: 60,
				}}
				description={
					<span style={{ fontSize: "1.2rem" }}>
						Your subscription does not include access to this service. Please
						contact support@nikopartners.com for more information.
					</span>
				}
			>
				{/* <Button
					type="primary"
					onClick={() => {
						history.push(`/user/user_profile`);
					}}
				>
					to Personal Center
				</Button> */}
			</Empty>
		</div>
	);
}

export const priceSplitter = (number) =>
	(number && number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")) || "0";
