import {
	requestGet,
	requestPost,
	requestPut,
	requestDelete,
} from "../utils/dva16";
import {
	NRevenueOverAll,
	EGet,
	EGetCollect,
	EGetTop,
	RSetState,
	EGetDetail,
} from "./contants";

const RevenueOverAll = {
	namespace: NRevenueOverAll,
	state: {
		RevenueOverAllList: null,
		RevenueOverCompanyDetailList: null,
		revenueList: null,
		RevenueOverAllHomeList: null,
		loading: false,
		page: null,
		perPage: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("organization", payload);
			reducer(RSetState, { RevenueOverAllList: res, loading: false });
		},
		async [EGetDetail](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("revenueOverAll", payload);
			reducer(RSetState, { RevenueOverCompanyDetailList: res, loading: false });
		},
		async [EGetCollect](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("revenuePage", payload);
			reducer(RSetState, {
				revenueList: res,
				loading: false,
				page: res?.page,
				perPage: res?.perPage,
			});
		},
		async [EGetTop]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("revenueIndex", payload);
			reducer(RSetState, { RevenueOverAllHomeList: res, loading: false });
		},
	},
};
export default RevenueOverAll;
