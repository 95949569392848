import React, { useEffect } from "react";
import moment from "moment";
import "./index.less";
import { Button, Row, Col, Typography, Tag, Empty, Spin, Collapse } from "antd";
import { effect, useStore, reducer } from "../../../utils/dva16";
import formatDate from "@/utils/dateFormater";
import defaultLogo from "../../../assets/images/game/defaul-logo_game@2x.png";
import CustomEmpty from "@/utils/CustomEmpty";
import {
	EGet,
	EGetOne,
	NInsightsFeed,
	NGame,
	RSetState,
	NExclusives,
	MName,
} from "../../../models/contants";
import { Helmet } from "react-helmet";
const { Link } = Typography;
const { Panel } = Collapse;

export default (props) => {
	const { id } = props.match.params;
	const { history } = props;
	const { GameDetail, loading } = useStore(NGame);
	const { EsportsList, InsightsFeedList } = useStore(NInsightsFeed);
	const { exclusivesList } = useStore(NExclusives);

	const customizeRenderEmpty = () => (
		<div style={{ textAlign: "center" }}>
			<Empty
				image={require("@/assets/images/check_you.png")?.default}
				imageStyle={{
					height: 60,
				}}
				description={<span>Please check your personal</span>}
			>
				<Button
					type="primary"
					onClick={() => {
						history.push(`/user/user_profile`);
					}}
				>
					to Personal Center
				</Button>
			</Empty>
		</div>
	);
	useEffect(() => {
		effect(MName, EGet, {
			modelName: GameDetail?.gameEn,
		});
	}, [GameDetail?.gameEn]);
	useEffect(() => {
		effect(NGame, EGetOne, { id });
		effect(NInsightsFeed, EGetOne, {
			type: "Esports",
			game: id,
			page: 1,
			perPage: 5,
		});
		effect(NInsightsFeed, EGet, {
			game: id,
			page: 1,
			perPage: 5,
		});

		return () => {
			reducer(NGame, RSetState, {
				GameDetail: null,
			});
			reducer(NInsightsFeed, RSetState, {
				EsportsList: null,
			});
		};
	}, [id]);
	useEffect(() => {
		//组件渲染时滚动到页面的顶部
		window.scrollTo(0, 0);
	}, []);
	// const date = new Date(record?.startDate);
	// const formattedDate = formatDate(date);
	return (
		<div className="GameDetail_Box GridBox wd100" style={{ padding: "20px 0" }}>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<Spin spinning={loading} size="large">
				<div className="GameBox mt50">
					<Row className="RowBox mt30">
						<Col span={24}>
							<div className="Game_Detail_Top">
								<div
									style={{ textAlign: GameDetail?.logo ? "" : "center" }}
									className="Game_Pic"
								>
									<img
										style={{
											width: GameDetail?.logo ? "" : "200px",
											height: GameDetail?.logo ? "" : "200px",
										}}
										className="Tabel_Company_Logo"
										src={GameDetail?.logo ? GameDetail?.logo : defaultLogo}
										alt={GameDetail?.name}
									/>
								</div>
								<div className="Game_Info">
									<div className="Game_Info_Title">
										<h2>{GameDetail?.gameEn}</h2>
										<div className="Game_Info_Tag_List">
											{GameDetail?.withPlatfrom?.map((item, index) => {
												return (
													<Tag color="#f50" key={index}>
														{item?.platform}
													</Tag>
												);
											})}
											{GameDetail?.withDeveloper?.map((item, index) => {
												return (
													<Tag color="#9E219E" key={index}>
														{item?.name}
													</Tag>
												);
											})}
											{GameDetail?.withPublisher?.map((item, index) => {
												return (
													<Tag color="#9E219E" key={index}>
														{item?.name}
													</Tag>
												);
											})}
										</div>
									</div>
									<div className="Game_Info_Link ">
										<h3>{GameDetail?.gameCn ? GameDetail?.gameCn : ""}</h3>
										<Link href={GameDetail?.website || "#"} target="_blank">
											{GameDetail?.website || "-"}
										</Link>
									</div>
									<div
										className="Game_Info_Amot BorderBox"
										style={{ marginBottom: "1rem" }}
									>
										<div className="Game_Info_Amot_Label">
											<div>
												<p>Game Publishers:</p>
												<p className="Game_Info_Blink FCblue">
													{GameDetail?.withPublisher
														?.map((item, index) => {
															return item?.name;
														})
														?.join(", ")}
												</p>
											</div>
											<div>
												<p>Game Developers:</p>
												<p className="Game_Info_Blink FCblue">
													{GameDetail?.withDeveloper
														?.map((item, index) => {
															return item?.name;
														})
														?.join(", ")}
												</p>
											</div>
											<div>
												<p>Genres:</p>
												<p className="Game_Info_Blink FCblue">
													{/* {Array.isArray(GameDetail?.genre) &&
														GameDetail?.genre?.join(", ")} */}
													{Array.isArray(GameDetail?.genre) &&
														GameDetail?.genre
															?.map((item, index) => {
																return item;
															})
															?.join(", ")}
												</p>
											</div>
											<div>
												<p>Platforms:</p>
												<p className="Game_Info_Blink FCblue">
													{Array.isArray(GameDetail?.platform) &&
														GameDetail?.platform
															?.map((item, index) => {
																return item;
															})
															?.join(", ")}
												</p>
											</div>
											<div>
												<p>Status:</p>
												<p className="Game_Info_Blink FCblue">
													{GameDetail?.status}
												</p>
											</div>
										</div>

										<div className="Game_Info_Amot_Label"></div>
									</div>
									{/* ISBN Games */}
									{GameDetail?.withIsbn?.length > 0 && (
										<Collapse className="faqAnswer">
											<Panel header={"ISBN"}>
												{GameDetail?.withIsbn?.map((i) => {
													const date = new Date(i?.date);
													const formattedDate = formatDate(date);
													return (
														<div className="Game_Info_Amot BorderBox">
															<div className="Game_Info_Amot_Label">
																<div>
																	<p>ISBN Number:</p>
																	<p className="Game_Info_Blink">{i?.ISBN}</p>
																</div>
																<div>
																	<p>ISBN Approval Date:</p>
																	<p className="Game_Info_Blink">
																		{formattedDate}
																	</p>
																</div>
																<div>
																	<p>China License Type:</p>
																	<p className="Game_Info_Blink">
																		{i?.lisenceArea}
																	</p>
																</div>
																{i?.lisenceArea == "Import" && (
																	<div>
																		<p>Import Area:</p>
																		<p className="Game_Info_Blink">
																			{Array.isArray(i?.importArea)
																				? i?.importArea?.join(", ")
																				: i?.importArea}
																		</p>
																	</div>
																)}
																{i?.lisenceArea == "Import" && (
																	<div>
																		<p>Import Region:</p>
																		<p className="Game_Info_Blink">
																			{Array.isArray(i?.importRegion)
																				? i?.importRegion?.join(", ")
																				: i?.importRegion}
																		</p>
																	</div>
																)}
																<div>
																	<p>Licensed Platform:</p>
																	<p className="Game_Info_Blink">
																		{Array.isArray(i?.licensedPlatform)
																			? i?.licensedPlatform?.join(", ")
																			: i?.licensedPlatform}
																	</p>
																</div>
																<div>
																	<p>Licensed Status:</p>
																	<p className="Game_Info_Blink">
																		{i?.licensedStatus == 1
																			? "Active"
																			: i?.licensedStatus == 0
																			? "Inactive"
																			: ""}
																	</p>
																</div>
															</div>
														</div>
													);
												})}
											</Panel>
										</Collapse>
									)}
								</div>
							</div>
						</Col>
					</Row>
				</div>
				{/* Insights */}
				<div className="BlogBox mt20" id="insightsSection">
					<div className="RowBox BorderBox">
						<div className="Global_title">
							<h2>Insights</h2>

							{InsightsFeedList?.data?.length > 3 && (
								<div
									className="right_more"
									onClick={() => {
										history.push({
											pathname: "/news_articles/VANA",
											state: { game: id, name: GameDetail?.gameEn },
										});
									}}
								>
									View more &gt;&gt;
								</div>
							)}
						</div>
						<div className="Insights_List">
							<ul className="ProlistBox">
								{InsightsFeedList?.error == 1009 ||
									InsightsFeedList?.total > 0 || <CustomEmpty />}

								{InsightsFeedList?.data && InsightsFeedList?.error != 1009
									? InsightsFeedList?.data?.slice(0, 3)?.map((vo, i) => {
											const date = new Date(vo?.date);
											const formattedDate = formatDate(date);
											return (
												<li key={i}>
													<div className="insight_list_cont">
														<h3>
															<div>
																<span
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: { type: vo?.type },
																		});
																	}}
																>
																	[{vo?.type?.trim()}]
																</span>
																<div
																	className="insight_list_title"
																	onClick={() => {
																		history.push({
																			pathname: `/news_articles/vana/details/${vo?.id}`,
																		});
																	}}
																>
																	{vo?.title}
																</div>
															</div>

															<div className="insight_list_date_style">
																{formattedDate}
															</div>
														</h3>
														<div className="Tag_List_Box">
															{vo?.withCompanys?.map((vi, ii) => (
																<Tag
																	key={ii}
																	color="#9E219E"
																	onClick={() => {
																		history.push(
																			`/games_organizations/organizations/details/${vi?.id}`
																		);
																	}}
																	style={{
																		cursor: "pointer",
																		borderRadius: "20px",
																	}}
																>
																	{vi?.name}
																</Tag>
															))}
															{vo?.withGames?.map((vi, iii) => (
																<Tag
																	key={iii}
																	color="#19AE54"
																	onClick={() => {
																		history.push(
																			`/games_organizations/games/details/${vi?.id}`
																		);
																	}}
																	style={{
																		cursor: "pointer",
																		borderRadius: "20px",
																	}}
																>
																	{vi?.gameEn}
																</Tag>
															))}
														</div>
														<div
															className="insght_lst_info"
															onClick={() => {
																history.push({
																	pathname: `/news_articles/vana/details/${vo?.id}`,
																});
															}}
														>
															{vo?.content?.replace(/<[^>]+>/g, "")}
														</div>
													</div>
												</li>
											);
									  })
									: customizeRenderEmpty()}
							</ul>
						</div>
					</div>
				</div>
				{/* NikoIQ Exclusives */}
				<div className="BlogBox mt20" id="exclusiveSection">
					<div className="RowBox BorderBox">
						<div className="Global_title">
							<h2>NikoIQ Exclusives</h2>
							{GameDetail?.exclusives?.length > 3 && (
								<div
									className="right_more"
									onClick={() => {
										history.push({
											pathname: "/nikoiq_exclusives",
											state: { game: id, name: GameDetail?.gameEn },
										});
									}}
								>
									View more &gt;&gt;
								</div>
							)}
						</div>
						<div className="Insights_List">
							<ul className="ProlistBox">
								{/* {GameDetail?.exclusives?.length < 0 || <CustomEmpty/>} */}
								{
									GameDetail?.exclusives &&
									GameDetail?.exclusives?.length > 0 ? (
										GameDetail?.exclusives?.slice(0, 3)?.map((vo, i) => {
											const date = new Date(vo?.date);
											const formattedDate = formatDate(date);
											return (
												<li key={i}>
													<div className="insight_list_cont">
														<h3>
															<div style={{ display: "block" }}>
																{/* <span
																onClick={() => {
																	history.push({
																		pathname: "/nikoiq_exclusives",
																		state: { type: vo?.type },
																	});
																}}
															>
																[{vo?.status?.trim()}]
															</span> */}
																{/* <div
																className="insight_list_title"
																style={{ display: "block" }}
																onClick={() => {
																	history.push({
																		pathname: "/nikoiq_exclusives",
																		state: { game: GameDetail?.id },
																	});
																}}
															>
																{vo?.subHeader}
															</div> */}
																<div
																	className="insight_list_title"
																	onClick={() => {
																		history.push({
																			pathname: "/nikoiq_exclusives",
																			state: { game: GameDetail?.id },
																		});
																	}}
																>
																	{vo?.title}
																</div>
															</div>

															<div className="insight_list_date_style">
																{formattedDate}
															</div>
														</h3>
														<div className="Tag_List_Box">
															{vo?.withCompanys?.map((vi, ii) => (
																<Tag
																	key={ii}
																	color="#9E219E"
																	onClick={() => {
																		history.push(
																			`/games_organizations/organizations/details/${vi?.id}`
																		);
																	}}
																	style={{
																		cursor: "pointer",
																		borderRadius: "20px",
																	}}
																>
																	{vi?.name}
																</Tag>
															))}
															{vo?.withGames?.map((vi, iii) => (
																<Tag
																	key={iii}
																	color="#19AE54"
																	onClick={() => {
																		history.push(
																			`/games_organizations/games/details/${vi?.id}`
																		);
																	}}
																	style={{
																		cursor: "pointer",
																		borderRadius: "20px",
																	}}
																>
																	{vi?.gameEn}
																</Tag>
															))}
														</div>
														<div
															className="insght_lst_info"
															onClick={() => {
																history.push({
																	pathname: "/nikoiq_exclusives",
																	state: { game: GameDetail?.id },
																});
															}}
														>
															{vo?.subHeader?.replace(/<[^>]+>/g, "")}
														</div>
													</div>
												</li>
											);
										})
									) : (
										<CustomEmpty />
									)
									// customizeRenderEmpty()
								}
							</ul>
						</div>
					</div>
				</div>
				{/* Esport events */}
				<div className="BlogBox mt20" id="esportsSection">
					<div className="RowBox BorderBox">
						<div className="Global_title">
							<h2>Esports</h2>
							{EsportsList?.data?.length > 3 && (
								<div
									className="right_more"
									onClick={() => {
										history.push({
											pathname: "/esports",
											state: { game: id, name: GameDetail?.gameEn },
										});
									}}
								>
									View more &gt;&gt;
								</div>
							)}
						</div>
						<div className="Insights_List">
							<ul className="ProlistBox">
								{EsportsList?.error == 1009 || EsportsList?.total > 0 || (
									<CustomEmpty />
								)}
								{EsportsList?.data && EsportsList?.error != 1009
									? EsportsList?.data?.slice(0, 3)?.map((vo, i) => {
											const date = new Date(vo?.date);
											const formattedDate = formatDate(date);
											return (
												<li key={i}>
													<div className="insight_list_cont">
														<h3>
															<div>
																<span
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: { type: vo?.type },
																		});
																	}}
																>
																	[{vo?.type?.trim()}]
																</span>
																<div
																	className="insight_list_title"
																	onClick={() => {
																		history.push({
																			pathname: `/news_articles/vana/details/${vo?.id}`,
																		});
																	}}
																>
																	{vo?.title}
																</div>
															</div>

															<div className="insight_list_date_style">
																{formattedDate}
															</div>
														</h3>
														<div className="Tag_List_Box">
															{vo?.withCompanys?.map((vi, ii) => (
																<Tag
																	key={ii}
																	color="#9E219E"
																	onClick={() => {
																		history.push(
																			`/games_organizations/organizations/details/${vi?.id}`
																		);
																	}}
																	style={{
																		cursor: "pointer",
																		borderRadius: "20px",
																	}}
																>
																	{vi?.name}
																</Tag>
															))}
															{vo?.withGames?.map((vi, iii) => (
																<Tag
																	key={iii}
																	color="#19AE54"
																	onClick={() => {
																		history.push(
																			`/games_organizations/games/details/${vi?.id}`
																		);
																	}}
																	style={{
																		cursor: "pointer",
																		borderRadius: "20px",
																	}}
																>
																	{vi?.gameEn}
																</Tag>
															))}
														</div>
														<div
															className="insght_lst_info"
															onClick={() => {
																history.push({
																	pathname: `/news_articles/vana/details/${vo?.id}`,
																});
															}}
														>
															{vo?.content?.replace(/<[^>]+>/g, "")}
														</div>
													</div>
												</li>
											);
									  })
									: customizeRenderEmpty()}
							</ul>
						</div>
					</div>
				</div>
			</Spin>
		</div>
	);
};
