import React, { useState, useEffect } from "react";
import moment from "moment";
import { debounce } from "lodash";
import "../Profile/index.less";
import "./index.less";
import  CustomEmpty  from "@/utils/CustomEmpty";
import {
	Button,
	Row,
	Col,
	Space,
	Table,
	Form,
	Select,
	Empty,
	Spin,
} from "antd";
import sortData from "../../../utils/sorter";
import { effect, useStore } from "../../../utils/dva16";
import {
	EGet,
	EGetCollect,
	EGetCompany,
	EGetTop,
	NMAIPO,
	NOrganizationMulti,
	NRegion,
	EGetArea,
} from "../../../models/contants";
import { Column } from "@ant-design/charts";
import E from "../../../models/E";
import { customizeRenderEmpty } from "../../../utils/utils";
import { Helmet } from "react-helmet";
const { Option } = Select;
export default (props) => {
	const { history } = props;
	const {
		location: { state },
	} = history;
	const { areaList, commonAreaList } = useStore(NRegion);
	const [form] = Form.useForm();
	const { OrganizationCompany } = useStore(NOrganizationMulti);
	const { MAIPOList, MAIPOTitle, ipoTopList, loading } = useStore(NMAIPO);
	const [searchValues, setSearchValues] = useState();
	const [sortState, setSortState] = useState(null);
	let sortType = {
		ascend: "asc",
		descend: "desc",
	};

	const EsportsColumn = () => {
		const [columnConfig, setColumnConfig] = useState({
			data: [],
			xField: "datetime",
			yField: "monthCount",
			appendPadding: 30,
			label: {
				position: "middle",
				style: {
					fill: "#FFFFFF",
					opacity: 0.6,
				},
			},
			xAxis: {
				label: {
					autoHide: true,
					autoRotate: false,
				},
			},
			tooltip: false,
			meta: {
				type: {
					alias: "datetime",
				},
				sales: {
					alias: "monthCount",
				},
			},
		});
		useEffect(() => {
			if (MAIPOTitle?.monthCount) {
				columnConfig.data = MAIPOTitle?.monthCount;
				setColumnConfig(Object.assign({}, columnConfig));
			}
		}, [MAIPOTitle]);
		return <Column {...columnConfig} />;
	};
	const columns = [
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (text) => <span>{text ?? "-"}</span>,
			sortOrder: sortState?.field == "date" && sortState?.order,
			sorter: (a, b) => {
				const dateA = new Date(a?.date);
				const dateB = new Date(b?.date);
				return dateA - dateB;
			},
		},
		{
			title: "Region",
			dataIndex: "region",
			key: "region",
			render: (text, record) => (
				<div>
					{record?.region?.length
						? record?.region?.map((vo, io) => <div key={io}>{vo}</div>)
						: "-"}
				</div>
			),
			sortOrder: sortState?.field == "region" && sortState?.order,
			sorter: (a, b) =>
				a?.region && b?.region && a?.region?.[0]?.localeCompare(b?.region?.[0]),
		},
		{
			title: "Type",
			dataIndex: "events",
			key: "events",
			render: (text) => <span>{text ?? "-"}</span>,
			sortOrder: sortState?.field == "events" && sortState?.order,
			sorter: (a, b) => a?.events?.localeCompare(b?.events),
		},
		{
			title: "Investor/Buyer",
			dataIndex: "investorName",
			key: "investorName",
			align: "center",
			render: (text) => <span>{text ?? "-"}</span>,
			sortOrder: sortState?.field == "investorName" && sortState?.order,
			sorter: (a, b) => a?.investorName?.localeCompare(b?.investorName),
		},
		{
			title: "Target Company",
			dataIndex: "object",
			key: "object",
			render: (text) => <span>{text ?? "-"}</span>,
			sortOrder: sortState?.field == "object" && sortState?.order,
			sorter: (a, b) => a?.object?.localeCompare(b?.object),
		},
		{
			title: "Core Business",
			dataIndex: "mainBusiness",
			key: "mainBusiness",
			render: (text) => <span>{text ?? "-"}</span>,
			sortOrder: sortState?.field == "mainBusiness" && sortState?.order,
			sorter: (a, b) => a?.mainBusiness?.localeCompare(b?.mainBusiness),
		},
		{
			title: "Key products",
			dataIndex: "keyProduct",
			key: "keyProduct",
			render: (text) => <span>{text ?? "-"}</span>,
			sortOrder: sortState?.field == "keyProduct" && sortState?.order,
			sorter: (a, b) => a?.keyProduct?.localeCompare(b?.keyProduct),
		},
		{
			title: "Value mm USD",
			dataIndex: "value",
			key: "value",
			render: (text) => (
				<>{text?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
			),
			sortOrder: sortState?.field == "value" && sortState?.order,
			sorter: (a, b) => a?.value?.localeCompare(b?.value),
		},
		{
			title: "Series ",
			dataIndex: "round",
			key: "round",
			render: (text) => <span>{text ?? "-"}</span>,
			sortOrder: sortState?.field == "round" && sortState?.order,
			sorter: (a, b) => a?.round?.localeCompare(b?.round),
		},
	];

	const top5columns = [
		{
			title: "Rank",
			dataIndex: "Rank",
			key: "Rank",
			align: "center",
			render(text, value, index) {
				return <div className={`rank rank${index + 1}`}>{index + 1}</div>;
			},
		},
		{
			title: "Investor/Buyer",
			dataIndex: "investorName",
			key: "investorName",
			render: (text) => <>{text || "-"}</>,
		},
		{
			title: "Number of Investments",
			dataIndex: "count",
			key: "count",
			align: "center",
			render: (text) => (
				<>{(text || "-")?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
			),
		},
		{
			title: "Investment Value mm USD",
			dataIndex: "total",
			key: "total",
			align: "center",
			render: (text) => (
				<>
					{(text || "-")
						?.toFixed()
						?.toString()
						?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
				</>
			),
		},
	];
	const pagination = {
		current: parseInt(MAIPOList && MAIPOList?.page) || 1,
		total: (MAIPOList && MAIPOList?.total) || 0,
		defaultCurrent: 1,
		defaultPageSize: parseInt(MAIPOList && MAIPOList?.perPage) || 10,
		pageSize: MAIPOList && MAIPOList?.perPage,
		pageSizeOptions: ["10", "20", "30", "50", "100"],
		showQuickJumper: true,
		showSizeChanger: true,
		position: "bottom",
	};
	useEffect(() => {
		if (state) {
			form.setFieldsValue({
				...state,
			});
			if (state?.companyId) {
				form.setFieldsValue({
					companyId: state?.companyId,
				});
			}
		}
		effect(NMAIPO, EGet, { page: 1, perPage: 10, ...state });
		effect(NMAIPO, EGetCollect, {});
		effect(NMAIPO, EGetTop, { page: 1, perPage: 5 });
		effect(NOrganizationMulti, EGetCompany, { page: 1, perPage: 10 });
		effect(NRegion, EGetArea, { page: 1, perPage: 200 });
		return () => {};
	}, []);
	const onFinish = (values) => {
		values.startDate = values?.date
			? moment(values?.date[0])?.format("YYYY-MM-DD")
			: null;
		values.endDate = values?.date
			? moment(values?.date[1])?.format("YYYY-MM-DD")
			: null;
		delete values?.date;
		setSearchValues(values);
		effect(NMAIPO, EGet, {
			page: 1,
			perPage: 10,
			field: sortState?.field,
			isDesc: sortState?.order && sortType[sortState?.order],
			...values,
		});
	};

	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetCompany, {
			name: value,
			page: 1,
			perPage: 10,
		});
	};

	const onReset = () => {
		setSearchValues();
		form.resetFields();
		setSortState(null);
		effect(NMAIPO, EGet, { page: 1, perPage: 10 });
	};
	const [regionArr, setRegionArr] = useState([]);
	function findRegionType(arr) {
		const updatedRegionArr = [];
		commonsortData(areaList?.data, "area")?.forEach((item) => {
			if (arr?.includes(item?.area)) {
				updatedRegionArr?.push(item?.region);
			}
		});
		setRegionArr(Array.from(new Set(updatedRegionArr)));
		form.setFieldsValue({
			region: Array.from(new Set(updatedRegionArr)),
		});
	}
	// ---------------------渲染-------------------------
	return (
		<div>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<div className="insightsFeedForEsportsBox MaIpoData_Box mt50">
				<Row gutter={24}>
					<Col span={10}>
						<div className="CompanyChartBox BorderBox">
							<h1
								className="Global_Jb_title Public_Title pd20"
								style={{ color: "#999999" }}
							>
								Most Active Investors（{moment().format("YYYY") - 1}）
							</h1>
							<div className="Chart_Run_Title ">
								<Table
									locale={{ emptyText: CustomEmpty }}
									scroll={{ x: "max-content" }}
									dataSource={ipoTopList || []}
									columns={top5columns}
									pagination={false}
									rowKey="id"
								/>
							</div>
						</div>
					</Col>
					<Col span={14}>
						<div className="CompanyChartBox BorderBox">
							<h1
								className="Global_Jb_title Public_Title pd22"
								style={{ color: "#999999" }}
							>
								M&A/IPO Events in Past 12 Months
							</h1>
							<div className="Chart_Run_Title">{EsportsColumn()}</div>
						</div>
					</Col>
				</Row>
			</div>
			<div
				className="BlogBox MaIpoData_Box mt50"
				style={{ backgroundColor: "#F7F9FB", padding: "40px 40px 20px 0px" }}
			>
				<Form
					name="advanced_search"
					className="ant-advanced-search-form"
					labelCol={{ span: 8 }}
					form={form}
					onFinish={onFinish}
				>
					<Row gutter={24}>
						<Col span={8}>
							<Form.Item name="area" label="Area">
								<Select
									getPopupContainer={(triggerNode) => triggerNode.parentNode}
									mode="multiple"
									placeholder="Please Search"
									allowClear
									showSearch
									onChange={(e) => {
										findRegionType(e);
									}}
									onSearch={debounce((value) => {
										const { page, perPage } = areaList;
										effect(NRegion, EGetArea, {
											page,
											perPage,
											area: value,
										});
									}, 300)}
									filterOption={false}
								>
									{sortData(areaList?.data, "area")?.map((record, index) => {
										return (
											<Option key={record?.id} value={record?.area}>
												{record?.area}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="region" label="Region">
								<Select
									mode="multiple"
									getPopupContainer={(triggerNode) => triggerNode.parentNode}
									placeholder="Please Select"
								>
									{E?.mainEnum?.map((vo, index) => (
										<Option key={index} value={vo?.text}>
											{vo?.text}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name="events" label="Event" initialValue={"All"}>
								<Select>
									<Option key={1} value={"All"}>
										{"All"}
									</Option>
									<Option key={2} value={"Investment"}>
										{"Investment"}
									</Option>
									<Option key={3} value={"Merger"}>
										{"Merger"}
									</Option>
									<Option key={4} value={"IPO"}>
										{"IPO"}
									</Option>
									<Option key={5} value={"Privatization"}>
										{"Privatization"}
									</Option>
								</Select>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name="companyId" label="Company">
								<Select
									allowClear
									getPopupContainer={(triggerNode) => triggerNode.parentNode}
									showSearch
									filterOption={false}
									placeholder="Please Select"
									onSearch={debounce((value) => {
										onSearchCompany(value);
									}, 300)}
								>
									{sortData(OrganizationCompany?.data)?.map((item, index) => {
										return (
											<Option key={index} value={item?.id}>
												{item?.name}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8} style={{ textAlign: "left" }}>
							<Space>
								<Button type="primary" htmlType="submit" className="primarySearch">
									Search
								</Button>
								<Button type="default" htmlType="button" onClick={onReset}	className="secReset">
									Reset
								</Button>
							</Space>
						</Col>
					</Row>
				</Form>
			</div>
			<Spin spinning={loading} size="large">
				<div className="CompanyBox">
					<Row className="RowBox mt30">
						<Col span={24}>
							{MAIPOList?.error == 1009 ? (
								customizeRenderEmpty()
							) : (
								<Table
									locale={{ emptyText: CustomEmpty }}
									scroll={{ x: "max-content" }}
									dataSource={MAIPOList?.data || []}
									columns={columns}
									className="CompanyTableBox"
									rowKey="id"
									pagination={pagination}
									onChange={(pagination, filter, sort) => {
										setSortState(sort);
										effect(NMAIPO, EGet, {
											page: pagination?.current,
											perPage: pagination?.pageSize,
											field: sort?.column && sort?.field,
											isDesc:
												(sort?.column &&
													sort?.order &&
													sortType[sort?.order]) ||
												null,
											...searchValues,
										});
									}}
								/>
							)}
						</Col>
					</Row>
				</div>
			</Spin>
		</div>
	);
};
