import { requestGet } from "../utils/dva16";
import { Nadvert, EGet, EGetOne, RSetState } from './contants';

const Advert = {
  namespace: Nadvert,
  state: {
    loading: false,
    AdList: null,
    EsportAd: null,
  },
  reducers: {
    [RSetState](state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
      reducer(RSetState, { loading: true });
      let res = await requestGet('adBanner', payload);
      reducer(RSetState, { AdList: res, loading: false });
    },
    async [EGetOne]({ payload, callback }, { call, reducer, select, effect }) {
      let res = await requestGet(`esportsBanner`, payload);
      reducer(RSetState, { EsportAd: res });
    },
  },

};
export default Advert;
