import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import routes from "./routes";
import BasicLayout from "./layout/BasicLayout";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ForgetPassword from "./pages/ResetPassword/ForgetPassword";
import FAQpage from "./pages/FAQ/FAQpage";
import PrivacyPolicy from "./pages/Terms/PrivacyPolicy";
import TermsOfUse from "./pages/Terms/TermsOfUse";

function App(props) {
	return (
		<HashRouter>
			<Switch>
				<Route exact path={"/"} render={() => <Redirect to="/home" />} />
				<Route exact={true} path={"/Login"} component={Login} />
				<Route exact={true} path={"/ResetPassword"} component={ResetPassword} />
				<Route exact={true} path={"/FAQ"} component={FAQpage} />
				<Route
					exact={true}
					path={"/ForgetPassword"}
					component={ForgetPassword}
				/>
				<Route
					exact={true}
					path={"/privacy_policy"}
					component={PrivacyPolicy}
				/>
				<Route
					exact={true}
					path={"/term_and_conditions"}
					component={TermsOfUse}
				/>

				<BasicLayout>
					{routes?.map((item, key) => {
						return item?.routes ? (
							item?.routes?.map((vo, index) => (
								<Route
									key={index}
									exact={vo?.exact}
									path={vo?.path}
									component={vo?.component}
								/>
							))
						) : (
							<Route
								key={key}
								exact={item?.exact}
								path={item?.path}
								component={item?.component}
							/>
						);
					})}
				</BasicLayout>
			</Switch>
		</HashRouter>
	);
}

export default App;
