import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import "./index.less";
import {
	Row,
	Col,
	Typography,
	Tag,
	DatePicker,
	Select,
	Pagination,
	Spin,
} from "antd";
import { effect, useStore, reducer } from "../../../utils/dva16";
import {
	EGet,
	EGetOne,
	NFreeReport,
	RSetState,
} from "../../../models/contants";
const { Title, Text, Link } = Typography;

export default (props) => {
	const { history } = props;
	const { reportDetailData, loading } = useStore(NFreeReport);
	useEffect(() => {
		effect(NFreeReport, EGetOne, { id: props?.match?.params?.id });
		return () => {
			reducer(NFreeReport, RSetState, {
				reportDetailData: null,
			});
		};
	}, []);

	return (
		<div className="BlogDetailLabel">
			<Spin spinning={loading} size="large">
				<div className="BlogDetailBox">
					<Row className="RowBox">
						<Col span={24}>
							<Title level={2} className="Global_title">
								[{reportDetailData?.type}]{reportDetailData?.title}
							</Title>
						</Col>
						<Col span={24}>
							<div className="Article_Info">
								<div className="Article_Info_Lf">
									<span>Region: {reportDetailData?.region}</span>
									<div className="Article_Info_Taglist">
										{reportDetailData?.withCompanys?.map((vo, i) => (
											<Tag
												style={{ cursor: "pointer", borderRadius: "20px" }}
												key={i}
												color="#9E219E"
												onClick={() => {
													history.push(
														`/games_organizations/organizations/details/${vo?.id}`
													);
												}}
											>
												{vo?.name}
											</Tag>
										))}
										{reportDetailData?.withGames?.map((vo, i) => (
											<Tag
												style={{ cursor: "pointer", borderRadius: "20px" }}
												key={i}
												color="#19AE54"
												onClick={() => {
													history.push(
														`/games_organizations/games/details/${vo?.id}`
													);
												}}
											>
												{vo?.nameEn}
											</Tag>
										))}
									</div>
								</div>
								<div className="Article_Info_Rg">
									<span>
										Creator: {reportDetailData?.createrInfo?.username}
									</span>
									<span>
										{moment(reportDetailData?.createrInfo?.date)?.format(
											"YYYY-MM-DD"
										)}
									</span>
								</div>
							</div>
						</Col>

						<Col span={24}>
							<div
								dangerouslySetInnerHTML={{
									__html: reportDetailData?.description,
								}}
							></div>
						</Col>

						<Col span={24} className="mt20">
							<div className="report_Box">
								<p>
									Recommended report:
									<Link href={reportDetailData?.report} target="_blank">
										{reportDetailData?.report}
									</Link>
								</p>
							</div>
						</Col>
					</Row>
				</div>
			</Spin>
		</div>
	);
};
