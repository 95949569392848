import { requestGet } from "../utils/dva16";
import {
	NTournament,
	EGet,
	EGetOne,
	EGetCollect,
	RSetState,
	EGetType,
	EGetDetail,
} from "./contants";

const Tournament = {
	namespace: NTournament,
	state: {
		TournamentList: null,
		TournamentCollect: null,
		TournamentTitle: null,
		tournamentSearch: null,
		loading: false,
		tournamentDetailData: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("tournament", payload);
			reducer(RSetState, { TournamentList: res, loading: false });
			callback && callback(res);
		},
		async [EGetCollect](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			let res = await requestGet("tournamentAnalysis", payload);
			reducer(RSetState, { TournamentCollect: res });
		},
		async [EGetOne]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("tournamentTitle", payload);
			reducer(RSetState, { TournamentTitle: res });
		},
		async [EGetType]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("tournamentSearch", payload);
			reducer(RSetState, { tournamentSearch: res });
		},
		async [EGetDetail](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			reducer(RSetState, { loading: true });
			let res = await requestGet(`tournament/${payload?.id}`, payload);
			reducer(RSetState, { tournamentDetailData: res, loading: false });
		},
	},
};
export default Tournament;
