import React, { useEffect, useState } from "react";
import { Table, Checkbox, Modal, Button, Card, DatePicker, Form } from "antd";
import E from "../../../../models/E";
import { find, union } from "lodash";
import moment from "moment";
import { NLogin, EGet } from "../../../../models/contants";
import { effect, useStore } from "../../../../utils/dva16";
import CustomEmpty from "@/utils/CustomEmpty";
const { RangePicker } = DatePicker;
export default (props) => {
	const [editForm] = Form.useForm();
	const { currentSubscribe } = useStore(NLogin);
	const [module, setModule] = useState([
		{ id: 5, name: "IQ Exclusives", value: "Exclusives" },
		{ id: 1, name: "VANA", value: "InsightsFeed" },
		{ id: 10, name: "Market Model", value: "MarketModel" },
		{ id: 2, name: "Esports", value: "Esport" },
		{ id: 7, name: "Games", value: "Games" },
		{ id: 8, name: "Organizations", value: "Organizations" },
		{ id: 3, name: "Financial Data", value: "Finacial" },
		{ id: 9, name: "Internet iCafe", value: "ChinaICafe" },
		// { id: 4, name: 'China streaming', value: 'ChinaStreaming' },
		// { id: 6, name: 'Transactions', value: 'Transactions' },
	]);
	useEffect(() => {
		effect(NLogin, EGet, {});
	}, []);
	//组件渲染时根据变量“subscribe”和“visible”更新“module”变量的值，并更新antd表单中的时间字段。 | During component rendering, update the value of the "module" variable based on the variables "subscribe" and "visible", and update the time field in the ant form.
	useEffect(() => {
		if (currentSubscribe?.[0] && currentSubscribe?.[0]?.module) {
			for (let i in currentSubscribe?.[0]?.module) {
				let item = find(module, { value: i });
				if (item) {
					item.region = currentSubscribe?.[0]?.module[i];
				}
			}
			setModule(module.concat());
		} else {
			setModule([
				{ id: 5, name: "IQ Exclusives", value: "Exclusives" },
				{ id: 1, name: "VANA", value: "InsightsFeed" },
				{ id: 10, name: "Market Model", value: "MarketModel" },
				{ id: 2, name: "Esports", value: "Esport" },
				{ id: 7, name: "Games", value: "Games" },
				{ id: 8, name: "Organizations", value: "Organizations" },
				{ id: 3, name: "Financial Data", value: "Finacial" },
				{ id: 9, name: "Internet iCafe", value: "ChinaICafe" },
				// { id: 4, name: 'China streaming', value: 'ChinaStreaming' },
				// { id: 6, name: 'Transactions', value: 'Transactions' },
			]);
		}
		editForm?.setFieldsValue({
			time:
				(currentSubscribe?.[0] && [
					moment(currentSubscribe?.[0]?.subscribeDate),
					moment(currentSubscribe?.[0]?.expiredDate),
				]) ||
				null,
		});
	}, [currentSubscribe?.[0]]);
	const renderEditSubScription = () => {
		const columns = [
			{ title: "Module", dataIndex: "name", key: "name" },
			{
				title: E?.mainRegion?.China,
				dataIndex: "China",
				key: "China",
				align: "center",
				render: (text, record, index) => {
					return (
						<Checkbox
							checked={
								record?.region &&
								record?.region?.indexOf(E?.mainRegion?.China) > -1
									? true
									: false
							}
						/>
					);
				},
			},
			{
				title: E?.mainRegion?.EastAsia,
				dataIndex: "Ease Asia",
				key: "Ease Asia",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<Checkbox
								checked={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.EastAsia) > -1
										? true
										: false
								}
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.SEA,
				dataIndex: "SEA",
				key: "SEA",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<Checkbox
								checked={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.SEA) > -1
										? true
										: false
								}
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.MENA,
				dataIndex: "MENA",
				key: "MENA",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<Checkbox
								checked={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.MENA) > -1
										? true
										: false
								}
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.India,
				dataIndex: "India",
				key: "India",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<Checkbox
								checked={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.India) > -1
										? true
										: false
								}
							/>
						)
					);
				},
			},
			{
				title: E?.mainRegion?.Global,
				dataIndex: "Global",
				key: "Global",
				align: "center",
				render: (text, record, index) => {
					return (
						record?.name !== "China streaming" && (
							<Checkbox
								checked={
									record?.region &&
									record?.region?.indexOf(E?.mainRegion?.Global) > -1
										? true
										: false
								}
							/>
						)
					);
				},
			},
			{
				title: "ALL",
				dataIndex: "ALL",
				key: "ALL",
				align: "center",
				render: (text, record, index) => {
					return (
						<Checkbox
							indeterminate={
								record?.region && record?.region?.length !== 6 ? true : false
							}
							checked={
								record?.region && record?.region?.length === 6 ? true : false
							}
						/>
					);
				},
			},
		];
		return (
			<Card bordered={false}>
				<Form form={editForm} style={{ marginBottom: "1rem" }}>
					<Form.Item label="Subscription Period" name="time">
						<RangePicker
							disabled
							format="MMM DD, YYYY"
							getPopupContainer={(triggerNode) => triggerNode.parentNode}
						/>
					</Form.Item>
				</Form>
				<Table
					locale={{ emptyText: CustomEmpty }}
					scroll={{ x: "max-content" }}
					bordered
					columns={columns}
					dataSource={module}
					pagination={false}
					rowKey="id"
				/>
			</Card>
		);
	};
	return <div>{renderEditSubScription()}</div>;
};
