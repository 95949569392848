import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import "./index.less";
import {
	Button,
	Row,
	Col,
	Space,
	Table,
	Form,
	Select,
	Spin,
	Tag,
	Collapse,
} from "antd";
import sortData from "../../../utils/sorter";
import { effect, useStore, reducer } from "../../../utils/dva16";
import {
	EGet,
	EGetArea,
	EGetChart,
	EGetCompany,
	EGetOne,
	EGetType,
	EGetCommonArea,
	NCompany,
	NExchange,
	NOrganizationMulti,
	NOrganizationMultiType,
	NRegion,
	RSetState,
	NCommon,
	EGetActivetType,
	NLogin,
	EGetRegion,
	NGame,
	EGeTPage,
} from "../../../models/contants";
import { Pie, G2 } from "@ant-design/charts";
import Reaulatory3 from "../../../assets/menu/Regulatory3.png";
import E from "../../../models/E";
import { Helmet } from "react-helmet";
import { customizeRenderEmpty } from "../../../utils/utils";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import defaultLogo from "../../../assets/images/company/defaul-logo_organization.png";
import CustomEmpty from "@/utils/CustomEmpty";
const { Option } = Select;
export default (props) => {
	const { history } = props;
	const [form] = Form.useForm();
	const { areaList, regionList, commonAreaList } = useStore(NRegion);
	const {
		OrganizationMultiList,
		OrganizationMultiChart,
		loading,
		OrganizationCompany,
	} = useStore(NOrganizationMulti);
	const { gameSearch } = useStore(NGame);
	const [searchValue, setSearchValue] = useState(null);
	const { OrganizationMultiTypeList } = useStore(NOrganizationMultiType);
	const [sortState, setSortState] = useState(null);
	let sortType = {
		ascend: "asc",
		descend: "desc",
	};

	function GamesColumn(text, record) {
		const games = record?.withGamesList ? [...record?.withGamesList] : [];
		const showCollapse = games?.length > 2;
		const defaultActiveKey = showCollapse ? ["2"] : [];
		return (
			<div>
				{games?.slice(0, 2)?.map((game, i) => (
					<Tag
						color={record?.withGamesList?.includes(game) ? "green" : "cyan"}
						key={i}
						style={{ marginBottom: 4 }}
					>
						{game}
					</Tag>
				))}
				{showCollapse && (
					<>
						<Collapse
							defaultActiveKey={defaultActiveKey}
							ghost
							expandIconPosition="right"
							expandIcon={({ isActive }) =>
								isActive ? <UpOutlined /> : <DownOutlined />
							}
						>
							<Collapse.Panel key="all">
								{games?.slice(2)?.map((game, i) => (
									<Tag
										color={
											record?.withGamesList?.includes(game) ? "green" : "cyan"
										}
										key={i}
										style={{ marginBottom: 4 }}
									>
										{game}
									</Tag>
								))}
							</Collapse.Panel>
						</Collapse>
					</>
				)}
			</div>
		);
	}
	useEffect(() => {
		effect(NGame, EGetType, { page: 1, perPage: 10 });
		effect(NCommon, EGetActivetType, { page: 1, perPage: 100 });
		effect(NExchange, EGet, { page: 1, perPage: 10 });
		effect(NRegion, EGetArea, { page: 1, perPage: 200 });
		effect(NRegion, EGetCommonArea);
		effect(NOrganizationMulti, EGetCompany, { page: 1, perPage: 10 });
		effect(NRegion, EGetRegion, { page: 1, perPage: 10 });
		effect(NOrganizationMultiType, EGet, { page: 1, perPage: 10 });
		effect(NOrganizationMulti, EGetChart, {
			category: ["Company", "Regulatory", "Government"],
		});
		return () => {
			reducer(NCompany, RSetState, { companyChartData: null });
		};
	}, []);
	useEffect(() => {
		let page = sessionStorage.getItem("orgPage");
		let pageSize = sessionStorage.getItem("orgPageSize");
		if (page && pageSize) {
			effect(NOrganizationMulti, EGet, { page, pageSize });
		} else {
			effect(NOrganizationMulti, EGet, { page: 1, pageSize: 10 });
		}
	}, []);

	const CompanyCharts = () => {
		const G = G2.getEngine("canvas");
		const [config, setConfig] = useState({
			data: [],
			height: 300,
			appendPadding: 10,
			angleField: "value",
			colorField: "region",
			radius: 0.75,
			innerRadius: 0.6,
			forceFit: true,
			legend: {
				layout: "horizontal",
				position: "bottom",
			},
			statistic: {
				title: false,
				content: false,
			},
			label: {
				type: "spider",
				labelHeight: 40,
				style: {
					fontSize: 20,
				},
				formatter: (data, mappingData) => {
					const group = new G.Group({});
					group?.addShape({
						type: "circle",
						attrs: {
							x: 0,
							y: 0,
							width: 40,
							height: 50,
							r: 5,
							fill: mappingData?.color,
						},
					});
					group?.addShape({
						type: "text",
						attrs: {
							x: 10,
							y: 8,
							text: `${data?.region}`,
							fill: mappingData?.color,
						},
					});
					group?.addShape({
						type: "text",
						attrs: {
							x: 0,
							y: 25,
							text: `${data?.value} [${(data?.percent * 100)?.toFixed(2)}%]`,
							fill: "rgba(0, 0, 0, 0.65)",
							fontWeight: 700,
						},
					});
					return group;
				},
			},
			interactions: [
				{
					type: "element-selected",
				},
				{
					type: "element-active",
				},
			],
		});
		useEffect(() => {
			if (OrganizationMultiChart && OrganizationMultiChart?.data) {
				let arr = OrganizationMultiChart?.data?.map((v) => {
					if (v?.region == null) {
						v.region = "Others";
					}
					return {
						region: v?.region,
						value: v?.count,
					};
				});

				config.data = arr;
				setConfig(Object.assign({}, config));
			}
		}, [OrganizationMultiChart]);
		return <Pie {...config} />;
	};
	const columns = [
		{
			title: "Organization",
			dataIndex: "name",
			key: "name",
			width: "250px",
			sortOrder: sortState?.field == "name" && sortState?.order,
			sorter: (a, b) => a?.name?.localeCompare(b?.name),
			render: (text, record, index) => (
				<>
					<div className="Tabel_Company">
						<img
							className="Tabel_Company_Logo"
							src={record?.logo ? record.logo : defaultLogo}
							alt={record?.name}
						/>
						<div className="Tabel_Company_Title">
							<p>
								<a
									className="a_style"
									key={record?.id}
									onClick={() => {
										toDetails(record?.id);
									}}
								>
									{record?.name}
								</a>
							</p>
							<p>
								<a
									className="a_style"
									onClick={() => {
										toDetails(record?.id);
									}}
								>
									{record?.localName}
								</a>
							</p>
						</div>
					</div>
				</>
			),
		},
		{
			title: "Category",
			dataIndex: "category",
			key: "category",
			render: (text, value, index) => <>{text ? text : "-"}</>,
			sortOrder: sortState?.field == "category" && sortState?.order,
			sorter: (a, b) => a?.category?.localeCompare(b?.category),
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			sortOrder: sortState?.field == "type" && sortState?.order,
			sorter: (a, b) => {
				a?.type?.[0] &&
					b?.type?.[0] &&
					a?.type?.[0]?.localeCompare(b?.type?.[0]);
			},
			render: (text, value, index) => (
				<>
					{value?.type?.map((item, index) => {
						return <div key={index}>{item + "\n"}</div>;
					})}
				</>
			),
		},
		{
			title: "HQ Location",
			dataIndex: "area",
			key: "area",
			sortOrder: sortState?.field == "area" && sortState?.order,
			sorter: (a, b) => {
				a?.area?.[0] &&
					b?.area?.[0] &&
					a?.area?.[0]?.localeCompare(b?.area?.[0]);
			},
			render: (text, record) => (
				<div>
					{record?.area?.length
						? record?.withArea?.map((vo, io) => <div key={io}>{vo}</div>)
						: "-"}
				</div>
			),
		},
		{
			title: "Region",
			dataIndex: "region",
			key: "region",
			sortOrder: sortState?.field == "region" && sortState?.order,
			sorter: (a, b) => {
				a?.region?.[0] &&
					b?.region?.[0] &&
					a?.region?.[0]?.localeCompare(b?.region?.[0]);
			},
			render: (text, record) => (
				<div>
					{record?.region?.length
						? record?.region?.map((vo, io) => <div key={io}>{vo}</div>)
						: "-"}
				</div>
			),
		},
		{
			title: "Related Games",
			key: "relatedGames",
			dataIndex: "relatedGames",
			align: "center",
			width: 200,
			render: GamesColumn,
			sorter: true,
			sortOrder: sortState?.field == "relatedGames" && sortState?.order,
		},
		{
			title: "Basic Organization",
			key: "isBasic",
			dataIndex: "isBasic",
			align: "center",
			sorter: true,
			sortOrder: sortState?.field == "isBasic" && sortState?.order,
			render: (text, record) => (
				<>
					<div>{text == 0 ? "No" : text == 1 ? "Yes" : ""}</div>
				</>
			),
		},
		{
			title: "Information",
			dataIndex: "Information",
			key: "Information",
			render: (text, record, index) => {
				return (
					<div className="Tabel_Information">
						{record?.__meta__?.exclusives_count >= 1 && (
							// record?.skipExclusives
							// 	?.map((rec) => rec?.status)
							// 	?.includes("Published") &&
							<a
								style={
									record?.__meta__?.exclusives_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.__meta__?.exclusives_count >= 1) {
										history.push({
											pathname: "/nikoiq_exclusives",
											state: { company: record?.id },
										});
									}
								}}
							>
								Exclusive
							</a>
						)}

						{record?.__meta__?.InsightFeed_count >= 1 && (
							// record?.skipInsightFeed
							// 	?.map((rec) => rec?.status)
							// 	?.includes("Published") &&
							<a
								style={
									record?.__meta__?.InsightFeed_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.__meta__?.InsightFeed_count >= 1) {
										history.push({
											pathname: "/news_articles/VANA",
											state: { company: record?.id },
										});
									}
								}}
							>
								VANA
							</a>
						)}

						{record?.__meta__?.Tournament_count >= 1 && (
							<a
								style={
									record?.__meta__?.Tournament_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.__meta__?.Tournament_count >= 1) {
										history.push({
											pathname: "/esports",
											state: { sponsor: record?.id },
										});
									}
								}}
							>
								Tournaments
							</a>
						)}

						{record?.skipTeams?.length >= 1 && (
							<a
								style={
									record?.skipTeams?.length >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.skipTeams?.length >= 1) {
										history.push({
											pathname: "/Esports/Teams",
											state: { sponsor: record?.id },
										});
									}
								}}
							>
								Teams
							</a>
						)}

						{record?.skipIpo?.length >= 1 && (
							<a
								style={
									record?.skipIpo?.length >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.skipIpo?.length >= 1) {
										history.push({
											pathname: "/Financial/Transactions",
											state: { companyId: record?.id },
										});
									}
								}}
							>
								M&A
							</a>
						)}
					</div>
				);
			},
		},
	];

	const toDetails = (value) => {
		history.push(`/games_organizations/organizations/details/${value}`);
	};
	const onFinish = (values) => {
		console.log(values);
		console.log(commonAreaList);
		values.areas = values.areas && [
			...values?.areas?.map((areaValue) => {
				return commonAreaList?.data?.find(
					(item) => item?.otherData === areaValue
				)?.id;
			}),
			...values.areas,
		];
		setSearchValue(values);
		effect(NOrganizationMulti, EGet, {
			...values,
			page: 1,
			perPage: 10,
			field: sortState?.field,
			isDesc: sortState?.order && sortType[sortState?.order],
		});
	};
	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetCompany, {
			name: value,
			page: 1,
			perPage: 10,
		});
	};
	const onSearchType = (value) => {
		effect(NOrganizationMultiType, EGet, { page: 1, perPage: 10, name: value });
	};
	const onReset = () => {
		setSearchValue();
		sessionStorage.setItem("orgPage", 1);
		sessionStorage.setItem("orgPageSize", 10);
		form.resetFields();
		effect(NOrganizationMulti, EGet, {
			page: 1,
			perPage: 10,
		});
	};

	function findRegionType(arr) {
		console.log(arr);
		const updatedRegionArr = [];
		areaList?.data?.forEach((item) => {
			if (arr?.includes(item?.id)) {
				updatedRegionArr?.push(item?.lookupValue);
			}
		});

		form.setFieldsValue({
			regions: Array.from(new Set(updatedRegionArr)),
		});
	}
	function onSearchGame(value) {
		effect(NGame, EGetType, { ...{ name: value }, page: 1, perPage: 10 });
	}

	return (
		<div>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<>
				{/* <div className="CompanyBox mt50" style={{ backgroundColor: "#F7F9FB" }}>
					<Row gutter={24} justify="center">
						<Col span={7}>
							<div className="Statistic_Box">
								<div className="Statistic_Card">
									<div className="Statistic_Card_Sub">
										<p>Total Organizations</p>
										<h2>
											{OrganizationMultiChart?.count
												?.toString()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
										</h2>
									</div>
								</div>
							</div>
						</Col>
						<Col span={10}>
							<div className="CompanyChartBox" style={{ paddingBottom: 50 }}>
								<h3 style={{ paddingTop: 10, textAlign: "center" }}>
									Organizations by Region
								</h3>
								{CompanyCharts()}
							</div>
						</Col>
					</Row>
				</div> */}
				<div
					className="BlogBox mt50"
					style={{ backgroundColor: "#F7F9FB", padding: "30px 50px" }}
				>
					<Form
						name="advanced_search"
						className="ant-advanced-search-form"
						labelCol={{ span: 6 }}
						form={form}
						onFinish={onFinish}
					>
						<Row gutter={24}>
							<Col span={8}>
								<Form.Item
									name="company"
									label="Organization"
									rules={[
										{
											required: false,
											message: "Please input",
										},
									]}
								>
									<Select
										allowClear
										getPopupContainer={(triggerNode) => triggerNode?.parentNode}
										showSearch
										filterOption={false}
										placeholder="Please Select"
										onSearch={debounce((value) => {
											onSearchCompany(value);
										}, 300)}
									>
										{sortData(OrganizationCompany?.data)?.map((item, index) => {
											return (
												<Option key={index} value={item?.name}>
													{item?.name}
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="category" label="Category">
									<Select
										getPopupContainer={(triggerNode) => triggerNode.parentNode}
										placeholder={"Please Select"}
										showArrow
										allowClear
									>
										<Option value="Company">Company</Option>
										<Option value="Regulatory">Regulatory</Option>
										<Option value="Government">Government</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Type" name="types">
									<Select
										allowClear
										getPopupContainer={(triggerNode) => triggerNode?.parentNode}
										showSearch
										filterOption={false}
										placeholder="Please Select"
										mode="multiple"
										onSearch={debounce((value) => {
											onSearchType(value);
										}, 300)}
									>
										{sortData(OrganizationMultiTypeList)?.map((item, index) => {
											return (
												<Option key={index} value={item?.name}>
													{item?.name}
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="HQ Location" name="areas">
									<Select
										getPopupContainer={(triggerNode) => triggerNode?.parentNode}
										mode="multiple"
										placeholder="Please Search"
										allowClear
										showSearch
										onChange={(e) => {
											findRegionType(e);
										}}
										onSearch={debounce((value) => {
											const { page, perPage } = areaList;
											effect(NRegion, EGetArea, {
												page,
												perPage,
												area: value,
											});
										}, 300)}
										filterOption={false}
									>
										{sortData(areaList?.data, "area")?.map((record, index) => {
											return (
												<Option key={record?.id} value={record?.otherData}>
													{record?.otherData}
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Region" name="regions">
									<Select
										mode="multiple"
										getPopupContainer={(triggerNode) => triggerNode?.parentNode}
										placeholder="Please Select"
										filterOption={(input, option) =>
											option?.children
												?.toLowerCase()
												?.indexOf(input?.toLowerCase()) >= 0
										}
									>
										{regionList?.map((record, index) => {
											return (
												<Option key={record?.id} value={record?.lookupValue}>
													{record?.lookupValue}
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>

							<Col span={8}>
								<Form.Item label="Related Games" name="relatedGames">
									<Select
										showSearch
										getPopupContainer={(triggerNode) => triggerNode.parentNode}
										placeholder="Please Search"
										mode="multiple"
										onSearch={debounce((value) => {
											onSearchGame(value);
										}, 200)}
										filterOption={false}
									>
										{sortData(gameSearch?.data, "gameEn")?.map(
											(record, index) => {
												return (
													<Option key={index} value={record?.id}>
														{record?.gameEn}
													</Option>
												);
											}
										)}
									</Select>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Basic Organization" name="isBasic">
									<Select
										placeholder="Please Select "
										getPopupContainer={(triggerNode) => triggerNode.parentNode}
									>
										<Option value={"1"}>Yes</Option>
										<Option value={"0"}>No</Option>
									</Select>
								</Form.Item>
							</Col>

							<Col span={16} style={{ textAlign: "right" }}>
								<Space>
									<Button
										type="primary"
										htmlType="submit"
										// style={{ marginRight: "5px" }}
										className="primarySearch"
									>
										Search
									</Button>
									<Button
										className="secReset"
										type="default"
										htmlType="button"
										onClick={() => {
											setSortState(null);
											onReset();
										}}
									>
										Reset
									</Button>
								</Space>
							</Col>
						</Row>
					</Form>
				</div>
				<Spin spinning={loading} size="large">
					<div className="CompanyBox">
						<Row className="RowBox mt30" justify="right">
							<Col span={24}>
								{OrganizationMultiList?.error == 1009 ? (
									customizeRenderEmpty()
								) : (
									<Table
										showSorterTooltip={false}
										scroll={{ x: "max-content" }}
										dataSource={OrganizationMultiList?.data || []}
										columns={columns}
										className="CompanyTableBox"
										rowKey="id"
										pagination={{
											current:
												parseInt(
													OrganizationMultiList && OrganizationMultiList?.page
												) || 1,
											total:
												(OrganizationMultiList &&
													OrganizationMultiList?.total) ||
												0,
											defaultCurrent: 1,
											defaultPageSize:
												parseInt(
													OrganizationMultiList &&
														OrganizationMultiList?.perPage
												) || 10,
											pageSize:
												OrganizationMultiList && OrganizationMultiList?.perPage,
											pageSizeOptions: ["10", "20", "30", "50", "100"],
											showQuickJumper: true,
											showSizeChanger: true,
											position: "bottom",
										}}
										onChange={(pagination, filter, sort) => {
											setSortState(sort);
											effect(NOrganizationMulti, EGet, {
												...searchValue,
												page: pagination?.current,
												perPage: pagination?.pageSize,
												field: sort?.column && sort?.field,
												isDesc:
													(sort?.column &&
														sort?.order &&
														sortType[sort?.order]) ||
													null,
											});
											sessionStorage.setItem("orgPage", pagination?.current);
											sessionStorage.setItem(
												"orgPageSize",
												pagination?.pageSize
											);
										}}
									/>
								)}
							</Col>
						</Row>
					</div>
				</Spin>
			</>
		</div>
	);
};
