const sortData = (data, model) => {
	if (!data) return [];
	return data?.sort((a, b) => {
		let itemA, itemB;
		switch (model) {
			case 'username':
				itemA = (a.username || '').toLowerCase();
				itemB = (b.username || '').toLowerCase();
				break;
			case 'clientName':
				itemA = (a.clientName || '').toLowerCase();
				itemB = (b.clientName || '').toLowerCase();
				break;
			case 'area':
				itemA = (a.area || '').toLowerCase();
				itemB = (b.area || '').toLowerCase();
				break;
			case 'text':
				itemA = (a.text || '').toLowerCase();
				itemB = (b.text || '').toLowerCase();
				break;
			case 'name':
				itemA = (a.name || '').toLowerCase();
				itemB = (b.name || '').toLowerCase();
				break;
			case 'nameEN':
				itemA = (a.nameEN || '').toLowerCase();
				itemB = (b.nameEN || '').toLowerCase();
				break;
			case 'gameEn':
				itemA = (a.gameEn || '').toLowerCase();
				itemB = (b.gameEn || '').toLowerCase();
				break;
			case 'genre':
				itemA = (a.genre || '').toLowerCase();
				itemB = (b.genre || '').toLowerCase();
				break;
			case 'events':
				itemA = (a.events || '').toLowerCase();
				itemB = (b.events || '').toLowerCase();
				break;
			case 'type':
				itemA = (a.type || '').toLowerCase();
				itemB = (b.type || '').toLowerCase();
				break;
			case 'languages':
				itemA = (a.languages || '').toLowerCase();
				itemB = (b.languages || '').toLowerCase();
				break;
			case 'tournament_type':
				itemA = (a.tournament_type || '').toLowerCase();
				itemB = (b.tournament_type || '').toLowerCase();
				break;
			case 'tournament_special_characterisctics':
				itemA = (a.tournament_special_characterisctics || '').toLowerCase();
				itemB = (b.tournament_special_characterisctics || '').toLowerCase();
				break;
			case 'subType':
				itemA = (a.subType || '').toLowerCase();
				itemB = (b.subType || '').toLowerCase();
				break;
			case 'platform':
				itemA = (a.platform || '').toLowerCase();
				itemB = (b.platform || '').toLowerCase();
				break;
			// Add more cases for other models if needed
			default:
				// Default to sorting by 'name' if the model is not recognized
				itemA = (a.name || '').toLowerCase();
				itemB = (b.name || '').toLowerCase();
		}
		if (itemA < itemB) return -1;
		if (itemA > itemB) return 1;
		return 0;
	});
};

export default sortData;
