import React, { useState, useEffect } from "react";
import IcafeRank from "./IcafeRank";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import "moment/locale/en-au";
import "../Profile/index.less";
import "./index.less";
import CustomEmpty from "@/utils/CustomEmpty";
import sortData from "../../../utils/sorter";
import defaultLogo from "../../../assets/images/game/defaul-logo_game.png";
import {
	Button,
	Row,
	Col,
	Table,
	Form,
	Select,
	Space,
	Spin,
	DatePicker,
} from "antd";
import { effect, useStore } from "../../../utils/dva16";
import {
	NICafeGame,
	EGet,
	EGetOne,
	NGame,
	NOrganizationMulti,
	NGenre,
	EGetType,
} from "../../../models/contants";
import { find } from "lodash";
const { Option } = Select;
const { RangePicker } = DatePicker;

moment.locale("en", {
	months: [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	],
});
export default (props) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const { gameSearch } = useStore(NGame);
	const { ICafeGameList, loading } = useStore(NICafeGame);
	const { organizationSearch } = useStore(NOrganizationMulti);
	const { GenreList } = useStore(NGenre);
	const nowMonth = moment()
		.subtract(1, "month")
		.startOf("month")
		.format("YYYY-MM");
	const lastMonth = moment()
		.subtract(6, "month")
		.startOf("month")
		.format("YYYY-MM");
	const [searchValues, setSearchValues] = useState();
	const [timeColumns, setTimeColumns] = useState([]);

	const renderTable = () => {
		const [columns, setColumns] = useState([
			{
				title: "Game",
				dataIndex: "gameEn",
				key: "gameEn",
				render: (text, record, index) => {
					return (
						<>
							<div className="Tabel_Company">
								<img
									className="Tabel_Company_Logo"
									src={record?.logo ? record.logo : defaultLogo}
									alt={record?.name}
								/>
								<div className="Tabel_Company_Title">
									<p>
										<a
											className="a_style"
											href={`#/games_organizations/games/details/${record?.id}`}
										>
											{record?.gameEn}
										</a>
									</p>
									<p>
										<a
											className="a_style"
											href={`#/games_organizations/games/details/${record?.id}`}
										>
											{record?.nameCn}
										</a>
									</p>
								</div>
							</div>
						</>
					);
				},
			},
		]);
		const [actionColumns, setActionColumns] = useState([
			{
				title: "Full Details",
				key: "iCafedata",
				dataIndex: "iCafedata",
				fixed: "right",
				width: 120,
				render: (text, record, index) => {
					return (
						<a
							className="iconfont icon-tianxie"
							onClick={() => {
								history.push(`/china_data/internet_cafe/details/${record?.id}`);
							}}
						>
							View more
						</a>
					);
				},
			},
		]);

		const pagination = {
			current: parseInt(ICafeGameList && ICafeGameList?.page) || 1,
			total: (ICafeGameList && ICafeGameList?.total) || 0,
			defaultCurrent: 1,
			defaultPageSize: parseInt(ICafeGameList && ICafeGameList?.perPage) || 10,
			pageSize: ICafeGameList && ICafeGameList?.perPage,
			pageSizeOptions: ["10", "20", "30", "50", "100"],
			showQuickJumper: true,
			showSizeChanger: true,
			position: "bottom",
		};
		return (
			<Table
				locale={{ emptyText: CustomEmpty }}
				scroll={{ x: "max-content" }}
				rowKey="id"
				className="CompanyTableBox"
				pagination={false}
				columns={columns?.concat(timeColumns, actionColumns)}
				dataSource={ICafeGameList?.data}
				onChange={(pagination, sorter, filter) => {
					effect(NICafeGame, EGet, {
						page: pagination?.current,
						perPage: pagination?.pageSize,
						...searchValues,
					});
				}}
			/>
		);
	};
	const onSearchGame = (value) => {
		effect(NGame, EGetType, { ...{ name: value }, page: 1, perPage: 10 });
	};
	const loadFiltersFromSession = () => {
		const savedFilters = sessionStorage.getItem("icafeFilters");
		return savedFilters ? JSON.parse(savedFilters) : null;
	};
	useEffect(() => {
		const savedFilters = loadFiltersFromSession();
		if (savedFilters) {
			setSearchValues(savedFilters);
			form.setFieldsValue({
				game: savedFilters.game,
				relatedOrganizations: savedFilters.relatedOrganizations,
				date: [
					savedFilters.startDate ? moment(savedFilters.startDate) : null,
					savedFilters.endDate ? moment(savedFilters.endDate) : null,
				],
				genre: savedFilters.genre,
			});
			effect(NICafeGame, EGet, { page: 1, perPage: 10, ...savedFilters });
		} else {
			effect(NICafeGame, EGet, { page: 1, perPage: 10 });
			effect(NGame, EGetType, { page: 1, perPage: 10 });
			effect(NOrganizationMulti, EGetType, { page: 1, perPage: 10 });
			effect(NGenre, EGet, { page: 1, perPage: 10 });
		}
	}, []);

	useEffect(() => {
		if (searchValues && searchValues?.startDate && searchValues?.endDate) {
			let { startDate, endDate } = searchValues;
			onFormatMonth(startDate, endDate);
		} else {
			const startDate = lastMonth;
			const endDate = nowMonth;
			let game_search = form.getFieldValue().game;
			onFormatMonth_default(startDate, endDate);
		}
	}, [searchValues]);
	const onFormatMonth = (startDate, endDate) => {
		let list = [];
		let startMonth = moment(startDate)?.month();
		let a = moment(endDate)?.diff(moment(startDate), "month");
		let endMonth = moment(startDate)?.month() + a;
		for (let index = startMonth; index <= endMonth; index++) {
			let month = moment(startDate).add(index - startMonth, "months");
			list.push({
				title: moment(month)?.format("MMM YYYY"),
				key: moment(month)?.format("YYYY-MM"),
				dataIndex: moment(month)?.format("YYYY-MM"),
				render: (text, record, index) => {
					let { icafe } = record;
					let monthItem = find(icafe, {
						date: moment(month)?.format("YYYY-MM"),
					});

					return (
						<span>
							{monthItem?.data
								?.toString()
								?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "0"}
						</span>
					);
				},
				sorter: (a, b) => {
					let monthA =
						find(a.icafe, {
							date: moment(month)?.format("YYYY-MM"),
						})?.data || "0";
					let monthB =
						find(b.icafe, {
							date: moment(month)?.format("YYYY-MM"),
						})?.data || "0";

					// Remove commas and convert to numbers
					let numA = parseInt(monthA.toString().replace(/,/g, ""), 10);
					let numB = parseInt(monthB.toString().replace(/,/g, ""), 10);

					return numA - numB;
				},
			});
		}
		setTimeColumns(list);
	};
	//月份格式化默认展示最近6个月 | Month formatting defaults to displaying the last 6 months
	const onFormatMonth_default = (startDate, endDate) => {
		let list = [];
		let startMonth = moment(startDate)?.month();
		let endMonth = moment(startDate)?.month() + 5;

		for (let index = startMonth; index <= endMonth; index++) {
			let month = moment(startDate)?.add(index - startMonth, "months");
			list?.push({
				title: moment(month)?.format("MMM YY"),
				key: moment(month)?.format("YYYY-MM"),
				render: (text, record, index) => {
					let { icafe } = record;
					let monthItem = find(icafe, {
						date: moment(month)?.format("YYYY-MM"),
					});

					return (
						<span>
							{monthItem?.data
								?.toString()
								?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "0"}
						</span>
					);
				},

				sorter: (a, b) => {
					let monthA =
						find(a.icafe, {
							date: moment(month)?.format("YYYY-MM"),
						})?.data || "0";
					let monthB =
						find(b.icafe, {
							date: moment(month)?.format("YYYY-MM"),
						})?.data || "0";

					// Remove commas and convert to numbers
					let numA = parseInt(monthA.toString().replace(/,/g, ""), 10);
					let numB = parseInt(monthB.toString().replace(/,/g, ""), 10);

					return numA - numB;
				},
			});
		}
		setTimeColumns(list);
	};
	const onFinish = (value) => {
		value.startDate = value?.date
			? moment(value?.date[0])?.format("YYYY-MM-DD")
			: null;
		value.endDate = value?.date
			? moment(value?.date[1])?.format("YYYY-MM-DD")
			: null;
		delete value?.date;
		setSearchValues(value);
		sessionStorage.setItem("icafeFilters", JSON.stringify(value));
		effect(NICafeGame, EGet, { page: 1, perPage: 10, ...value });
	};
	const onReset = () => {
		form.resetFields();
	};
	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetType, {
			name: value,
			page: 1,
			perPage: 10,
		});
	};
	const onSearchGenre = (value) => {
		effect(NGenre, EGet, { ...{ genre: value }, page: 1, perPage: 10 });
	};

	return (
		<div>
			<Spin spinning={loading} size="large">
				<div
					className="BlogBox mt50"
					style={{ backgroundColor: "#F7F9FB", padding: "40px 40px 20px 0px" }}
				>
					<Form
						name="advanced_search"
						className="ant-advanced-search-form"
						form={form}
						labelCol={{ span: 8 }}
						onFinish={onFinish}
					>
						<Row gutter={24}>
							<Col span={6}>
								<Form.Item name="game" label="Game">
									<Select
										getPopupContainer={(triggerNode) => triggerNode.parentNode}
										allowClear
										style={{ width: "100%" }}
										showSearch
										filterOption={false}
										placeholder="Please Select"
										onSearch={debounce((value) => {
											onSearchGame(value);
										}, 300)}
									>
										{sortData(gameSearch?.data, "gameEn")?.map(
											(item, index) => {
												return (
													<Option key={index} value={item?.id}>
														{item?.gameEn}
													</Option>
												);
											}
										)}
									</Select>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									name="relatedOrganizations"
									label="Related Organizations"
								>
									<Select
										allowClear
										getPopupContainer={(triggerNode) => triggerNode?.parentNode}
										showSearch
										filterOption={false}
										placeholder="Please Select"
										onSearch={debounce((value) => {
											onSearchCompany(value);
										}, 300)}
									>
										{sortData(organizationSearch?.data)?.map((item, index) => {
											return (
												<Option key={index} value={item?.id}>
													{item?.name}
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item label="Date" name="date">
									<RangePicker
										format="MMM, YYYY"
										getPopupContainer={(triggerNode) => triggerNode.parentNode}
										picker="month"
										defaultValue={[moment(nowMonth), moment(lastMonth)]}
									/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item name="genre" label="Genre">
									<Select
										// style={{ width: "80%" }}
										allowClear
										getPopupContainer={(triggerNode) => triggerNode?.parentNode}
										showSearch
										filterOption={false}
										placeholder="Please Select"
										onSearch={debounce((value) => {
											onSearchGenre(value);
										}, 300)}
									>
										{sortData(GenreList?.data, "genre")?.map((item, index) => {
											return (
												<Option key={index} value={item?.id}>
													{item?.lookupValue}
												</Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>

							<Col span={6}>
								<Space>
									<Button type="primary" htmlType="submit" className="primarySearch">
										Search
									</Button>
									<Button type="default" htmlType="submit" onClick={onReset}	className="secReset">
										Reset
									</Button>
								</Space>
							</Col>
						</Row>
					</Form>
				</div>
				<div className="CompanyBox">
					<Row className="RowBox mt30">
						<Col span={24}>{renderTable()}</Col>
					</Row>
				</div>
				{/* <div className="CompanyBox">
					<div className=" BlogBox mt20 wd100 ">
						<div className="RowBox BorderBox" style={{ marginBottom: 100 }}>
							<div className="Global_title Global_Jb_title BoxPadding">
								<h2 style={{ color: "#999999", padding: "15px", margin: 0 }}>
									Rank Change
								</h2>
							</div>
							<div className="Insights_List">
								<IcafeRank />
							</div>
						</div>
					</div>
				</div> */}
			</Spin>
		</div>
	);
};
