import React, { useState, useEffect } from "react";
import "moment/locale/en-au";
import { Row, Col, Table, Spin } from "antd";
import { effect, useStore } from "../../../utils/dva16";
import { NMarket, EGet } from "../../../models/contants";
import { priceSplitter } from "../../../utils/utils";
import  CustomEmpty  from "@/utils/CustomEmpty";
export default (props) => {
	const { marketList, loading } = useStore(NMarket);
	const [searchValues, setSearchValues] = useState({});
	const [sortState, setSortState] = useState(null);
	let sortType = {
		ascend: "asc",
		descend: "desc",
	};

	const columns = [
		{
			title: "No",
			dataIndex: "id",
			key: "id",
			sortOrder: sortState?.field == "id" && sortState?.order,
			sorter: (a, b) => a?.id - b?.id,
		},
		{
			title: "Country",
			dataIndex: "country",
			key: "country",
			sortOrder: sortState?.field == "country" && sortState?.order,
			sorter: (a, b) => a?.country?.localeCompare(b?.country),
		},
		{
			title: "Region",
			dataIndex: "region",
			key: "region",
			sortOrder: sortState?.field == "region" && sortState?.order,
			sorter: (a, b) => a?.region?.localeCompare(b?.region),
		},
		{
			title: "Year",
			dataIndex: "year",
			key: "year",
			sortOrder: sortState?.field == "year" && sortState?.order,
			sorter: (a, b) => {
				const dateA = new Date(a?.year);
				const dateB = new Date(b?.year);
				return dateA - dateB;
			},
		},
		{
			title: "GDP",
			dataIndex: "gdp",
			key: "gdp",
			sortOrder: sortState?.field == "gdp" && sortState?.order,
			sorter: (a, b) => Number(a?.gdp) - Number(b?.gdp),

			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.gdp)}</span>
					</>
				);
			},
		},
		{
			title: "GDP per Capita",
			dataIndex: "gdpPerCapita",
			key: "gdpPerCapita",
			sortOrder: sortState?.field == "gdpPerCapita" && sortState?.order,
			sorter: (a, b) => Number(a?.gdpPerCapita) - Number(b?.gdpPerCapita),
			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.gdpPerCapita)}</span>
					</>
				);
			},
		},
		{
			title: "Total Population",
			dataIndex: "totalPopulation",
			key: "totalPopulation",
			sortOrder: sortState?.field == "totalPopulation" && sortState?.order,
			sorter: (a, b) => Number(a?.totalPopulation) - Number(b?.totalPopulation),

			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.totalPopulation)}</span>
					</>
				);
			},
		},
		{
			title: "Internet Users",
			dataIndex: "internetUsers",
			key: "internetUsers",
			sortOrder: sortState?.field == "internetUsers" && sortState?.order,
			sorter: (a, b) => Number(a?.internetUsers) - Number(b?.internetUsers),

			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.internetUsers)}</span>
					</>
				);
			},
		},
		{
			title: "Smartphone Users",
			dataIndex: "smartphoneUsers",
			key: "smartphoneUsers",
			sortOrder: sortState?.field == "smartphoneUsers" && sortState?.order,
			sorter: (a, b) => Number(a?.smartphoneUsers) - Number(b?.smartphoneUsers),
			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.smartphoneUsers)}</span>
					</>
				);
			},
		},
		{
			title: "PC Gamers",
			dataIndex: "pcGamers",
			key: "pcGamers",
			sortOrder: sortState?.field == "pcGamers" && sortState?.order,
			sorter: (a, b) => Number(a?.pcGamers) - Number(b?.pcGamers),

			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.pcGamers)}</span>
					</>
				);
			},
		},
		{
			title: "Mobile Gamers",
			dataIndex: "mobileGamers",
			key: "mobileGamers",
			sortOrder: sortState?.field == "mobileGamers" && sortState?.order,
			sorter: (a, b) => Number(a?.mobileGamers) - Number(b?.mobileGamers),
			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.mobileGamers)}</span>
					</>
				);
			},
		},
		{
			title: "Console Gamers",
			dataIndex: "consoleGamers",
			key: "consoleGamers",
			sortOrder: sortState?.field == "consoleGamers" && sortState?.order,
			sorter: (a, b) => Number(a?.consoleGamers) - Number(b?.consoleGamers),
			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.consoleGamers)}</span>
					</>
				);
			},
		},
		{
			title: "Total Gamers",
			dataIndex: "totalGamers",
			key: "totalGamers",
			sortOrder: sortState?.field == "totalGamers" && sortState?.order,
			sorter: (a, b) => Number(a?.totalGamers) - Number(b?.totalGamers),
			render: (text, record) => {
				return (
					<>
						<span>{priceSplitter(record?.totalGamers)}</span>
					</>
				);
			},
		},
		{
			title: "PC Games Revenue",
			dataIndex: "pcGamesRevenue",
			key: "pcGamesRevenue",
			sortOrder: sortState?.field == "pcGamesRevenue" && sortState?.order,
			sorter: (a, b) => Number(a?.pcGamesRevenue) - Number(b?.pcGamesRevenue),
			render: (text, record) => {
				return (
					<>
						<span>$</span>
						<span>{priceSplitter(record?.pcGamesRevenue)}</span>
					</>
				);
			},
		},
		{
			title: "Mobile Games Revenue",
			dataIndex: "mobileGamesRevenue",
			key: "mobileGamesRevenue",
			sortOrder: sortState?.field == "mobileGamesRevenue" && sortState?.order,
			sorter: (a, b) =>
				Number(a?.mobileGamesRevenue) - Number(b?.mobileGamesRevenue),
			render: (text, record) => {
				return (
					<>
						<span>$</span>
						<span>{priceSplitter(record?.mobileGamesRevenue)}</span>
					</>
				);
			},
		},
		{
			title: "Console Games Revenue",
			dataIndex: "consoleGamesRevenue",
			key: "consoleGamesRevenue",
			sortOrder: sortState?.field == "consoleGamesRevenue" && sortState?.order,
			sorter: (a, b) =>
				Number(a?.consoleGamesRevenue) - Number(b?.consoleGamesRevenue),
			render: (text, record) => {
				return (
					<>
						<span>$</span>
						<span>{priceSplitter(record?.consoleGamesRevenue)}</span>
					</>
				);
			},
		},
		{
			title: "Total Revenue",
			dataIndex: "totalRevenue",
			key: "totalRevenue",
			sortOrder: sortState?.field == "totalRevenue" && sortState?.order,
			sorter: (a, b) => Number(a?.totalRevenue) - Number(b?.totalRevenue),
			render: (text, record) => {
				return (
					<>
						<span>$</span>
						<span>{priceSplitter(record?.totalRevenue)}</span>
					</>
				);
			},
		},
		{
			title: "Esports Revenues",
			dataIndex: "esportsRevenues",
			key: "esportsRevenues",
			sortOrder: sortState?.field == "esportsRevenues" && sortState?.order,
			sorter: (a, b) => Number(a?.esportsRevenues) - Number(b?.esportsRevenues),
			render: (text, record) => {
				return (
					<>
						<span>$</span>
						<span>{priceSplitter(record?.esportsRevenues)}</span>
					</>
				);
			},
		},
	];

	useEffect(() => {
		effect(NMarket, EGet, { page: 1, perPage: 10 });
		return () => {};
	}, []);

	return (
		<div>
			<Spin spinning={loading} size="large">
				<div className="CompanyBox">
					<Row className="RowBox mt30">
						<Col span={24}>
							<Table
								locale={{ emptyText: CustomEmpty }}
								scroll={{ x: "max-content" }}
								dataSource={marketList?.data || []}
								columns={columns}
								className="CompanyTableBox"
								rowKey="id"
								pagination={{
									current: parseInt(marketList && marketList?.page) || 1,
									total: (marketList && marketList?.total) || 0,
									defaultCurrent: 1,
									defaultPageSize:
										parseInt(marketList && marketList?.perPage) || 10,
									pageSize: marketList && marketList?.perPage,
									pageSizeOptions: ["10", "20", "30", "50", "100"],
									showQuickJumper: true,
									showSizeChanger: true,
									position: "bottom",
								}}
								onChange={(pagination, filter, sort) => {
									setSortState(sort);
									effect(NMarket, EGet, {
										...searchValues,
										page: pagination?.current,
										perPage: pagination?.pageSize,
										field: sort?.column && sort?.field,
										isDesc:
											(sort?.column && sort?.order && sortType[sort?.order]) ||
											null,
									});
								}}
							/>
						</Col>
					</Row>
				</div>
			</Spin>
		</div>
	);
};
