import React, { useState, useEffect } from "react";
import "./index.less";
import { Row, Col, Typography, Tag, Spin } from "antd";
import { effect, useStore, reducer } from "../../../utils/dva16";
import {
	EGet,
	EGetOne,
	NBlog,
	RSetState,
	MName,
} from "../../../models/contants";
const { Title, Link } = Typography;

export default (props) => {
	const { history } = props;
	const { detailList, loading } = useStore(NBlog);
	useEffect(() => {
		effect(NBlog, EGetOne, { id: props?.match?.params?.id });
		return () => {
			reducer(NBlog, RSetState, {
				detailList: null,
			});
		};
	}, []);

	useEffect(() => {
		effect(MName, EGet, {
			modelName: detailList?.title,
		});
	}, [detailList?.title]);
	return (
		<div className="BlogDetailLabel">
			<Spin spinning={loading} size="large">
				<div className="BlogDetailBox">
					<Row className="RowBox">
						<Col span={24}>
							<Title level={2} className="Global_title">
								{detailList?.title}
							</Title>
						</Col>
						<Col span={24}>
							<div className="Article_Info">
								<div className="Article_Info_Lf">
									<span
										onClick={() => {
											history.push({
												pathname: "/news_articles/blog_posts",
												state: { region: detailList?.region },
											});
										}}
									>
										Region: {detailList?.region}
									</span>
									<div className="Article_Info_Taglist">
										{detailList?.withCompanys?.map((vo, i) => (
											<Tag
												key={i}
												color="#9E219E"
												style={{ cursor: "pointer", borderRadius: "20px" }}
												onClick={() => {
													history.push({
														pathname: "/news_articles/blog_posts",
														state: { company: vo?.id },
													});
												}}
											>
												{vo?.name}
											</Tag>
										))}
										{detailList?.withGames?.map((vo, i) => (
											<Tag
												key={i}
												color="#19AE54"
												style={{ cursor: "pointer", borderRadius: "20px" }}
												onClick={() => {
													history.push({
														pathname: "/news_articles/blog_posts",
														state: { game: vo?.id },
													});
												}}
											>
												{vo?.gameEn}
											</Tag>
										))}
									</div>
								</div>
								<div className="Article_Info_Rg">
									<span
										onClick={() => {
											history.push({
												pathname: "/news_articles/blog_posts",
												state: { type: detailList?.type },
											});
										}}
									>
										Category: {detailList?.type?.trim()}
									</span>
								</div>
							</div>
						</Col>

						<Col span={24}>
							<div
								dangerouslySetInnerHTML={{ __html: detailList?.description }}
							></div>
						</Col>

						<Col span={24} className="mt20">
							<div className="report_Box">
								<p>
									Recommended report:
									<Link href={detailList?.report} target="_blank">
										{detailList?.report}
									</Link>
								</p>
							</div>
						</Col>
					</Row>
				</div>
			</Spin>
		</div>
	);
};
