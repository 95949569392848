import FreeReport from "./FreeReport";
import Global from "./Global";
import Login from "./Login";
import Game from "./Game";
import Blog from "./Blog";
import Company from "./Company";
import Genre from "./Genre";
import Platform from "./Platform";
import InsightsFeed from "./InsightsFeed";
import ISBN from "./ISBN";
import Tournament from "./Tournament";
import Team from "./Team";
import RevenueOverAll from "./RevenueOverAll";
import MAIPO from "./MAIPO";
import ICafeGame from "./ICafeGame";
import earnings from "./earnings";
import Stream from "./Stream";
import Region from "./Region";
import Advert from "./Advert";
import Exclusives from "./Exclusives";
import OrganizationMulti from "./OrganizationMulti";
import Exchange from "./Exchange";
import env from "./env";
import OrganizationMultiType from "./OrganizationMultiType";
import Common from "./Common";
import E from "./E";
import faq from "./faq";
import topic from "./topic";
import announcement from "./announcement";
import tab from "./tab";
import Market from "./Market";
import ModelName from "./ModelName";
import tournamentType from "./tournamentType";
import specialCharacteristics from "./specialCharacteristics";
import type from "./type";
let models = [
	Global,
	Login,
	FreeReport,
	Game,
	Blog,
	Company,
	Genre,
	Platform,
	InsightsFeed,
	ISBN,
	Tournament,
	Team,
	RevenueOverAll,
	MAIPO,
	ICafeGame,
	earnings,
	Stream,
	Region,
	Advert,
	Exclusives,
	OrganizationMulti,
	Exchange,
	OrganizationMultiType,
	Common,
	type,
	faq,
	env,
	topic,
	announcement,
	tab,
	Market,
	E,
	ModelName,
	tournamentType,
	specialCharacteristics,
];

export default models;
