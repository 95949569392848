import {
	NInsightType,
	EGetTypeSelect,
	// EGetType,
	// EPost,
	// EPut,
	// EDelete,
	RSetState,
} from "./contants";
import {
	requestGet,
	// requestPost,
	// requestPut,
	// requestDelete,
} from "@/utils/dva16";

export default {
	namespace: NInsightType,
	state: {
		Typelist: null,
		TypeSelectlist: null,
		load: false,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		// async [EGetType]({ payload, callback }, { call, reducer, select, effect }) {
		// 	reducer(RSetState, { load: true });
		// 	let res = await requestGet('type', payload);
		// 	reducer(RSetState, { load: false, Typelist: res });
		// },
		async [EGetTypeSelect](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			let res = await requestGet("typeSelect", payload);
			reducer(RSetState, { TypeSelectlist: res });
		},
		// async [EPost]({ payload, callback }, { call, reducer, select, effect }) {
		// 	await requestPost(`type`, payload);
		// 	callback && callback();
		// },
		// async [EPut]({ payload, callback }, { call, reducer, select, effect }) {
		// 	await requestPut(`type/${payload?.id}`, payload);
		// 	callback && callback();
		// },
		// async [EDelete]({ payload, callback }, { call, reducer, select, effect }) {
		// 	await requestDelete(`type/${payload?.id}`, payload);
		// 	callback && callback();
		// },
	},
};
