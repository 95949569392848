import React, { useEffect, useState } from "react";
import imgSource from "../../../assets";
import {
	Button,
	Col,
	Form,
	Row,
	Select,
	Input,
	Space,
	DatePicker,
	Pagination,
	Spin,
	Empty,
} from "antd";
import "./index.less";

const { Option } = Select;
import { debounce } from "lodash";
import {
	EGet,
	EGetActive,
	EGetAgencies,
	EGetArea,
	EGetCompany,
	EGetSponsor,
	EGetType,
	EPost,
	NCommon,
	NExclusives,
	NGame,
	NGenre,
	NOrganizationMulti,
	NPlatform,
	NRegion,
	EGetRegion,
	NLogin,
} from "../../../models/contants";
import sortData from "../../../utils/sorter";
import formatDate from "@/utils/dateFormater";
import { effect, useStore } from "../../../utils/dva16";
import E from "../../../models/E";
import moment from "moment";
import { customizeRenderEmpty } from "../../../utils/utils";
import { Helmet } from "react-helmet";
import CustomEmpty from "@/utils/CustomEmpty";
export default (props) => {
	const { history } = props;
	const {
		location: { state },
	} = history;
	const [form] = Form.useForm();
	const { OrganizationMultiList, OrganizationCompany } =
		useStore(NOrganizationMulti);

	const { areaList, regionList, commonAreaList } = useStore(NRegion);
	const { gameSearch } = useStore(NGame);
	const { GenreList } = useStore(NGenre);
	const { PlatformData } = useStore(NPlatform);
	// const { sponsorList, agencyList } = useStore(NCommon);
	const { exclusivesList, loading, activeNew } = useStore(NExclusives);
	const [searchValues, setSearchValues] = useState();
	const [more, setMore] = useState(false);
	// const { currentSubscribe, userInfo } = useStore(NLogin);

	useEffect(() => {
		if (state) {
			form.setFieldsValue({
				...state,
			});
			if (state?.company) {
				form.setFieldsValue({
					company: state?.company,
				});
			}
			setSearchValues({ ...searchValues, ...state });
			setMore(true);
		}
		// effect(NOrganizationMulti, EGet, { page: 1, perPage: 10 });
		effect(NPlatform, EGet, { page: 1, perPage: 25 });
		effect(NGenre, EGet, { page: 1, perPage: 30 });
		effect(NGame, EGet, { page: 1, perPage: 10 });
		effect(NGame, EGetType, { page: 1, perPage: 10 });
		effect(NPlatform, EGetType, {});
		effect(NExclusives, EGet, { ...state });
		effect(NExclusives, EGetActive, {});
		effect(NOrganizationMulti, EGetCompany, {
			page: 1,
			perPage: 10,
		});
		// effect(NCommon, EGetSponsor, { page: 1, perPage: 10 });
		// effect(NCommon, EGetAgencies, { page: 1, perPage: 10 });
		effect(NRegion, EGetArea, { page: 1, perPage: 200 });
		effect(NRegion, EGetRegion, { page: 1, perPage: 10 });
		effect(NLogin, EGet, {});
	}, []);
	const onPageChange = (page, pageSize) => {
		if (page && pageSize) {
			effect(NExclusives, EGet, {
				page: page || 1,
				perPage: pageSize || 10,
				...searchValues,
			});
		}
	};
	const onFinish = (values) => {
		if (values?.date) {
			values.startDate = moment(values?.date[0])?.format("YYYY-MM-DD HH:mm:ss");
			values.endDate = moment(values?.date[1])?.format("YYYY-MM-DD HH:mm:ss");
		}
		values.area = values.area && [
			...values?.area?.map((areaValue) => {
				return commonAreaList?.data?.find(
					(item) => item?.otherData === areaValue
				)?.id;
			}),
			...values.area,
		];

		values.platform = values?.platform?.map((areaValue) => {
			return PlatformData?.data?.find((item) => item.lookupValue === areaValue)
				?.id;
		});
		setSearchValues(values);
		delete values?.date;
		effect(NExclusives, EGet, { ...values, page: 1, perPage: 10 });
	};

	const onReset = () => {
		setSearchValues();
		form.resetFields();
		effect(NExclusives, EGet, { page: 1, perPage: 10 });
	};
	// const [regionArr, setRegionArr] = useState([]);
	function findRegionType(arr) {
		const updatedRegionArr = [];
		commonAreaList?.data?.forEach((item) => {
			if (arr?.includes(item?.otherData)) {
				updatedRegionArr?.push(item?.lookupValue);
			}
		});

		form.setFieldsValue({
			region: Array.from(new Set(updatedRegionArr)),
		});
	}
	//用户操作判断 | User operation judgment
	const onAction = (id, mode, url, title) => {
		if (mode == E?.operate?.Share) {
			effect(NExclusives, EPost, { exclusivesID: id, type: E?.operate?.Share });
			//跳转outlook并且填充内容
			const body = `Hello, \n\n Please copy this link to share the NikoIQ Exclusives report:${url} \n\n Thank You`;
			window.open(
				`https://outlook.live.com/owa/?path=/mail/action/compose&to=&subject=${`NikoIQ Exclusives-${title}`}&body=${encodeURIComponent(
					body
				)}`
			);
		} else {
			effect(
				NExclusives,
				EPost,
				{ exclusivesID: id, type: E?.operate?.Download },
				() => {}
			);
			//查看PDF | View PDF
			window.open(url);
		}
	};
	//默认展示活跃文章 | Default display of active articles

	const defaultShow = () => {
		const date = new Date(activeNew && activeNew?.date);
		const formattedDate = formatDate(date);
		return (
			<div className="active">
				<div className="head">
					<div className="left_title">
						<img src={imgSource?.icon_article} className="icon_article"></img>
						<span>{activeNew?.title}</span>
					</div>
					<div className="right_time">{formattedDate}</div>
				</div>
				<div className="title">{activeNew?.subHeader}</div>
				<div className="rich_pdf">
					{activeNew?.pdf && <embed src={activeNew?.pdf}></embed>}
					<div className="controls">
						<div
							className="btn_action"
							onClick={() => {
								onAction(
									activeNew?.id,
									E?.operate?.Download,
									activeNew?.pdf,
									activeNew?.title
								);
							}}
						>
							<div className="icon_action">
								<img src={imgSource?.icon_pdf} className="icon"></img>
							</div>
							<span className="option">Click here for PDF</span>
						</div>
						<div
							className="btn_action"
							onClick={() => {
								onAction(
									activeNew?.id,
									E?.operate?.Share,
									activeNew?.pdf,
									activeNew?.title
								);
							}}
						>
							<div className="icon_action">
								<img src={imgSource?.icon_share} className="icon"></img>
							</div>
							<span className="option">share</span>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const dataBlock = (v, i) => {
		const date = new Date(v?.date);
		const formattedDate = formatDate(date);

		return (
			<div className="data_item" key={i}>
				<div className="head">
					<span className="title">{v?.title}</span>
					<span className="time">{formattedDate}</span>
				</div>
				<div className="mid">{v?.subHeader}</div>
				<div className="bottom">
					<div
						className="action"
						onClick={() => {
							onAction(v?.id, E?.operate?.Download, v?.pdf, v?.title);
						}}
					>
						<img src={imgSource?.icon_pdf}></img>
						<span>Click here for PDF</span>
					</div>
					<div
						className="action"
						onClick={() => {
							onAction(v?.id, E?.operate?.Share, v?.pdf, v?.title);
						}}
					>
						<img src={imgSource?.icon_share}></img>
						<span>Share</span>
					</div>
				</div>
			</div>
		);
	};
	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetCompany, {
			name: value,
			page: 1,
			perPage: 10,
		});
	};
	const onSearchGame = (value) => {
		// effect(NGame, EGet, { ...{ keyword: value }, page: 1, perPage: 10 });
		effect(NGame, EGetType, { ...{ name: value }, page: 1, perPage: 10 });
	};
	const onSearchGenre = (value) => {
		effect(NGenre, EGet, { ...{ genre: value }, page: 1, perPage: 10 });
	};
	const onSearchPlatform = (value) => {
		effect(NPlatform, EGet, { ...{ platform: value }, page: 1, perPage: 10 });
	};
	const onSearchSponsor = (value) => {
		effect(NCommon, EGetSponsor, {
			company: value,
			page: 1,
			perPage: 10,
		});
	};
	const onSearchAgency = (value) => {
		effect(NCommon, EGetAgencies, {
			company: value,
			page: 1,
			perPage: 10,
		});
	};
	return (
		<div className="exclusives_container">
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
	        	`}
				</script>
			</Helmet>
			<Spin spinning={loading} size="large">
				{activeNew && defaultShow()}
				{exclusivesList?.error == 1009 && customizeRenderEmpty()}
				{exclusivesList?.error != 1009 && (
					<>
						{more == false && loading == false && (
							<div className="history_box">
								<Button
									type="primary"
									className="history"
									onClick={() => {
										setMore(true);
									}}
								>
									See Past NikoIQ Exclusives
								</Button>
							</div>
						)}
						{more == true && (
							<>
								<div className="form_box">
									<Form labelCol={{ span: 8 }} form={form} onFinish={onFinish}>
										<Row>
											<Col span={8} xs={24} md={8}>
												<Form.Item name="area" label="Area">
													<Select
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														mode="multiple"
														placeholder="Please Search"
														allowClear
														showSearch
														onChange={(e) => {
															findRegionType(e);
														}}
														onSearch={debounce((value) => {
															const { page, perPage } = areaList;
															effect(NRegion, EGetArea, {
																page,
																perPage,
																area: value,
															});
														}, 300)}
														filterOption={false}
													>
														{sortData(areaList?.data, "area")?.map(
															(record, index) => {
																return (
																	<Option
																		key={record?.id}
																		value={record?.otherData}
																	>
																		{record?.otherData}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
											<Col span={8} xs={24} md={8}>
												<Form.Item name="region" label="Region">
													<Select
														mode="multiple"
														getPopupContainer={(triggerNode) =>
															triggerNode.parentNode
														}
														placeholder="Please Select"
													>
														{E?.mainEnum?.map((vo, index) => (
															<Option key={index} value={vo?.text}>
																{vo?.text}
															</Option>
														))}
													</Select>
												</Form.Item>
											</Col>

											<Col span={8} xs={24} md={8}>
												<Form.Item name="keyWord" label="Keyword(s)">
													<Input
														placeholder="Please input the keywords"
														autoComplete="off"
													/>
												</Form.Item>
											</Col>
											<Col span={8} xs={24} md={8}>
												<Form.Item name="date" label="Date">
													<DatePicker.RangePicker
														format="MMM DD, YYYY"
														style={{ width: "100%" }}
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
													/>
												</Form.Item>
											</Col>
											<Col span={8} xs={24} md={8}>
												<Form.Item name="game" label="Game">
													<Select
														allowClear
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														filterOption={false}
														placeholder="Please Select"
														onSearch={debounce((value) => {
															onSearchGame(value);
														}, 100)}
													>
														{sortData(gameSearch?.data, "gameEn")?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.id}>
																		{item?.gameEn}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
											<Col span={8} xs={24} md={8}>
												<Form.Item name="company" label="Organization">
													<Select
														allowClear
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														filterOption={false}
														placeholder="Please Select"
														onSearch={debounce((value) => {
															onSearchCompany(value);
														}, 300)}
													>
														{sortData(OrganizationCompany?.data)?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.id}>
																		{item?.name}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>

											{/* <Col span={8} xs={24} md={8}>
												<Form.Item name="sponsors" label="Sponsors">
													<Select
														mode="multiple"
														allowClear
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														filterOption={false}
														placeholder="Please Select"
														onSearch={debounce((value) => {
															onSearchSponsor(value);
														}, 300)}
													>
														{sortData(sponsorList?.data)?.map((item, index) => {
															return (
																<Option key={index} value={item?.id}>
																	{item?.name}
																</Option>
															);
														})}
													</Select>
												</Form.Item>
											</Col> */}
											{/* <Col span={8} xs={24} md={8}>
												<Form.Item
													name="regulatory"
													label="Regulatory Agencies"
												>
													<Select
														mode="multiple"
														allowClear
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														filterOption={false}
														placeholder="Please Select"
														onSearch={debounce((value) => {
															onSearchAgency(value);
														}, 300)}
													>
														{sortData(agencyList?.data)?.map((item, index) => {
															return (
																<Option key={index} value={item?.id}>
																	{item?.name}
																</Option>
															);
														})}
													</Select>
												</Form.Item>
											</Col> */}
											<Col span={8} xs={24} md={8}>
												<Form.Item name="genre" label="Genre">
													<Select
														allowClear
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														// filterOption={false}
														placeholder="Please Select"
														onSearch={debounce((value) => {
															onSearchGenre(value);
														}, 300)}
														filterOption={(input, option) =>
															option?.children
																?.toLowerCase()
																?.indexOf(input?.toLowerCase()) >= 0
														}
													>
														{sortData(GenreList?.data, "genre")?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.id}>
																		{item?.lookupValue}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
											<Col span={8} xs={24} md={8}>
												<Form.Item
													name="platform"
													label="Platform"
													rules={[
														{
															required: false,
															message: "Input something!",
														},
													]}
												>
													<Select
														mode="multiple"
														allowClear
														getPopupContainer={(triggerNode) =>
															triggerNode?.parentNode
														}
														showSearch
														filterOption={false}
														placeholder="Please Select"
														onSearch={debounce((value) => {
															onSearchPlatform(value);
														}, 300)}
													>
														{sortData(PlatformData?.data, "platform")?.map(
															(item, index) => {
																return (
																	<Option key={index} value={item?.lookupValue}>
																		{item?.lookupValue}
																	</Option>
																);
															}
														)}
													</Select>
												</Form.Item>
											</Col>
											<Col span={8} xs={24} style={{ textAlign: "right" }}>
												<Space>
													<Button
														type="primary"
														htmlType="submit"
														className="primarySearch"
													>
														Search
													</Button>
													<Button
														type="default"
														onClick={onReset}
														className="secReset"
													>
														Reset
													</Button>
												</Space>
											</Col>
										</Row>
									</Form>
								</div>
								<div className="data_list">
									{exclusivesList?.data?.map((v, i) => {
										return dataBlock(v, i);
									})}
									{exclusivesList?.total == 0 &&
										exclusivesList?.error != 1009 && <CustomEmpty />}
									<Row>
										<Col
											span={24}
											style={{ textAlign: "right", paddingRight: 24 }}
										>
											<Pagination
												current={exclusivesList?.page}
												total={exclusivesList?.total}
												pageSize={exclusivesList?.perPage || 10}
												onChange={onPageChange}
												showSizeChanger
												showQuickJumper
												showTotal={(total) => `Total ${total} items`}
											/>
										</Col>
									</Row>
								</div>
							</>
						)}
					</>
				)}
			</Spin>
		</div>
	);
};
