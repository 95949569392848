import "./index2.less";
import imgSource from "../../assets";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { Row, Col, Card, Divider } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Image } from "antd";
const { Meta } = Card;

export default (props) => {
	const { history } = props;
	const isSmallScreen = useMediaQuery({ maxWidth: 480 });

	return (
		<div className="newHomeContainer">
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>

				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<div className="head">
				<img src={imgSource?.logoNiko} className="head_img"></img>
				<Divider
					type={isSmallScreen ? "horizontal" : "vertical"}
					className="divider"
				/>
				<div className="imgTitle">
					<p>Smarter Business Decisions, Simplified</p>
				</div>
			</div>
			<div>
				<Row
					gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}
					style={{ marginTop: "2rem" }}
					justify="center"
				>
					<Col xs={20} sm={12} md={11} className="cardContainer customCol">
						<Card
							title="Market Model"
							className="card"
							headStyle={{
								backgroundColor: "#ecf4fa",
								opacity: "84%",
								fontSize: "33px",
								color: "black",
								borderRadius: "20px 20px 0 0",
								padding: "14px 0",
							}}
							bodyStyle={{ padding: "2vw 2vw 1vw", height: "85%" }}
						>
							<div className="card-content">
								<div className="top-part">
									<div className="card-description">
										Explore market models with a data visualization tool.
									</div>
									<div>
										<Image
											preview={false}
											src={imgSource?.home_market}
											className="home_market"
										></Image>
									</div>
								</div>
								<div className="bottom-part">
									<p
										className="card-link "
										onClick={() => {
											history.push({
												pathname: "/market_model",
											});
										}}
									>
										Go to Module{" "}
										<Image
											preview={false}
											src={imgSource?.right_arrow}
											className="linkIcon"
										></Image>
									</p>
								</div>
							</div>
						</Card>
					</Col>
					<Col xs={20} sm={12} md={11} className="cardContainer customCol">
						<Card
							title="News & Articles"
							className="card"
							headStyle={{
								backgroundColor: "#ecf4fa",
								opacity: "84%",
								fontSize: "33px",
								color: "black",
								borderRadius: "20px 20px 0 0",
								padding: "14px 0",
							}}
							bodyStyle={{ padding: "2vw 2vw 1vw", height: "85%" }}
						>
							<div className="card-content">
								<div className="top-part">
									<p className="card-description">
										Keep current on curated country level news articles with
										Niko’s analysis, plus our blog posts.
									</p>
									<div>
										<Image
											src={imgSource?.home_news}
											className="home_news"
											preview={false}
										></Image>
									</div>
								</div>
								<div className="bottom-part">
									<div className="card-links">
										<p
											className="card-link"
											onClick={() => {
												history.push({
													pathname: "/news_articles/VANA",
												});
											}}
										>
											Go to VANA{" "}
											<Image
												preview={false}
												src={imgSource?.right_arrow}
												className="linkIcon"
											></Image>
										</p>
										<p
											className="card-link"
											onClick={() => {
												history.push({
													pathname: "/news_articles/blog_posts",
												});
											}}
										>
											Go to Blog Posts{" "}
											<Image
												preview={false}
												src={imgSource?.right_arrow}
												className="linkIcon"
											></Image>
										</p>
									</div>
								</div>
							</div>
						</Card>
					</Col>
					<Col xs={20} sm={12} md={11} className="cardContainer customCol">
						<Card
							title="Games & Organizations"
							className="card"
							headStyle={{
								backgroundColor: "#ecf4fa",
								opacity: "84%",
								fontSize: "33px",
								color: "black",
								borderRadius: "20px 20px 0 0",
								padding: "14px 0",
							}}
							bodyStyle={{ padding: "2vw 2vw 1vw", height: "85%" }}
						>
							<div className="card-content">
								<div className="top-part">
									<p className="card-description">
										Filter the knowledge base for game & organization data
										including news & insights.
									</p>
									<div>
										<Image
											preview={false}
											src={imgSource?.home_games}
											className="home_games"
										></Image>
									</div>
								</div>
								<div className="bottom-part">
									<div className="card-links">
										<p
											className="card-link"
											onClick={() => {
												history.push({
													pathname: "/games_organizations/games",
												});
											}}
										>
											Go to Games{" "}
											<Image
												preview={false}
												src={imgSource?.right_arrow}
												className="linkIcon"
											></Image>
										</p>
										<p
											className="card-link"
											onClick={() => {
												history.push({
													pathname: "/games_organizations/organizations",
												});
											}}
										>
											Go to Organizations{" "}
											<Image
												preview={false}
												src={imgSource?.right_arrow}
												className="linkIcon"
											></Image>
										</p>
									</div>
								</div>
							</div>
						</Card>
					</Col>
					<Col xs={20} sm={12} md={11} className="cardContainer customCol">
						<Card
							title="NikoIQ Exclusives"
							className="card"
							headStyle={{
								backgroundColor: "#ecf4fa",
								opacity: "84%",
								fontSize: "33px",
								color: "black",
								borderRadius: "20px 20px 0 0",
								padding: "14px 0",
							}}
							bodyStyle={{ padding: "2vw 2vw 1vw", height: "85%" }}
						>
							<div className="card-content">
								<div className="top-part">
									<p className="card-description">
										Dig deeper with our analysis on timely and interesting facts
										and trends we find in our data, in brief reports. Exclusive
										to NikoIQ.
									</p>
									<div>
										<Image
											preview={false}
											src={imgSource?.home_exclusives}
											className="home_exclusives"
										></Image>
									</div>
								</div>
								<div className="bottom-part">
									<p
										className="card-link"
										onClick={() => {
											history.push({
												pathname: "/nikoiq_exclusives",
											});
										}}
									>
										Go to Module{" "}
										<Image
											preview={false}
											src={imgSource?.right_arrow}
											className="linkIcon"
										></Image>
									</p>
								</div>
							</div>
						</Card>
					</Col>
				</Row>
				<Row
					gutter={{
						xs: 8,
						sm: 16,
						md: 24,
						lg: 32,
					}}
					style={{ marginTop: "2rem" }}
					justify="center"
				>
					<Col xs={20} sm={12} md={11} className="cardContainer customCol">
						<Card
							title="Esports"
							className="card"
							headStyle={{
								backgroundColor: "#ecf4fa",
								opacity: "84%",
								fontSize: "33px",
								color: "black",
								borderRadius: "20px 20px 0 0",
								padding: "14px 0",
							}}
							bodyStyle={{ padding: "2vw 2vw 1vw", height: "85%" }}
						>
							<div className="card-content">
								<div className="top-part">
									<p className="card-description">
										Track & analyze the latest esports data including
										tournaments, prize pools & games.
									</p>
									<div>
										<Image
											preview={false}
											src={imgSource?.home_esports}
											className="home_esports"
										></Image>
									</div>
								</div>
								<div className="bottom-part">
									<p
										className="card-link"
										onClick={() => {
											history.push({
												pathname: "/esports",
											});
										}}
									>
										Go to Module{" "}
										<Image
											preview={false}
											src={imgSource?.right_arrow}
											className="linkIcon"
										></Image>
									</p>
								</div>
							</div>
						</Card>
					</Col>
					<Col xs={20} sm={12} md={11} className="cardContainer customCol">
						<Card
							title="China Data"
							className="card"
							headStyle={{
								backgroundColor: "#ecf4fa",
								opacity: "84%",
								fontSize: "33px",
								color: "black",
								borderRadius: "20px 20px 0 0",
								padding: "14px 0",
							}}
							bodyStyle={{ padding: "2vw 2vw 1vw", height: "85%" }}
						>
							<div className="card-content">
								<div className="top-part">
									<p className="card-description">
										Track China data in one place: NPPA game licenses, games
										played in internet cafes, and more.
									</p>
									<div>
										<Image
											preview={false}
											src={imgSource?.home_china_data}
											className="home_china_data"
										></Image>
									</div>
								</div>
								<div className="bottom-part">
									<div className="card-links">
										<p
											className="card-link"
											onClick={() => {
												history.push({
													pathname: "/china_data/internet_cafe",
												});
											}}
										>
											Go to Internet Cafe{" "}
											<Image
												preview={false}
												src={imgSource?.right_arrow}
												className="linkIcon"
											></Image>
										</p>
										<p
											className="card-link"
											onClick={() => {
												history.push({
													pathname: "/china_data/isbn",
												});
											}}
										>
											Go to ISBN
											<Image
												preview={false}
												src={imgSource?.right_arrow}
												className="linkIcon"
											></Image>
										</p>
									</div>
								</div>
							</div>
						</Card>
					</Col>
					<Col xs={20} sm={12} md={11} className="cardContainer customCol">
						<Card
							title="Financial Data"
							className="card"
							headStyle={{
								backgroundColor: "#ecf4fa",
								opacity: "84%",
								fontSize: "33px",
								color: "black",
								borderRadius: "20px 20px 0 0",
								padding: "14px 0",
							}}
							bodyStyle={{ padding: "2vw 2vw 1vw", height: "85%" }}
						>
							<div className="card-content">
								<div className="top-part">
									<p className="card-description">
										Gain context on financial and earnings data about key
										companies exposed to games markets in Asia & MENA.
									</p>
									<div>
										<Image
											preview={false}
											src={imgSource?.home_financial_data}
											className="home_financial_data"
										></Image>
									</div>
								</div>
								<div className="bottom-part">
									<p className="card-soon">Coming Soon</p>
								</div>
							</div>
						</Card>
					</Col>
					<Col xs={20} sm={12} md={11} className="cardContainer customCol">
						<Card
							title="Gamer Insights"
							className="card"
							headStyle={{
								backgroundColor: "#ecf4fa",
								opacity: "84%",
								fontSize: "33px",
								color: "black",
								borderRadius: "20px 20px 0 0",
								padding: "14px 0",
							}}
							bodyStyle={{ padding: "2vw 2vw 1vw", height: "85%" }}
						>
							<div className="card-content">
								<div className="top-part">
									<p className="card-description">
										Filter consumer demographic & behavior data via proprietary
										gamer survey results.
									</p>
									<div>
										<Image
											preview={false}
											src={imgSource?.home_gamer_insights}
											className="home_gamer_insights"
										></Image>
									</div>
								</div>
								<div className="bottom-part">
									<p className="card-soon">Coming Soon</p>
								</div>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	);
};
