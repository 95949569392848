import React, { useState, useEffect } from "react";
import moment from "moment";
import "./index.less";
import imgSource from "../../../assets";
import { effect, useStore } from "../../../utils/dva16";
import formatDate from "@/utils/dateFormater";
import {
	EGetDetail,
	EGetTop,
	NInsightsFeed,
	EGet,
	MName,
} from "../../../models/contants";
import { Helmet } from "react-helmet";

export default (props) => {
	const { topNewList } = useStore(NInsightsFeed);
	const { history } = props;
	const {
		location: { state },
	} = history;
	const { InsightDetails } = useStore(NInsightsFeed);
	const [topNews, setTopNews] = useState([]);
	useEffect(() => {
		effect(NInsightsFeed, EGetDetail, { id: props?.match?.params?.id });

		return () => {};
	}, [props]);
	useEffect(() => {
		effect(NInsightsFeed, EGetTop, { page: 1, pageSize: 10 });
	}, []);
	useEffect(() => {
		if (topNewList) {
			//筛选出不是当前文章的数据 | Filter out data that is not the current article
			let arr = topNewList?.data?.filter((item) => {
				return item?.id != props?.match?.params?.id;
			});
			setTopNews(arr);
		}
	}, [topNewList, props]);
	useEffect(() => {
		effect(MName, EGet, {
			modelName: InsightDetails?.title,
		});
	}, [InsightDetails?.title]);
	//从sessionStorage中获取当前页面和每页的项目数，并将页面减1。然后，它调用effect函数以获取新页面的数据 | Retrieve the current page and the number of items per page from sessionStorage, and subtract 1 from the page. Then, it calls the effect function to obtain the data for the new page
	const prev = () => {
		let page = sessionStorage.getItem("page");
		let perPage = sessionStorage.getItem("perPage");
		let index = sessionStorage.getItem("index");
		if (page == 1 && index == 0) {
			return;
		}
		if (page > 1 && index == 0) {
			sessionStorage.setItem("page", page - 1);
			effect(
				NInsightsFeed,
				EGet,
				{
					page: page - 1,
					pageSize: perPage,
				},
				(res) => {
					if (res) {
						sessionStorage.setItem("arr", JSON.stringify(res?.data));
						let arr = JSON.parse(sessionStorage.getItem("arr"));
						let id = arr[Number(perPage - 1)]?.id;
						sessionStorage.setItem("index", perPage - 1);
						history.push(`/news_articles/vana/details/${id}`);
					}
				}
			);
		} else {
			let arr = JSON.parse(sessionStorage.getItem("arr"));
			let id = arr[Number(index - 1)].id;
			sessionStorage.setItem("index", index - 1);
			history.push(`/news_articles/vana/details/${id}`);
		}
	};
	//从sessionStorage中获取当前页面和每页的项目数，并将页面加1。然后，它调用effect函数以获取新页面的数据 | Retrieve the current page and the number of items per page from sessionStorage, and add 1 to the page. Then, it calls the effect function to obtain the data for the new page
	const next = () => {
		let page = sessionStorage.getItem("page");
		let lastPage = sessionStorage.getItem("lastPage");
		let perPage = sessionStorage.getItem("perPage");
		let index = sessionStorage.getItem("index");
		if (page == lastPage && index == perPage - 1) {
			return;
		}
		if (page >= 1 && index == perPage - 1) {
			let page1 = Number(page) + 1;
			sessionStorage.setItem("page", page1);
			effect(
				NInsightsFeed,
				EGet,
				{
					page: page1,
					pageSize: perPage,
				},
				(res) => {
					if (res) {
						sessionStorage.setItem("arr", JSON.stringify(res?.data));
						let arr = JSON.parse(sessionStorage.getItem("arr"));
						let id = arr[Number(0)]?.id;
						sessionStorage.setItem("index", Number(0));
						sessionStorage.setItem("lastPage", res?.lastPage);
						history.push(`/news_articles/vana/details/${id}`);
					}
				}
			);
		} else {
			effect(
				NInsightsFeed,
				EGet,
				{
					page: page,
					pageSize: perPage,
				},
				(res) => {
					if (res) {
						let arr = JSON.parse(sessionStorage.getItem("arr"));
						let index2 = Number(index) + 1;
						let id = arr[index2].id;
						sessionStorage.setItem("index", index2);
						sessionStorage.setItem("lastPage", res.lastPage);
						history.push(`/news_articles/vana/details/${id}`);
					}
				}
			);
		}
	};
	// console.log(InsightDetails?.createdAt);
	const date = new Date(InsightDetails && InsightDetails?.createdAt);
	const formattedDate = formatDate(date);
	return (
		<div className="newDetail">
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          				gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<div className="newContainer">
				<div className="title">{InsightDetails?.title}</div>
				<div className="headerInfo">
					<div>
						<span
							className="link"
							onClick={() => {
								history.push({
									pathname: "/news_articles/VANA",
									state: { region: InsightDetails?.region },
								});
							}}
						>
							Region:{" "}
							{InsightDetails?.region?.map((vo) => {
								return <span style={{ marginRight: 8 }}>{vo}</span>;
							})}
						</span>
						<span
							className="link"
							onClick={() => {
								console.log(InsightDetails);
								history.push({
									pathname: "/news_articles/VANA",
									state: {
										type: InsightDetails?.type,
										subType: InsightDetails?.subType,
									},
								});
							}}
						>
							Category: {InsightDetails?.type}
						</span>
					</div>
					<div>
						{/* <span>Creator: {InsightDetails?.createrInfo?.realName}</span> */}
						<span>
							{formattedDate}
							{/* {moment(InsightDetails?.createrInfo?.date).format("YYYY-MM-DD")} */}
						</span>
					</div>
				</div>
				<div
					className="contentBox"
					dangerouslySetInnerHTML={{
						__html: InsightDetails?.content,
					}}
				></div>
				<div className="footerInfo">
					<div className="link">
						<a href={InsightDetails?.link} target="_blank">
							{InsightDetails?.link}
						</a>
					</div>
					<div className="companyTags">
						{InsightDetails?.withCompanys?.map((item) => (
							<div
								key={item?.id}
								className="tag"
								title={item?.name}
								onClick={() => {
									history.push({
										pathname: "/news_articles/VANA",
										state: { company: item?.id },
									});
								}}
							>
								<span>{item?.name}</span>
							</div>
						))}
						{InsightDetails?.withTeams?.map((item) => (
							<div
								key={item?.id}
								className="tag"
								title={item?.esportTeamEn}
								onClick={() => {
									history.push({
										pathname: "/news_articles/VANA",
										state: { teamID: item?.id },
									});
								}}
							>
								<span>{item?.esportTeamEn}</span>
							</div>
						))}
					</div>
					<div className="gameTags">
						{InsightDetails?.withGames?.map((item) => (
							<div
								key={item?.id}
								className="tag"
								title={item?.gameEn}
								onClick={() => {
									history.push({
										pathname: "/news_articles/VANA",
										state: { game: item?.id },
									});
								}}
							>
								<span title="">{item?.gameEn}</span>
							</div>
						))}
					</div>
					<div className="btns">
						<div onClick={prev}>
							<img src={imgSource?.arrow_left}></img>
							<span>Previous</span>
						</div>
						<div onClick={next}>
							<span>Next</span>
							<img src={imgSource?.arrow_right}></img>
						</div>
					</div>
				</div>
				<div className="topNews">
					<div className="title">Recommended News</div>
					{topNews?.slice(0, 3)?.map((item, index) => (
						<div className="newsItem" key={index}>
							<div
								className="head"
								onClick={() => {
									history.push(`/news_articles/vana/details/${item?.id}`);
									window.scroll(0, 0);
								}}
							>
								{item?.title}
							</div>
							<div
								className="text"
								onClick={() => {
									history.push(`/news_articles/vana/details/${item?.id}`);
									window.scroll(0, 0);
								}}
							>
								{item?.content?.replace(/<[^>]+>/g, "")}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
