import React from "react";
import { useEffect, useState } from "react";
import "../Login/index.less";
import { Typography, Collapse, Input, Button, Form, Row, Col } from "antd";
const { Link } = Typography;
const { Panel } = Collapse;
import { SearchOutlined } from "@ant-design/icons";
import { EGet, NFAQ, NAnnouncement, NTopics } from "../../models/contants";
import { effect, useStore } from "@/utils/dva16";
import { Spin } from "antd";

const FAQpage = (props) => {
	const { faqList, load } = useStore(NFAQ);
	const { announcementList, loadAnnouncement } = useStore(NAnnouncement);
	const { topicsList, loadTopics } = useStore(NTopics);
	const [form] = Form.useForm();
	const [searchValues, setSearchValues] = useState({});
	const { history } = props;

	useEffect(() => {
		effect(NFAQ, EGet, { page: 1, perPage: 10 });
		effect(NAnnouncement, EGet, { page: 1, perPage: 10 });
		effect(NTopics, EGet, { page: 1, perPage: 10 });
	}, []);

	const onSearch = (value) => {
		setSearchValues(value);
		effect(NFAQ, EGet, {
			page: 1,
			perPage: 10,
			...value,
		});
	};

	const onChange = (key) => {
		// console.log(key);
	};

	return (
		<div>
			<div className="LoginBox">
				<header>
					<div className="LogoBox">
						<Link href="">
							<img
								src={require("@/assets/icon/logo_niko.png")?.default}
								onClick={() => history.replace("/login")}
							/>
						</Link>
					</div>
					<nav className="">
						<Link
							style={{ fontWeight: "700", fontSize: "1.5rem" }}
							onClick={() => {
								history.replace("/FAQ");
							}}
						>
							FAQ
						</Link>
						<Link
							style={{ fontWeight: "700", fontSize: "1.5rem" }}
							onClick={() => {
								window.open("https://nikopartners.com/about/");
							}}
						>
							About NikoIQ
						</Link>
						<Link
							style={{ fontWeight: "700", fontSize: "1.5rem" }}
							onClick={() => {
								window.open("https://nikopartners.com/");
							}}
						>
							Niko Partners
						</Link>
					</nav>
				</header>
				<div className="titleFAQ">
					{/* <h1>FAQ</h1> */}
				</div>
				<Form form={form} onFinish={onSearch}>
					<Row gutter={24} justify={"center"}>
						<Col span={8}>
							<Form.Item name="question">
								<Input
									placeholder="Search for a question"
									style={{ width: "100%", borderRadius: "20px" }}
									prefix={
										<SearchOutlined
											className="site-form-item-icon"
											style={{ fontSize: "2rem" }}
										/>
									}
								/>
							</Form.Item>
						</Col>
						<Col span={2}>
							<Button className="primarySearch" htmlType="submit">
								Search
							</Button>
						</Col>
					</Row>
				</Form>
				<div className="faqContainer">
					<h3>FAQs:</h3>
					<div className="orangeLine"></div>
					<Collapse onChange={onChange} className="faqAnswer">
						{faqList?.data?.map((i, k) => {
							return (
								<Panel header={i?.question} key={k}>
									<p className="faqAnswer">{i?.answer}</p>
								</Panel>
							);
						})}
					</Collapse>
				</div>
				{announcementList?.data?.length > 0 && (
					<div className="faqContainer">
						<h3>Announcements:</h3>
						<div className="orangeLine"></div>
						<Collapse onChange={onChange} className="faqAnswer">
							{announcementList?.data?.map((i, k) => {
								return (
									<Panel header={i?.title} key={k}>
										<p className="faqAnswer">{i?.content}</p>
									</Panel>
								);
							})}
						</Collapse>
					</div>
				)}
				{topicsList?.data?.length > 0 && (
					<div className="faqContainer">
						<h3>Topics:</h3>
						<div className="orangeLine"></div>
						<div className="topics">
							{topicsList?.data?.map((topic) => {
								return (
									<div className="topicsContainer">
										<p className="topicTitle">{topic?.title}</p>
										<p className="topicContent">{topic?.content}</p>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default FAQpage;
