/* -------------------namespace------------------- */
export const NGlobal = "NGlobal";
export const NLogin = "NLogin";
export const NFreeReport = "NFreeReport";
export const NGame = "NGame";
export const EGeTPage = "EGeTPage";
export const NBlog = "NBlog";
export const NCompany = "NCompany";
export const NGenre = "NGenre";
export const NPlatform = "NPlatform";
export const NInsightsFeed = "NInsightsFeed";
export const NISBN = "NISBN";
export const NTournament = "NTournament";
export const NTeam = "NTeam";
export const NOrganization = "NOrganization";
export const NRevenueOverAll = "NRevenueOverAll";
export const NMAIPO = "NMAIPO";
export const NICafeGame = "NICafeGame";
export const NEarnings = "NEarnings";
export const NStream = "NStream";
export const NRegion = "NRegion";
export const EGetRegion = "EGetRegion";
export const Nadvert = "Nadvert";
export const NExclusives = "NExclusives";
export const NOrganizationMulti = "NOrganizationMulti";
export const NExchange = "NExchange";
export const NOrganizationMultiType = "NOrganizationMultiType";
export const NCommon = "NCommon";
export const NFAQ = "NFAQ";
export const NTopics = "NTopics";
export const NAnnouncement = "NAnnouncement";
export const NTab = "NTab";
export const NMarket = "NMarket";
export const NTournamentType = "NTournamentType";
export const NSpecialCharacteristics = "NSpecialCharacteristics";

export const E = "E";
/* -------------------reducers------------------- */
export const RSetState = "RSetState";
/* -------------------effects------------------- */
export const EGet = "EGet";
export const EGetAll = "EGetAll";
export const EGetOne = "EGetOne";
export const EGetArea = "EGetArea";
export const EGetCommonArea = "EGetCommonArea";
export const EGetActive = "EGetActive";
export const EPost = "EPost";
export const EPostUser = "EPostUser";
export const EPostPassWordEdit = "EPostPassWordEdit";
export const EPostPassWordReset = "EPostPassWordReset";
export const EPostCompletePassWordReset = "EPostCompletePassWordReset";
export const EPut = "EPut";
export const EDelete = "EDelete";
export const EGetCollect = "EGetCollect";
export const EGetTop = "EGetTop";
export const EGetDetail = "EGetDetail";
export const EGetType = "EGetType";
export const EGetChart = "EGetChart";
export const EGetSponsor = "EGetSponsor";
export const EGetAgencies = "EGetAgencies";
export const EGetCompany = "EGetCompany";
export const EGetActivetType = "EGetActivetType";
export const NInsightType = "NInsightType";
export const EGetTypeSelect = "EGetTypeSelect";
/*--------------- effects ---------------*/

export const EGetRevenuePageAnalysis = "EGetRevenuePageAnalysis";
export const EGetRevenueTitle = "EGetRevenueTitle";
export const EPostAll = "EPostAll";
export const EPutRevenue = "EPutRevenue";
export const env = "env";
export const MName = "MName";
