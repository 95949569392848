import defaultImportLogo from "../assets/images/icon_no-data.png";
const CustomEmpty = () => (
	<div style={{ textAlign: "center" }}>
		<img
			src={defaultImportLogo} // replace this with your image URL
			alt="No Data"
			style={{ paddingBottom: "10px", paddingTop: "10px" }}
		/>
		<p>No Data</p>
	</div>
);

export default CustomEmpty;
