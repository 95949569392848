import React, { useState, useEffect } from "react";
import moment from "moment";
import "./index.less";
import { Row, Col, Typography, Tag, Spin } from "antd";
import { effect, useStore, reducer } from "../../../utils/dva16";
import CustomEmpty from "@/utils/CustomEmpty";
import formatDate from "@/utils/dateFormater";
import "swiper/swiper-bundle.min.css";
import {
	RSetState,
	NTournament,
	EGetDetail,
	MName,
	EGet,
} from "../../../models/contants";
import { Helmet } from "react-helmet";
const { Link } = Typography;

export default (props) => {
	const { id } = props.match.params;
	const { history } = props;
	const { tournamentDetailData, loading } = useStore(NTournament);

	useEffect(() => {
		effect(NTournament, EGetDetail, { id });
		return () => {
			reducer(NTournament, RSetState, {
				tournamentDetailData: null,
			});
		};
	}, [id]);

	// Scroll to the top of the page during component rendering
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		effect(MName, EGet, {
			modelName: tournamentDetailData?.tournament,
		});
	}, [tournamentDetailData?.tournament]);
	// ---------------------渲染-------------------------
	const date = new Date(
		tournamentDetailData && tournamentDetailData?.startDate
	);
	const formattedDate = formatDate(date);
	return (
		<div className="CompanyDetail_Box  wd100" style={{ padding: "20px 0" }}>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<Spin spinning={loading} size="large">
				<div className="CompanyBox mt50">
					<Row className="RowBox mt30">
						<Col span={24}>
							<div className="Detail_Title_Box">
								<div className="companyDttitle">
									<h1>{tournamentDetailData?.tournament}</h1>
									<div className="Tag_List_Box">
										{tournamentDetailData?.withGames?.map((vi, iii) => (
											<Tag
												key={iii}
												color="#19AE54"
												onClick={() => {
													history.push(
														`/games_organizations/games/details/${vi?.id}`
													);
												}}
												style={{
													cursor: "pointer",
													borderRadius: "20px",
													fontSize: "1rem",
												}}
											>
												{vi?.gameEn}
											</Tag>
										))}
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>
				<div className="BlogBox mt20">
					<Row gutter={16}>
						<Col span={8}>
							<div className="RowBox BorderBox Atom_layout">
								<div className="Atom_Box">
									<p>Host Area:</p>
									<p className="Fsb">
										{tournamentDetailData?.area?.length > 0
											? tournamentDetailData?.area?.join(", ")
											: "-"}
									</p>
								</div>
								<div className="Atom_Box">
									<p>Region:</p>
									<p className="Fsb">
										{tournamentDetailData?.region?.join(", ") || "-"}
									</p>
								</div>
								<div className="Atom_Box">
									<p>Tournament Type:</p>
									<p className="Fsb">
										{tournamentDetailData?.tournament_type || "-"}
									</p>
								</div>
								<div className="Atom_Box">
									<p>Scope:</p>
									<p className="Fsb">{tournamentDetailData?.scope || "-"}</p>
								</div>
								<div className="Atom_Box">
									<p>Venue:</p>
									<p className="Fsb">{tournamentDetailData?.venue || "-"}</p>
								</div>
							</div>
						</Col>
						<Col span={8}>
							<div className="RowBox BorderBox Atom_layout">
								<div className="Atom_Box">
									<p>Start Date:</p>
									<p className="Fsb">
										{tournamentDetailData?.startDate ? formattedDate : "-"}
									</p>
								</div>
								<div className="Atom_Box">
									<p>Prize Pool:</p>
									<p className="Fsb">
										{tournamentDetailData?.prizePool
											? "$" +
											  tournamentDetailData?.prizePool
													?.toString()
													?.replace(/(\d)(?=(\d{3})+\b)/g, "$1,")
											: "-"}
									</p>
								</div>
								<div className="Atom_Box">
									<p>Platforms:</p>
									<p className="Fsb">
										{tournamentDetailData?.platforms?.length > 0
											? tournamentDetailData?.platforms?.join(", ")
											: "-"}
									</p>
								</div>
								<div className="Atom_Box">
									<p>Characteristics:</p>
									<p className="Fsb">
										{tournamentDetailData?.special_characteristics || "-"}
									</p>
								</div>
								<div className="Atom_Box">
									<p>URL:</p>
									<p className="Fsb">
										<Link
											href={tournamentDetailData?.announcment_link || "#"}
											target="_blank"
											style={{ textDecoration: "underline" }}
										>
											{tournamentDetailData?.announcment_link || "-"}
										</Link>
									</p>
								</div>
							</div>
						</Col>
						<Col span={8}>
							<div className="RowBox BorderBox">
								<div
									className="Global_title"
									style={{ justifyContent: "center" }}
								>
									<h2>Hosts</h2>
								</div>
								<div className="Tag_List">
									{tournamentDetailData?.hosts &&
									tournamentDetailData?.hosts?.length > 0 ? (
										tournamentDetailData?.hosts?.map((vi, ii) => (
											<Tag
												key={ii}
												color="#9E219E"
												onClick={() => {
													history.push(
														`/games_organizations/organizations/details/${vi?.id}`
													);
												}}
												className="Tag_Item"
												style={{ cursor: "pointer", borderRadius: "20px" }}
											>
												{vi?.name}
											</Tag>
										))
									) : (
										<CustomEmpty />
									)}
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</Spin>
		</div>
	);
};
