import React, { useEffect } from "react";
import moment from "moment";
import { debounce } from "lodash";
import "./index.less";
import "../Profile/index.less";
import copy from "copy-to-clipboard";
import {
	Button,
	Row,
	Col,
	Space,
	Tag,
	Form,
	Input,
	Select,
	Pagination,
	message,
	Spin,
	Tooltip,
} from "antd";
import sortData from "../../../utils/sorter";
import { effect, useStore } from "../../../utils/dva16";
import {
	EGet,
	NCompany,
	NGame,
	NGenre,
	NPlatform,
	NInsightsFeed,
} from "../../../models/contants";
import { CopyOutlined } from "@ant-design/icons";
import E from "../../../models/E";

const { Option } = Select;

export default (props) => {
	const { history } = props;
	const [form] = Form.useForm();
	const { InsightsFeedList, loading } = useStore(NInsightsFeed);
	const { companyData } = useStore(NCompany);
	const { GameList } = useStore(NGame);
	const { GenreList } = useStore(NGenre);
	const { PlatformData } = useStore(NPlatform);
	useEffect(() => {
		effect(NInsightsFeed, EGet, { type: "Esports", page: 1, perPage: 10 });
		effect(NCompany, EGet, { page: 1, perPage: 10 });
		effect(NGame, EGet, { page: 1, perPage: 10 });
		effect(NGenre, EGet, { page: 1, perPage: 30 });
		effect(NPlatform, EGet, { page: 1, perPage: 25 });
		return () => {};
	}, []);
	const onPageChange = (page, pageSize) => {
		effect(NInsightsFeed, EGet, { type: "Esports", page, perPage: pageSize });
	};
	const onSearchCompany = (value) => {
		effect(NCompany, EGet, { ...{ company: value }, page: 1, perPage: 10 });
	};
	const onSearchGame = (value) => {
		effect(NGame, EGet, { ...{ keyword: value }, page: 1, perPage: 10 });
	};
	const onSearchGenre = (value) => {
		effect(NGenre, EGet, { ...{ genre: value }, page: 1, perPage: 10 });
	};
	const onSearchPlatform = (value) => {
		effect(NPlatform, EGet, { ...{ platform: value }, page: 1, perPage: 10 });
	};

	const onFinish = (values) => {
		if (values?.date) {
			values.startDate = moment(values?.date[0])?.format("YYYY-MM-DD");
			values.endDate = moment(values?.date[1])?.format("YYYY-MM-DD");
		}
		delete values?.date;
		effect(NInsightsFeed, EGet, {
			...values,
			type: "Esports",
			page: 1,
			perPage: 10,
		});
	};

	const onReset = () => {
		form.resetFields();
		effect(NInsightsFeed, EGet, { type: "Esports", page: 1, perPage: 10 });
	};
	return (
		<div>
			<div
				className="BlogBox mt50"
				style={{ backgroundColor: "#F7F9FB", padding: "40px 40px 20px 0px" }}
			>
				<Form
					name="advanced_search"
					className="ant-advanced-search-form"
					labelCol={{ span: 8 }}
					form={form}
					onFinish={onFinish}
				>
					<Row>
						<Col span={8}>
							<Form.Item
								name="region"
								label="Region"
								initialValue={"All"}
								rules={[
									{
										required: false,
										message: "Input something!",
									},
								]}
							>
								<Select>
									<Option value="All">All</Option>
									{E?.mainEnum?.map((vo, index) => (
										<Option key={index} value={vo?.text}>
											{vo?.text}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name="keyWord"
								label="Key words"
								rules={[
									{
										required: false,
										message: "Input something!",
									},
								]}
							>
								<Input placeholder="Input something" />
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name="company" label="Company">
								<Select
									allowClear
									getPopupContainer={(triggerNode) => triggerNode?.parentNode}
									showSearch
									filterOption={false}
									placeholder="Please Select"
									onSearch={debounce((value) => {
										onSearchCompany(value);
									}, 300)}
								>
									{companyData?.data?.map((item, index) => {
										return (
											<Option key={index} value={item?.id}>
												{item?.name}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="game" label="Game">
								<Select
									allowClear
									getPopupContainer={(triggerNode) => triggerNode?.parentNode}
									showSearch
									filterOption={false}
									placeholder="Please Select"
									onSearch={debounce((value) => {
										onSearchGame(value);
									}, 300)}
								>
									{GameList?.data?.map((item, index) => {
										return (
											<Option key={index} value={item?.id}>
												{item?.nameEn}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="genres" label="Genre">
								<Select
									allowClear
									getPopupContainer={(triggerNode) => triggerNode?.parentNode}
									showSearch
									filterOption={false}
									placeholder="Please Select"
									onSearch={debounce((value) => {
										onSearchGenre(value);
									}, 300)}
								>
									{sortData(GenreList?.data, "genre")?.map((item, index) => {
										return (
											<Option key={index} value={item?.genre}>
												{item?.genre}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name="platform"
								label="Platform"
								//
								rules={[
									{
										required: false,
										message: "Input something!",
									},
								]}
							>
								<Select
									allowClear
									getPopupContainer={(triggerNode) => triggerNode?.parentNode}
									showSearch
									filterOption={false}
									placeholder="Please Select"
									onSearch={debounce((value) => {
										onSearchPlatform(value);
									}, 300)}
								>
									{sortData(PlatformData?.data, "platform")?.map(
										(item, index) => {
											return (
												<Option key={index} value={item?.platform}>
													{item?.platform}
												</Option>
											);
										}
									)}
								</Select>
							</Form.Item>
						</Col>
						<Col span={16} style={{ textAlign: "right" }}>
							<Space>
								<Button type="primary" htmlType="submit" className="primarySearch">
									Search
								</Button>
								<Button type="default" htmlType="button" onClick={onReset} 	className="secReset">
									Reset
								</Button>
							</Space>
						</Col>
					</Row>
				</Form>
			</div>

			<Spin spinning={loading} size="large">
				<div className="BlogBox">
					<Row className="RowBox mt30">
						<Col span={24}>
							<ul className="ProlistBox">
								{InsightsFeedList?.data?.map((vo, i) => (
									<li key={i} style={{ padding: "20px 0" }}>
										<div
											className="insight_list_cont"
											style={{ width: "100%" }}
										>
											<h3>
												<div>{vo?.title}</div>

												<div className="insight_list_date_style">
													<Tooltip placement="top" title={"COPY"}>
														<CopyOutlined
															onClick={(e) => {
																copy(
																	vo?.title + "\n" + vo?.info + "\n" + vo?.link
																);
																message.success("Copied Success");
															}}
														/>
													</Tooltip>
													{moment(vo?.date).format("MMM Do YYYY")}
												</div>
											</h3>
											<div className="Tag_List_Box">
												{vo?.withCompanys?.map((vi, ii) => (
													<Tag
														key={ii}
														color="#9E219E"
														onClick={() => {
															history.push(
																`/games_organizations/organizations/details/${vi?.id}`
															);
														}}
														style={{ cursor: "pointer", borderRadius: "20px" }}
													>
														{vi?.name}
													</Tag>
												))}
												{vo?.withGames?.map((vi, iii) => (
													<Tag
														key={iii}
														color="#19AE54"
														onClick={() => {
															history.push(
																`/games_organizations/games/details/${vi?.id}`
															);
														}}
														style={{ cursor: "pointer", borderRadius: "20px" }}
													>
														{vi?.nameEn}
													</Tag>
												))}
											</div>

											<div className="insght_lst_info">{vo?.info}</div>
											<a href={vo?.link} target="_blank">
												{vo?.link}
											</a>
										</div>
									</li>
								))}
							</ul>
						</Col>
						<Col span={24} style={{ textAlign: "right" }}>
							{InsightsFeedList && (
								<Pagination
									current={InsightsFeedList?.page}
									total={InsightsFeedList?.total}
									pageSize={InsightsFeedList?.perPage}
									onChange={onPageChange}
									showSizeChanger
									showQuickJumper
								/>
							)}
						</Col>
					</Row>
				</div>
			</Spin>
		</div>
	);
};
