import { requestGet } from "../utils/dva16";
import {
	NICafeGame,
	EGet,
	EGetCollect,
	EGetOne,
	EGetDetail,
	RSetState,
} from "./contants";
import { orderBy } from "lodash";

const ICafeGame = {
	namespace: NICafeGame,
	state: {
		icafeRankchange: null,
		ICafeGameList: null,
		loading: false,
		ICafeGameTitle: null,
		ICafeGameDetail: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("icafe", payload);
			reducer(RSetState, { ICafeGameList: res, loading: false });
		},
		async [EGetCollect](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			let res = await requestGet("icafeTitle", payload);
			reducer(RSetState, { ICafeGameTitle: res });
		},
		async [EGetDetail](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			reducer(RSetState, { loading: true });
			let res = await requestGet(`icafeDetail`, payload);
			reducer(RSetState, { ICafeGameDetail: res, loading: false });
		},
		async [EGetOne]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet(`icafeRankchange`, payload);
			reducer(RSetState, {
				icafeRankchange: res && orderBy(res, ["rank"], ["asc"]),
			});
		},
	},
};
export default ICafeGame;
