import { requestGet } from "@/utils/dva16";
import { EGet, NSpecialCharacteristics, RSetState } from "./contants";

export default {
	namespace: NSpecialCharacteristics,
	state: {
		specialCharacteristicsList: null,
		load: false,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { load: true });
			let res = await requestGet("tournament-special-characterisctic", payload);
			reducer(RSetState, { specialCharacteristicsList: res, load: false });
		},
	},
};
