import {
	NLogin,
	RSetState,
	EPost,
	EGet,
	EPostUser,
	EPostPassWordEdit,
	EPostPassWordReset,
	EPostCompletePassWordReset,
	EGetOne,
} from "./contants";
import { requestPost, bindJWTToken, requestGet } from "../utils/dva16";
import { setToken } from "../utils/utils";
import { message } from "antd";

const Login = {
	namespace: NLogin,
	state: {
		userInfo: null,
		collapsed: false,
		authToken: null,
		currentSubscribe: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EPost]({ payload, callback }, { select }) {
			let { token, email, clientUser } = await requestPost("login", payload);
			if (clientUser && token && email) {
				setToken(token);
				bindJWTToken(token);
			}
			callback && callback();
		},
		async [EPostUser](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			await requestPost(`addAccount`, payload);
			callback &&
				callback(message.success("Registration succeeded, thank you"));
		},
		async [EPostPassWordEdit](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			await requestPost(`updatePwd`, payload);
			callback && callback(message.success("Password modified successfully"));
		},
		async [EPostPassWordReset](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			await requestPost(`resetPassword`, payload);
			callback &&
				callback(message.success("The mail has been sent. Please wait"));
		},
		async [EPostCompletePassWordReset](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			await requestPost(`completeResetPassword`, payload);
			callback && callback(message.success("Password updated successfully!"));
		},
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("getUserCenter", payload);
			let currentSub = res?.subscriptionInfo?.filter(
				(module) => module?.status == 1
			);
			reducer(RSetState, { userInfo: res, currentSubscribe: currentSub });
		},
	},
};
export default Login;
