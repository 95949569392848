import React, { useState, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import "../Profile/index.less";
import "./index.less";
import {
	Button,
	Row,
	Col,
	Space,
	Table,
	Form,
	Input,
	Radio,
	Select,
	Spin,
	Tag,
	DatePicker,
} from "antd";
import sortData from "../../../utils/sorter";
import { effect, useStore } from "../../../utils/dva16";
import { customizeRenderEmpty } from "../../../utils/utils";
import formatDate from "@/utils/dateFormater";
import {
	EGet,
	EGetOne,
	NISBN,
	NGame,
	NGenre,
	NPlatform,
	EGetType,
	NOrganizationMulti,
	EGetCompany,
	NRegion,
	NLogin,
	EGetRegion,
	EGetArea,
} from "../../../models/contants";
import { Line } from "@ant-design/charts";
import E from "../../../models/E";
import { Helmet } from "react-helmet";
import moment from "moment";
const { Option } = Select;
const { RangePicker } = DatePicker;
export default (props) => {
	const { history } = props;
	const { PlatformData } = useStore(NPlatform);
	const [searchValues, setSearchValues] = useState();
	const { OrganizationCompany } = useStore(NOrganizationMulti);
	const { gameSearch } = useStore(NGame);
	const { currentSubscribe, userInfo } = useStore(NLogin);
	const { areaList, regionList, commonAreaList } = useStore(NRegion);
	const [form] = Form.useForm();
	const [sortState, setSortState] = useState(null);
	let sortType = {
		ascend: "asc",
		descend: "desc",
	};
	const { ISBNData, ISBNTitleData, loading } = useStore(NISBN);
	const ISBNLine = () => {
		const data = [];
		const [ISBNLineData, setISBNLineData] = useState({
			data,
			padding: "auto",
			xField: "datetime",
			yField: "monthCount",
			height: 290,
			appendPadding: 20,
			yAxis: {
				label: {
					// 数值格式化为千分位
					formatter: (v) =>
						`${v}`?.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
				},
			},
			seriesField: "lisenceArea",
		});
		useEffect(() => {
			if (ISBNTitleData?.totalALL) {
				ISBNLineData.data = ISBNTitleData?.totalALL || data;
				setISBNLineData(Object.assign({}, ISBNLineData));
			}
		}, [ISBNTitleData]);

		return <Line {...ISBNLineData} />;
	};
	// function findRegionType(arr) {
	// 	const updatedRegionArr = [];
	// 	areaList?.data?.forEach((item) => {
	// 		if (arr?.includes(item?.otherData)) {
	// 			updatedRegionArr?.push(item?.lookupValue);
	// 		}
	// 	});

	// 	form.setFieldsValue({
	// 		importRegion: Array.from(new Set(updatedRegionArr)),
	// 	});
	// }
	function findRegionType(arr) {
		const updatedRegionArr = [];
		areaList?.data?.forEach((item) => {
			if (arr?.includes(item?.otherData)) {
				updatedRegionArr?.push(item?.lookupValue);
			}
		});

		form.setFieldsValue({
			importRegion: Array.from(new Set(updatedRegionArr)),
		});
	}
	const columns = [
		{
			title: "Game",
			dataIndex: "gameId",
			key: "gameId",
			sortOrder: sortState?.field == "gameId" && sortState?.order,
			sorter: (a, b) => {
				a?.game?.gameEn &&
					b?.game?.gameEn &&
					a?.game?.gameEn?.localeCompare(b?.game?.gameEn);
			},
			render: (text, record, index) => (
				<>
					<div className="Tabel_Company">
						<div className="Tabel_Company_Title">
							<p>
								<a
									// style={{ cursor: "default" }}
									className="a_style"
									href={`#/games_organizations/games/details/${record?.gameId}`}
								>
									{record?.game?.gameEn}
								</a>
							</p>
							<p>
								<a
									// style={{ cursor: "default" }}
									className="a_style"
									href={`#/games_organizations/games/details/${record?.gameId}`}
								>
									{record?.game?.gameCn}
								</a>
							</p>
						</div>
					</div>
				</>
			),
		},
		{
			title: "ISBN",
			dataIndex: "ISBN",
			key: "ISBN",
			render: (text) => <span>{text ?? "-"}</span>,
			sortOrder: sortState?.field == "ISBN" && sortState?.order,
			sorter: (a, b) => a?.ISBN?.localeCompare(b?.ISBN),
		},
		{
			title: "License Type",
			dataIndex: "lisenceArea",
			key: "lisenceArea",
			sortOrder: sortState?.field == "lisenceArea" && sortState?.order,
			sorter: (a, b) => a?.lisenceArea?.localeCompare(b?.lisenceArea),
			render: (text, record) => {
				return (
					<>
						<div>
							<span>{record?.lisenceArea}</span>
						</div>
					</>
				);
			},
		},
		{
			title: "Licensed Status",
			dataIndex: "licensedStatus",
			key: "licensedStatus",
			sortOrder: sortState?.field == "licensedStatus" && sortState?.order,
			sorter: (a, b) => a?.licensedStatus?.localeCompare(b?.licensedStatus),
			render: (text, record) => {
				return (
					<>
						<div>
							<span>
								{record?.licensedStatus == 1
									? "Active"
									: record?.licensedStatus == 0
									? "Inactive"
									: ""}
							</span>
						</div>
					</>
				);
			},
		},

		{
			title: "Approval Date",
			dataIndex: "date",
			key: "date",
			sortOrder: sortState?.field == "date" && sortState?.order,
			sorter: (a, b) => {
				const dateA = new Date(a?.date);
				const dateB = new Date(b?.date);
				return dateA - dateB;
			},
			render: (text, record, index) => {
				const date = new Date(record?.date);
				const formattedDate = formatDate(date);
				return <div>{record?.date ? formattedDate : ""} </div>;
			},
		},
		{
			title: "Licensed Platform",
			dataIndex: "licensedPlatform",
			key: "licensedPlatform",
			sortOrder: sortState?.field == "licensedPlatform" && sortState?.order,
			sorter: (a, b) => a?.licensedPlatform?.localeCompare(b?.licensedPlatform),
			render: (text, record) => (
				<>
					{record?.licensedPlatform &&
						record?.licensedPlatform?.map((value, index) => {
							return <div key={index}>{value + "\n"}</div>;
						})}
				</>
			),
		},

		{
			title: "Publishing Unit",
			dataIndex: "publishingUnit",
			key: "publishingUnit",
			sortOrder: sortState?.field == "publishingUnit" && sortState?.order,
			sorter: (a, b) => a?.publishingUnit?.localeCompare(b?.publishingUnit),
			render: (text, record) => (
				<>
					{record?.withLicensee?.length > 0 ? (
						record?.withLicensee?.map((value, index) => {
							return (
								<Tag
									color="#9E219E"
									style={{
										marginBottom: 5,
										whiteSpace: "initial",
										borderRadius: "20px",
									}}
									key={index}
									onClick={() => {
										history.push("/proFile/companyList");
									}}
								>
									{value?.name}
								</Tag>
							);
						})
					) : (
						<Tag
							color="#9E219E"
							style={{
								marginBottom: 5,
								whiteSpace: "initial",
								borderRadius: "20px",
							}}
							onClick={() => {
								history.push("/proFile/companyList");
							}}
						>
							{record?.publishingUnit}
						</Tag>
					)}
				</>
			),
		},
		{
			title: "Operating Unit",
			dataIndex: "operatingUnit",
			key: "operatingUnit",
			sortOrder: sortState?.field == "operatingUnit" && sortState?.order,
			sorter: (a, b) => {
				a?.withOperator?.[0]?.name &&
					b?.withOperator?.[0]?.name &&
					a?.withOperator?.[0]?.name?.localeCompare(b?.withOperator?.[0]?.name);
			},
			render: (text, record) => (
				<>
					{record?.withOperator?.map((value, index) => {
						return (
							<Tag
								color="#9E219E"
								style={{
									marginBottom: 5,
									whiteSpace: "initial",
									borderRadius: "20px",
								}}
								key={index}
								onClick={() => {
									history.push("/proFile/companyList");
								}}
							>
								{value?.name}
							</Tag>
						);
					})}
				</>
			),
		},
		{
			title: "Import Area",
			dataIndex: "importArea",
			key: "importArea",
			render: (text, record) => (
				<>
					{record?.importArea &&
						record?.lisenceArea == "Import" &&
						record?.importArea?.map((value, index) => {
							return <div key={index}>{value + "\n"}</div>;
						})}
				</>
			),
			sortOrder: sortState?.field == "importArea" && sortState?.order,
			sorter: (a, b) =>
				a?.importArea &&
				b?.importArea &&
				a?.importArea?.[0]?.localeCompare(b?.importArea?.[0]),
		},
		{
			title: "Import Region",
			dataIndex: "importRegion",
			key: "importRegion",
			render: (text, record) => (
				<>
					{record?.importRegion &&
						record?.lisenceArea == "Import" &&
						record?.importRegion?.map((value, index) => {
							return <div key={index}>{value + "\n"}</div>;
						})}
				</>
			),
			sortOrder: sortState?.field == "importRegion" && sortState?.order,
			sorter: (a, b) =>
				a?.importRegion &&
				b?.importRegion &&
				a?.importRegion?.[0]?.localeCompare(b?.importRegion?.[0]),
		},
	];

	useEffect(() => {
		effect(NISBN, EGetOne, {});
		effect(NOrganizationMulti, EGetCompany, {
			page: 1,
			perPage: 10,
		});
		effect(NRegion, EGetRegion, { page: 1, perPage: 10 });
		effect(NPlatform, EGet, { page: 1, perPage: 25 });
		effect(NGame, EGetType, { page: 1, perPage: 10 });
		effect(NRegion, EGetArea, { page: 1, perPage: 200 });
		effect(NLogin, EGet, {});

		return () => {};
	}, []);

	useEffect(() => {
		let page = sessionStorage.getItem("isbnPage");
		let pageSize = sessionStorage.getItem("isbnPageSize");
		if (page && pageSize) {
			effect(NISBN, EGet, { page, pageSize });
		} else {
			effect(NISBN, EGet, { page: 1, perPage: 10 });
		}
	}, []);

	const onFinish = (values) => {
		values.startDate = values?.date
			? moment(values?.date[0])?.format("YYYY-MM-DD")
			: null;
		values.endDate = values?.date
			? moment(values?.date[1])?.format("YYYY-MM-DD")
			: null;
		values.importArea = values.importArea && [
			...values?.importArea?.map((areaValue) => {
				return commonAreaList?.data?.find(
					(item) => item?.otherData === areaValue
				)?.id;
			}),
			...values.importArea,
		];
		delete values?.date;
		setSearchValues(values);
		effect(NISBN, EGet, {
			...values,
			page: 1,
			perPage: 10,
			field: sortState?.field,
			isDesc: sortState?.order && sortType[sortState?.order],
		});
	};

	const onSearchPlatform = (value) => {
		effect(NPlatform, EGet, { ...{ platform: value }, page: 1, perPage: 10 });
	};
	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetCompany, {
			name: value,
			page: 1,
			perPage: 10,
		});
	};
	const onSearchGame = (value) => {
		effect(NGame, EGetType, { ...{ name: value }, page: 1, perPage: 10 });
	};
	const onReset = () => {
		sessionStorage.setItem("isbnPage", 1);
		sessionStorage.setItem("isbnPageSize", 10);
		form.resetFields();
		setSortState(null);
	};
	const pagination = {
		current: parseInt(ISBNData && ISBNData?.page) || 1,
		total: (ISBNData && ISBNData?.total) || 0,
		defaultCurrent: 1,
		defaultPageSize: parseInt(ISBNData && ISBNData?.perPage) || 10,
		pageSize: ISBNData && ISBNData?.perPage,
		pageSizeOptions: ["10", "20", "30", "50", "100"],
		showQuickJumper: true,
		showSizeChanger: true,
		position: "bottom",
	};

	return (
		<div>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>

			{userInfo?.type == "Client" ? (
				currentSubscribe?.[0]?.module.hasOwnProperty("ISBN") &&
				currentSubscribe?.[0]?.module["ISBN"]?.length > 0 ? (
					<Spin spinning={loading} size="large">
						{/* <div className="insightsFeedForEsportsBox ISBNBox mt50">
							<Row gutter={24}>
								<Col span={6}>
									<div className="CompanyChartBox BorderBox">
										<h1
											className="Global_Jb_title Public_Title pd20"
											style={{ color: "#999999" }}
										>
											Games Approved
										</h1>
										<div className="Chart_Run_Title ">
											<div className="Statistic_Box">
												<div
													className="Statistic_Card"
													style={{ padding: "37px 0px" }}
												>
													<img
														src={
															require("@/assets/images/home/ico_tongji.png")
																.default
														}
													/>
													<div style={{ paddingLeft: "40px" }}>
														<span
															style={{
																fontSize: "24px",
																color: "#999999",
																fontWeight: 400,
															}}
														>
															Domestic
														</span>
														<span
															style={{
																fontSize: "28px",
																color: "#28395E",
																fontWeight: 600,
															}}
														>
															{ISBNTitleData?.Region?.[0]?.count
																? `${ISBNTitleData?.Region?.[0]?.count
																		?.toString()
																		?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
																: "-"}
														</span>
													</div>
												</div>

												<div
													className="Statistic_Card"
													style={{ padding: "36px 30px 36px 0px" }}
												>
													<img
														src={
															require("@/assets/images/home/ico_diqiu.png")
																?.default
														}
													/>
													<div style={{ paddingLeft: "40px" }}>
														<span
															style={{
																fontSize: "24px",
																color: "#999999",
																fontWeight: 400,
															}}
														>
															Import
														</span>
														<span
															style={{
																fontSize: "28px",
																color: "#28395E",
																fontWeight: 600,
															}}
														>
															{ISBNTitleData?.Region?.[1]?.count
																? `${ISBNTitleData?.Region?.[1]?.count
																		?.toString()
																		?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
																: "-"}
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Col>
								<Col span={18}>
									<div className="CompanyChartBox BorderBox">
										<h1
											className="Global_Jb_title Public_Title pd20"
											style={{ color: "#999999" }}
										>
											Games Approved
										</h1>
										<div className="Chart_Run_Box ">
											<ISBNLine />
										</div>
									</div>
								</Col>
							</Row>
						</div> */}
						<div
							className="BlogBox ISBNBox mt50"
							style={{
								backgroundColor: "#F7F9FB",
								padding: "40px 40px 20px 0px",
							}}
						>
							<Form
								name="advanced_search"
								className="ant-advanced-search-form"
								labelCol={{ span: 8 }}
								form={form}
								onFinish={onFinish}
							>
								<Row gutter={24}>
									<Col span={8}>
										<Form.Item name="game" label="Game">
											<Select
												allowClear
												getPopupContainer={(triggerNode) =>
													triggerNode.parentNode
												}
												showSearch
												filterOption={false}
												placeholder="Please Select"
												onSearch={debounce((value) => {
													onSearchGame(value);
												}, 100)}
											>
												{sortData(gameSearch?.data, "gameEn")?.map(
													(item, index) => {
														return (
															<Option key={index} value={item?.gameEn}>
																{item?.gameEn}
															</Option>
														);
													}
												)}
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label="ISBN" name="ISBN">
											<Input placeholder="Please Enter" />
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label="License Type" name="lisenceArea">
											<Select
												getPopupContainer={(triggerNode) =>
													triggerNode?.parentNode
												}
												showArrow={true}
												placeholder="Please Select"
											>
												<Option value="Import">Import</Option>
												<Option value="Domestic">Domestic</Option>
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label="Licensed Status" name="licensedStatus">
											<Select
												placeholder="Please Select "
												getPopupContainer={(triggerNode) =>
													triggerNode.parentNode
												}
											>
												<Option value={"1"}>Active</Option>
												<Option value={"0"}>Inactive</Option>
											</Select>
										</Form.Item>
									</Col>

									<Col span={8}>
										<Form.Item label="Approval Date" name="date">
											<RangePicker
												format="MMM DD, YYYY"
												getPopupContainer={(triggerNode) =>
													triggerNode.parentNode
												}
												style={{ width: "100%" }}
											/>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item
											name="licensedPlatform"
											label="Licensed Platform"
											rules={[
												{
													required: false,
													message: "Input something!",
												},
											]}
										>
											<Select
												allowClear
												showSearch
												getPopupContainer={(triggerNode) =>
													triggerNode.parentNode
												}
												filterOption={(input, option) =>
													option?.children
														?.toLowerCase()
														.indexOf(input?.toLowerCase()) >= 0
												}
												placeholder="Please Select"
												onSearch={debounce((value) => {
													onSearchPlatform(value);
												}, 300)}
											>
												{sortData(PlatformData?.data, "platform")?.map(
													(record, index) => {
														return (
															<Option key={record?.id} value={record?.id}>
																{record?.lookupValue}
															</Option>
														);
													}
												)}
											</Select>
										</Form.Item>
									</Col>

									<Col span={8}>
										<Form.Item name="publishingUnit" label="Publishing Unit">
											<Select
												allowClear
												getPopupContainer={(triggerNode) =>
													triggerNode.parentNode
												}
												showSearch
												filterOption={false}
												placeholder="Please Select"
												onSearch={debounce((value) => {
													onSearchCompany(value);
												}, 300)}
											>
												{sortData(OrganizationCompany?.data)?.map(
													(item, index) => {
														return (
															<Option key={index} value={item?.id}>
																{item?.name}
															</Option>
														);
													}
												)}
											</Select>
										</Form.Item>
									</Col>

									<Col span={8}>
										<Form.Item name="operatingUnit" label="Operating Unit">
											<Select
												allowClear
												getPopupContainer={(triggerNode) =>
													triggerNode.parentNode
												}
												showSearch
												filterOption={false}
												placeholder="Please Select"
												onSearch={debounce((value) => {
													onSearchCompany(value);
												}, 300)}
											>
												{sortData(OrganizationCompany?.data)?.map(
													(item, index) => {
														return (
															<Option key={item?.id} value={item?.id}>
																{item?.name}
															</Option>
														);
													}
												)}
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label="Import Area" name="importArea">
											<Select
												mode="multiple"
												getPopupContainer={(triggerNode) =>
													triggerNode.parentNode
												}
												id="select"
												placeholder="Please Search"
												allowClear
												showSearch
												onChange={(e) => {
													findRegionType(e);
												}}
												// onSearch={(value) => {
												// 	effect(NRegionArea, EGetArea, {
												// 		page: 1,
												// 		perPage: 200,
												// 		area: value,
												// 	});
												// }}
												filterOption={(input, option) =>
													option?.children
														?.toLowerCase()
														.indexOf(input?.toLowerCase()) >= 0
												}
											>
												{sortData(areaList?.data, "area")?.map(
													(record, index) => {
														return (
															<Option
																key={record?.id}
																value={record?.otherData}
															>
																{record?.otherData}
															</Option>
														);
													}
												)}
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item name="importRegion" label="Import Region">
											<Select
												mode="multiple"
												getPopupContainer={(triggerNode) =>
													triggerNode.parentNode
												}
												placeholder="Please Select"
											>
												{regionList?.map((vo, index) => (
													<Option key={index} value={vo?.lookupValue}>
														{vo?.lookupValue}
													</Option>
												))}
											</Select>
										</Form.Item>
									</Col>

									<Col span={16} style={{ textAlign: "right" }}>
										<Space>
											<Button
												type="primary"
												htmlType="submit"
												className="primarySearch"
											>
												Search
											</Button>
											<Button
												type="default"
												htmlType="submit"
												onClick={onReset}
												className="secReset"
											>
												Reset
											</Button>
										</Space>
									</Col>
								</Row>
							</Form>
						</div>
						<div className="CompanyBox">
							<Row className="RowBox mt30">
								<Col span={24}>
									<Table
										scroll={{ x: "max-content" }}
										dataSource={ISBNData?.data || []}
										columns={columns}
										className="CompanyTableBox"
										rowKey="id"
										pagination={pagination}
										onChange={(pagination, filter, sort) => {
											setSortState(sort);
											effect(NISBN, EGet, {
												page: pagination?.current,
												perPage: pagination?.pageSize,
												field: sort?.column && sort?.field,
												isDesc:
													(sort?.column &&
														sort?.order &&
														sortType[sort?.order]) ||
													null,
												...searchValues,
											});

											sessionStorage.setItem("isbnPage", pagination?.current);
											sessionStorage.setItem(
												"isbnPageSize",
												pagination?.pageSize
											);
										}}
									/>
								</Col>
							</Row>
						</div>
					</Spin>
				) : (
					customizeRenderEmpty()
				)
			) : (
				<Spin spinning={loading} size="large">
					{/* <div className="insightsFeedForEsportsBox ISBNBox mt50">
						<Row gutter={24}>
							<Col span={6}>
								<div className="CompanyChartBox BorderBox">
									<h1
										className="Global_Jb_title Public_Title pd20"
										style={{ color: "#999999" }}
									>
										Games Approved
									</h1>
									<div className="Chart_Run_Title ">
										<div className="Statistic_Box">
											<div
												className="Statistic_Card"
												style={{ padding: "37px 0px" }}
											>
												<img
													src={
														require("@/assets/images/home/ico_tongji.png")
															.default
													}
												/>
												<div style={{ paddingLeft: "40px" }}>
													<span
														style={{
															fontSize: "24px",
															color: "#999999",
															fontWeight: 400,
														}}
													>
														Domestic
													</span>
													<span
														style={{
															fontSize: "28px",
															color: "#28395E",
															fontWeight: 600,
														}}
													>
														{ISBNTitleData?.Region?.[0]?.count
															? `${ISBNTitleData?.Region?.[0]?.count
																	?.toString()
																	?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
															: "-"}
													</span>
												</div>
											</div>

											<div
												className="Statistic_Card"
												style={{ padding: "36px 30px 36px 0px" }}
											>
												<img
													src={
														require("@/assets/images/home/ico_diqiu.png")
															?.default
													}
												/>
												<div style={{ paddingLeft: "40px" }}>
													<span
														style={{
															fontSize: "24px",
															color: "#999999",
															fontWeight: 400,
														}}
													>
														Import
													</span>
													<span
														style={{
															fontSize: "28px",
															color: "#28395E",
															fontWeight: 600,
														}}
													>
														{ISBNTitleData?.Region?.[1]?.count
															? `${ISBNTitleData?.Region?.[1]?.count
																	?.toString()
																	?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
															: "-"}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Col>
							<Col span={18}>
								<div className="CompanyChartBox BorderBox">
									<h1
										className="Global_Jb_title Public_Title pd20"
										style={{ color: "#999999" }}
									>
										Games Approved
									</h1>
									<div className="Chart_Run_Box ">
										<ISBNLine />
									</div>
								</div>
							</Col>
						</Row>
					</div> */}
					<div
						className="BlogBox ISBNBox mt50"
						style={{
							backgroundColor: "#F7F9FB",
							padding: "40px 40px 20px 0px",
						}}
					>
						<Form
							name="advanced_search"
							className="ant-advanced-search-form"
							labelCol={{ span: 8 }}
							form={form}
							onFinish={onFinish}
						>
							<Row gutter={24}>
								<Col span={8}>
									<Form.Item name="game" label="Game">
										<Select
											allowClear
											getPopupContainer={(triggerNode) =>
												triggerNode.parentNode
											}
											showSearch
											filterOption={false}
											placeholder="Please Select"
											onSearch={debounce((value) => {
												onSearchGame(value);
											}, 100)}
										>
											{sortData(gameSearch?.data, "gameEn")?.map(
												(item, index) => {
													return (
														<Option key={index} value={item?.gameEn}>
															{item?.gameEn}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="ISBN" name="ISBN">
										<Input placeholder="Please Enter" />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="License Type" name="lisenceArea">
										<Select
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											showArrow={true}
											placeholder="Please Select"
										>
											<Option value="Import">Import</Option>
											<Option value="Domestic">Domestic</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Licensed Status" name="licensedStatus">
										<Select
											placeholder="Please Select "
											getPopupContainer={(triggerNode) =>
												triggerNode.parentNode
											}
										>
											<Option value={"1"}>Active</Option>
											<Option value={"0"}>Inactive</Option>
										</Select>
									</Form.Item>
								</Col>

								<Col span={8}>
									<Form.Item label="Approval Date" name="date">
										<RangePicker
											format="MMM DD, YYYY"
											getPopupContainer={(triggerNode) =>
												triggerNode.parentNode
											}
											style={{ width: "100%" }}
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										name="licensedPlatform"
										label="Licensed Platform"
										rules={[
											{
												required: false,
												message: "Input something!",
											},
										]}
									>
										<Select
											allowClear
											showSearch
											getPopupContainer={(triggerNode) =>
												triggerNode.parentNode
											}
											filterOption={false}
											placeholder="Please Select"
											onSearch={debounce((value) => {
												onSearchPlatform(value);
											}, 300)}
										>
											{sortData(PlatformData?.data, "platform")?.map(
												(record, index) => {
													return (
														<Option key={record?.id} value={record?.id}>
															{record?.lookupValue}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>

								<Col span={8}>
									<Form.Item name="publishingUnit" label="Publishing Unit">
										<Select
											allowClear
											getPopupContainer={(triggerNode) =>
												triggerNode.parentNode
											}
											showSearch
											filterOption={false}
											placeholder="Please Select"
											onSearch={debounce((value) => {
												onSearchCompany(value);
											}, 300)}
										>
											{sortData(OrganizationCompany?.data)?.map(
												(item, index) => {
													return (
														<Option key={index} value={item?.id}>
															{item?.name}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>

								<Col span={8}>
									<Form.Item name="operatingUnit" label="Operating Unit">
										<Select
											allowClear
											getPopupContainer={(triggerNode) =>
												triggerNode.parentNode
											}
											showSearch
											filterOption={false}
											placeholder="Please Select"
											onSearch={debounce((value) => {
												onSearchCompany(value);
											}, 300)}
										>
											{sortData(OrganizationCompany?.data)?.map(
												(item, index) => {
													return (
														<Option key={item?.id} value={item?.id}>
															{item?.name}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Import Area" name="importArea">
										<Select
											mode="multiple"
											getPopupContainer={(triggerNode) =>
												triggerNode.parentNode
											}
											id="select"
											placeholder="Please Search"
											allowClear
											showSearch
											onChange={(e) => {
												findRegionType(e);
											}}
											// onSearch={(value) => {
											// 	effect(NRegionArea, EGetArea, {
											// 		page: 1,
											// 		perPage: 200,
											// 		area: value,
											// 	});
											// }}
											filterOption={(input, option) =>
												option?.children
													?.toLowerCase()
													.indexOf(input?.toLowerCase()) >= 0
											}
										>
											{sortData(areaList?.data, "area")?.map(
												(record, index) => {
													return (
														<Option key={record?.id} value={record?.otherData}>
															{record?.otherData}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="importRegion" label="Import Region">
										<Select
											mode="multiple"
											getPopupContainer={(triggerNode) =>
												triggerNode.parentNode
											}
											placeholder="Please Select"
										>
											{regionList?.map((vo, index) => (
												<Option key={index} value={vo?.lookupValue}>
													{vo?.lookupValue}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>

								<Col span={16} style={{ textAlign: "right" }}>
									<Space>
										<Button
											type="primary"
											htmlType="submit"
											className="primarySearch"
										>
											Search
										</Button>
										<Button
											type="default"
											htmlType="submit"
											onClick={onReset}
											className="secReset"
										>
											Reset
										</Button>
									</Space>
								</Col>
							</Row>
						</Form>
					</div>
					<div className="CompanyBox">
						<Row className="RowBox mt30">
							<Col span={24}>
								<Table
									scroll={{ x: "max-content" }}
									dataSource={ISBNData?.data || []}
									columns={columns}
									className="CompanyTableBox"
									rowKey="id"
									pagination={pagination}
									onChange={(pagination, filter, sort) => {
										setSortState(sort);
										effect(NISBN, EGet, {
											page: pagination?.current,
											perPage: pagination?.pageSize,
											field: sort?.column && sort?.field,
											isDesc:
												(sort?.column &&
													sort?.order &&
													sortType[sort?.order]) ||
												null,
											...searchValues,
										});

										sessionStorage.setItem("isbnPage", pagination?.current);
										sessionStorage.setItem(
											"isbnPageSize",
											pagination?.pageSize
										);
									}}
								/>
							</Col>
						</Row>
					</div>
				</Spin>
			)}
		</div>
	);
};
