import React, { useState, useEffect } from "react";
import moment from "moment";
import { debounce } from "lodash";
import "./index.less";
import CustomEmpty from "@/utils/CustomEmpty";
import {
	Button,
	Image,
	Row,
	Col,
	Space,
	Card,
	Table,
	Form,
	Input,
	DatePicker,
	Select,
	Spin,
	Radio,
	InputNumber,
} from "antd";
import sortData from "../../../utils/sorter";
import defaultLogo from "../../../assets/images/game/defaul-logo_game.png";
import { effect, useStore } from "@/utils/dva16";

import {
	EGet,
	EGetCollect,
	EGetCompany,
	NGame,
	NGenre,
	NOrganizationMulti,
	NPlatform,
	NInsightsFeed,
	NLogin,
	EGeTPage,
} from "../../../models/contants";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { customizeRenderEmpty } from "../../../utils/utils";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default (props) => {
	const { history } = props;
	const [form] = Form.useForm();
	const { OrganizationCompany } = useStore(NOrganizationMulti);
	const [searchValue, setSearchValue] = useState(null);
	const { GenreList } = useStore(NGenre);
	const { PlatformData } = useStore(NPlatform);
	const { GameList, gameTitle, loading, gamePage } = useStore(NGame);
	const { EsportsList, InsightsFeedList } = useStore(NInsightsFeed);
	const { currentSubscribe, userInfo } = useStore(NLogin);

	const [sortState, setSortState] = useState(null);

	let sortType = {
		ascend: "asc",
		descend: "desc",
	};
	const columns = [
		{
			title: "Game",
			dataIndex: "gameEn",
			key: "gameEn",
			sortOrder: sortState?.field == "gameEn" && sortState?.order,
			sorter: (a, b) => a?.gameEn?.localeCompare(b?.gameEn),

			render: (text, record, index) => (
				<>
					<div className="Tabel_Company">
						<img
							className="Tabel_Company_Logo"
							src={record?.logo ? record.logo : defaultLogo}
							alt={record?.name}
						/>
						<div className="Tabel_Company_Title">
							<p>
								<a
									className="a_style"
									href={`#/games_organizations/games/details/${record?.id}`}
								>
									{record?.gameEn}
								</a>
							</p>
							<p>
								<a
									className="a_style"
									href={`#/games_organizations/games/details/${record?.id}`}
								>
									{record?.gameCn}
								</a>
							</p>
						</div>
					</div>
				</>
			),
		},
		{
			title: "Status",
			key: "status",
			dataIndex: "status",
			align: "center",
			sortOrder: sortState?.field == "status" && sortState?.order,
			sorter: (a, b) => a?.status?.localeCompare(b?.status),
		},
		{
			title: "Genre",
			dataIndex: "genre",
			key: "genre",
			sortOrder: sortState?.field == "genre" && sortState?.order,
			sorter: (a, b) => a?.genre?.localeCompare(b?.genre),
			render: (text, value, index) => (
				<>
					{value?.genre?.length > 0 &&
						value?.genre?.map((item, index) => {
							return <div key={index}>{item + "\n"}</div>;
						})}
				</>
			),
		},

		{
			title: "Developer",
			dataIndex: "developer",
			key: "developer",
			sortOrder: sortState?.field == "developer" && sortState?.order,
			sorter: (a, b) => {
				a?.withDeveloper?.[0]?.name &&
					b?.withDeveloper?.[0]?.name &&
					a?.withDeveloper?.[0]?.name?.localeCompare(
						b?.withDeveloper?.[0]?.name
					);
			},
			render: (text, value, index) => (
				<div style={{ wordBreak: "break-all" }}>
					{value?.withDeveloper?.length > 0 &&
					Array.isArray(value?.withDeveloper)
						? value?.withDeveloper?.map((item, key) => {
								return (
									<a
										className="a_style"
										key={key}
										onClick={() => {
											toDetails(item?.id);
										}}
									>
										{item?.name}

										{key + 1 != value?.withDeveloper?.length ? "," : ""}
									</a>
								);
						  })
						: value?.withDeveloper}
					{value?.withDeveloper?.length != 0 ? "" : "-"}
				</div>
			),
		},
		{
			title: "Publisher",
			dataIndex: "publisher",
			key: "publisher",
			sortOrder: sortState?.field == "publisher" && sortState?.order,
			sorter: (a, b) => {
				a?.withPublisher?.[0]?.name &&
					b?.withPublisher?.[0]?.name &&
					a?.withPublisher?.[0]?.name?.localeCompare(
						b?.withPublisher?.[0]?.name
					);
			},
			render: (text, value, index) => (
				<div style={{ wordBreak: "break-all" }}>
					{value?.withPublisher?.length > 0 &&
					Array.isArray(value?.withPublisher)
						? value?.withPublisher?.map((item, key) => {
								return (
									<a
										className="a_style"
										key={key}
										onClick={() => {
											toDetails(item?.id);
										}}
									>
										{item?.name}
										{key + 1 != value?.withPublisher?.length ? "," : ""}
									</a>
								);
						  })
						: value?.withPublisher}
					{value?.withPublisher?.length != 0 ? "" : "-"}
				</div>
			),
		},
		{
			title: "Platform",
			dataIndex: "platform",
			key: "platform",
			sortOrder: sortState?.field == "platform" && sortState?.order,
			sorter: (a, b) =>
				a?.platform &&
				b?.platform &&
				a?.platform?.[0]?.localeCompare(b?.platform?.[0]),
			render: (text, value, index) => (
				<>
					{value?.platform?.length > 0 &&
						value?.platform?.map((item, index) => {
							return <div key={index}>{item + "\n"}</div>;
						})}
				</>
			),
		},
		{
			title: "No. of ISBN",
			key: "numOfIsbn",
			dataIndex: "numOfIsbn",
			align: "center",
			sorter: true,
			sortOrder: sortState?.field == "numOfIsbn" && sortState?.order,
		},

		{
			title: "Information",
			dataIndex: "information",
			key: "information",
			render: (text, value, index) => (
				<div className="Tabel_Information">
					{value?.__meta__?.exclusives_count >= 1 && (
						<a
							style={
								value?.__meta__?.exclusives_count >= 1
									? {}
									: {
											color: "black",
											textDecoration: "none",
											cursor: "default",
									  }
							}
							className="a_style"
							onClick={() => {
								if (value?.__meta__?.exclusives_count >= 1) {
									history.push({
										pathname: "/nikoiq_exclusives",
										state: { game: value?.id },
									});
								}
							}}
						>
							Exclusive
						</a>
					)}

					{value?.__meta__?.InsightFeed_count >= 1 && (
						<a
							style={
								value?.__meta__?.InsightFeed_count >= 1
									? {}
									: {
											color: "black",
											textDecoration: "none",
											cursor: "default",
									  }
							}
							className="a_style"
							onClick={() => {
								if (value?.__meta__?.InsightFeed_count >= 1) {
									history.push({
										pathname: "/news_articles/VANA",
										state: { game: value?.id },
									});
								}
							}}
						>
							VANA
						</a>
					)}

					{value?.__meta__?.Tournament_count >= 1 && (
						<a
							style={
								value?.__meta__?.Tournament_count >= 1
									? {}
									: {
											color: "black",
											textDecoration: "none",
											cursor: "default",
									  }
							}
							className="a_style"
							onClick={() => {
								if (value?.__meta__?.Tournament_count >= 1) {
									history.push({
										pathname: "/Esports",
										state: { game: value?.id },
									});
								}
							}}
						>
							Tournaments
						</a>
					)}
				</div>
			),
		},
		// {
		// 	title: "Release Date",
		// 	dataIndex: "publishedAt",
		// 	key: "publishedAt",
		// 	render: (text, value, index) => (
		// 		<>{text ? `${moment(text)?.format("YYYY-MM-DD")}` : "-"}</>
		// 	),
		// 	sortOrder: sortState?.field == "publishedAt" && sortState?.order,
		// 	sorter: (a, b) => {
		// 		const dateA = new Date(a?.publishedAt);
		// 		const dateB = new Date(b?.publishedAt);
		// 		return dateA - dateB;
		// 	},
		// },
	];

	useEffect(() => {
		if (!GameList) {
			effect(NGame, EGetCollect, { page: 1, perPage: 10 });
			effect(NOrganizationMulti, EGetCompany, { page: 1, perPage: 10 });
			effect(NGenre, EGet, { page: 1, perPage: 10 });
			effect(NPlatform, EGet, { page: 1, perPage: 10 });
			effect(NLogin, EGet, {});
		}
		return () => {};
	}, []);
	useEffect(() => {
		if (!GameList) {
			effect(NGame, EGet, { page: gamePage ? gamePage : 1, perPage: 10 });
		}
	}, [gamePage]);
	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetCompany, {
			name: value,
			page: 1,
			perPage: 10,
		});
	};
	const onSearchGenre = (value) => {
		effect(NGenre, EGet, { ...{ genre: value }, page: 1, perPage: 10 });
	};
	const onSearchPlatform = (value) => {
		effect(NPlatform, EGet, { ...{ platform: value }, page: 1, perPage: 10 });
	};
	const toDetails = (value) => {
		history.push(`/games_organizations/organizations/details/${value}`);
	};
	const onFinish = (values) => {
		values.platform = values?.platform?.map((platform) => {
			return PlatformData?.data?.find((item) => item?.lookupValue === platform)
				?.id;
		});
		values.genre = values?.genre?.map((genre) => {
			return GenreList?.data?.find((item) => item?.lookupValue === genre)?.id;
		});
		values.startDate = values?.Date
			? moment(values?.Date[0])?.format("YYYY-MM-DD")
			: null;
		values.endDate = values?.Date
			? moment(values?.Date[1])?.format("YYYY-MM-DD")
			: null;
		delete values?.Date;
		setSearchValue(values);
		effect(NGame, EGet, {
			...values,
			page: 1,
			perPage: 10,
			field: sortState?.field,
			isDesc: sortState?.order && sortType[sortState?.order],
		});
	};
	const onReset = () => {
		setSearchValue();
		effect(NGame, EGet, { page: 1, perPage: 10 });
		effect(NOrganizationMulti, EGetCompany, { page: 1, perPage: 10 });
		effect(NGenre, EGet, { page: 1, perPage: 10 });
		effect(NPlatform, EGet, { page: 1, perPage: 10 });
		form.resetFields();
	};

	// ---------------------渲染-------------------------
	return (
		<div>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			{userInfo?.type == "Client" ? (
				currentSubscribe?.[0]?.module.hasOwnProperty("Games") &&
				currentSubscribe?.[0]?.module["Games"]?.length > 0 ? (
					<>
						{/* <div className="GameBox mt50">
							<Row className="RowBox mt30">
								<Col span={24}>
									<div className="home_Card_Row BoxPadding">
										<Card className="home_Card_Box">
											<Image
												style={{ width: "62px", height: "64px" }}
												preview={false}
												src={
													require("../../../assets/images/game/ICO_TOLTAL.png")
														?.default
												}
												fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMIAAADDCAYAAADQvc6UAAABRWlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSSwoyGFhYGDIzSspCnJ3UoiIjFJgf8LAwSDCIMogwMCcmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsis7PPOq3QdDFcvjV3jOD1boQVTPQrgSkktTgbSf4A4LbmgqISBgTEFyFYuLykAsTuAbJEioKOA7DkgdjqEvQHEToKwj4DVhAQ5A9k3gGyB5IxEoBmML4BsnSQk8XQkNtReEOBxcfXxUQg1Mjc0dyHgXNJBSWpFCYh2zi+oLMpMzyhRcASGUqqCZ16yno6CkYGRAQMDKMwhqj/fAIcloxgHQqxAjIHBEugw5sUIsSQpBobtQPdLciLEVJYzMPBHMDBsayhILEqEO4DxG0txmrERhM29nYGBddr//5/DGRjYNRkY/l7"
											/>

											<div className="statistic_Card">
												<div className="statistic_title">Total Games</div>
												<div
													className="statistic_value"
													style={{ textAlign: "left" }}
												>
													{gameTitle?.total}
												</div>
											</div>
										</Card>
										<Card className="home_Card_Box">
											<Image
												style={{ width: "70px", height: "55px" }}
												preview={false}
												src={
													require("../../../assets/images/game/ICO_PC.png")
														?.default
												}
												fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMIAAADDCAYAAADQvc6UAAABRWlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSSwoyGFhYGDIzSspCnJ3UoiIjFJgf8LAwSDCIMogwMCcmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsis7PPOq3QdDFcvjV3jOD1boQVTPQrgSkktTgbSf4A4LbmgqISBgTEFyFYuLykAsTuAbJEioKOA7DkgdjqEvQHEToKwj4DVhAQ5A9k3gGyB5IxEoBmML4BsnSQk8XQkNtReEOBxcfXxUQg1Mjc0dyHgXNJBSWpFCYh2zi+oLMpMzyhRcASGUqqCZ16yno6CkYGRAQMDKMwhqj/fAIcloxgHQqxAjIHBEugw5sUIsSQpBobtQPdLciLEVJYzMPBHMDBsayhILEqEO4DxG0txmrERhM29nYGBddr//5/DGRjYNRkY/l7"
											/>
											<div className="statistic_Card">
												<div className="statistic_title">PC Games</div>
												<div
													className="statistic_value"
													style={{ textAlign: "left" }}
												>
													{gameTitle?.pcCount}
												</div>
											</div>
										</Card>
										<Card className="home_Card_Box">
											<Image
												style={{ width: "46px", height: "70px" }}
												preview={false}
												src={
													require("../../../assets/images/game/ICO_MOBILE.png")
														?.default
												}
												fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMIAAADDCAYAAADQvc6UAAABRWlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSSwoyGFhYGDIzSspCnJ3UoiIjFJgf8LAwSDCIMogwMCcmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsis7PPOq3QdDFcvjV3jOD1boQVTPQrgSkktTgbSf4A4LbmgqISBgTEFyFYuLykAsTuAbJEioKOA7DkgdjqEvQHEToKwj4DVhAQ5A9k3gGyB5IxEoBmML4BsnSQk8XQkNtReEOBxcfXxUQg1Mjc0dyHgXNJBSWpFCYh2zi+oLMpMzyhRcASGUqqCZ16yno6CkYGRAQMDKMwhqj/fAIcloxgHQqxAjIHBEugw5sUIsSQpBobtQPdLciLEVJYzMPBHMDBsayhILEqEO4DxG0txmrERhM29nYGBddr//5/DGRjYNRkY/l7"
											/>
											<div className="statistic_Card">
												<div className="statistic_title">Mobile Games</div>
												<div
													className="statistic_value"
													style={{ textAlign: "left" }}
												>
													{gameTitle?.mobileCount}
												</div>
											</div>
										</Card>
										<Card className="home_Card_Box">
											<Image
												style={{ width: "79px", height: "58px" }}
												preview={false}
												src={
													require("../../../assets/images/game/ICO_OTHER.png")
														?.default
												}
												fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMIAAADDCAYAAADQvc6UAAABRWlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSSwoyGFhYGDIzSspCnJ3UoiIjFJgf8LAwSDCIMogwMCcmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsis7PPOq3QdDFcvjV3jOD1boQVTPQrgSkktTgbSf4A4LbmgqISBgTEFyFYuLykAsTuAbJEioKOA7DkgdjqEvQHEToKwj4DVhAQ5A9k3gGyB5IxEoBmML4BsnSQk8XQkNtReEOBxcfXxUQg1Mjc0dyHgXNJBSWpFCYh2zi+oLMpMzyhRcASGUqqCZ16yno6CkYGRAQMDKMwhqj/fAIcloxgHQqxAjIHBEugw5sUIsSQpBobtQPdLciLEVJYzMPBHMDBsayhILEqEO4DxG0txmrERhM29nYGBddr//5/DGRjYNRkY/l7"
											/>
											<div className="statistic_Card">
												<div className="statistic_title">Console Games</div>
												<div
													className="statistic_value"
													style={{ textAlign: "left" }}
												>
													{gameTitle?.otherCount}
												</div>
											</div>
										</Card>
									</div>
								</Col>
							</Row>
						</div> */}
						<div
							className="BlogBox mt50"
							style={{ backgroundColor: "#F7F9FB", padding: "30px 50px" }}
						>
							<Form
								name="advanced_search"
								className="ant-advanced-search-form"
								form={form}
								onFinish={onFinish}
							>
								<Row>
									<Col span={8}>
										<Form.Item
											name="keyword"
											label="Game"
											rules={[
												{
													required: false,
													message: "Input something!",
												},
											]}
										>
											<Input
												placeholder="Please Enter"
												autoComplete="off"
												style={{ width: "80%" }}
											/>
										</Form.Item>
									</Col>
									{/* <Col span={8}>
										<Form.Item name="Date" label="Date">
											<RangePicker
												getPopupContainer={(triggerNode) =>
													triggerNode?.parentNode
												}
												style={{ width: "80%" }}
											/>
										</Form.Item>
									</Col> */}

									<Col span={8}>
										<Form.Item name="genre" label="Genre">
											<Select
												style={{ width: "80%" }}
												allowClear
												getPopupContainer={(triggerNode) =>
													triggerNode?.parentNode
												}
												showSearch
												filterOption={false}
												placeholder="Please Select"
												onSearch={debounce((value) => {
													onSearchGenre(value);
												}, 300)}
												mode="multiple"
											>
												{sortData(GenreList?.data, "genre")?.map(
													(item, index) => {
														return (
															<Option key={index} value={item?.lookupValue}>
																{item?.lookupValue}
															</Option>
														);
													}
												)}
											</Select>
										</Form.Item>
									</Col>

									<Col span={8}>
										<Form.Item name="developer" label="Developer">
											<Select
												style={{ width: "80%" }}
												allowClear
												getPopupContainer={(triggerNode) =>
													triggerNode?.parentNode
												}
												showSearch
												filterOption={false}
												placeholder="Please Select"
												onSearch={debounce((value) => {
													onSearchCompany(value);
												}, 300)}
											>
												{sortData(OrganizationCompany?.data)?.map(
													(item, index) => {
														return (
															<Option key={index} value={item?.id}>
																{item?.name}
															</Option>
														);
													}
												)}
											</Select>
										</Form.Item>
									</Col>

									<Col span={8}>
										<Form.Item name="publisher" label="Publisher">
											<Select
												style={{ width: "79%" }}
												allowClear
												getPopupContainer={(triggerNode) =>
													triggerNode?.parentNode
												}
												showSearch
												filterOption={false}
												placeholder="Please Select"
												onSearch={debounce((value) => {
													onSearchCompany(value);
												}, 300)}
											>
												{sortData(OrganizationCompany?.data)?.map(
													(item, index) => {
														return (
															<Option key={index} value={item?.id}>
																{item?.name}
															</Option>
														);
													}
												)}
											</Select>
										</Form.Item>
									</Col>

									<Col span={8}>
										<Form.Item
											name="platform"
											label="Platform"
											rules={[
												{
													required: false,
													message: "Input something!",
												},
											]}
										>
											<Select
												showArrow
												style={{ width: "79%" }}
												mode="multiple"
												allowClear
												getPopupContainer={(triggerNode) =>
													triggerNode?.parentNode
												}
												showSearch
												filterOption={false}
												placeholder="Please Select"
												onSearch={debounce((value) => {
													onSearchPlatform(value);
												}, 300)}
											>
												{sortData(PlatformData?.data, "platform")?.map(
													(record, index) => {
														return (
															<Option key={index} value={record?.lookupValue}>
																{record?.lookupValue}
															</Option>
														);
													}
												)}
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label="No. of ISBNs" name="numOfIsbn">
											<Select
												placeholder="Please Select "
												getPopupContainer={(triggerNode) =>
													triggerNode.parentNode
												}
												style={{ width: "79%" }}
											>
												<Option value={"0"}>0</Option>
												<Option value={"1"}>1</Option>
												<Option value={"2"}>2</Option>
												<Option value={"3"}>3</Option>
												<Option value={"4"}>4</Option>
												<Option value={"5"}>5</Option>
												<Option value={">5"}>{">5"}</Option>
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label="Status" name="status">
											<Radio.Group>
												<Radio value={""}>All</Radio>
												<Radio value={"Released"}>Released</Radio>
												<Radio value={"Retired"}>Retired</Radio>
												<Radio value={"In Development / Testing"}>
													In Development / Testing
												</Radio>
											</Radio.Group>
										</Form.Item>
									</Col>
									<Col span={16} style={{ textAlign: "right" }}>
										<Space>
											<Button
												type="primary"
												htmlType="submit"
												className="primarySearch"
											>
												Search
											</Button>
											<Button
												type="default"
												htmlType="button"
												onClick={() => {
													onReset();
													setSortState(null);
												}}
												className="secReset"
											>
												Reset
											</Button>
										</Space>
									</Col>
								</Row>
							</Form>
						</div>

						<Spin spinning={loading} size="large">
							<div className="GameBox">
								<Row className="RowBox mt30">
									<Col span={24}>
										<Table
											locale={{ emptyText: CustomEmpty }}
											showSorterTooltip={false}
											scroll={{ x: "max-content" }}
											dataSource={GameList?.data || []}
											columns={columns}
											className="CompanyTableBox"
											rowKey="id"
											pagination={{
												current: parseInt(GameList && GameList?.page) || 1,
												total: (GameList && GameList?.total) || 0,
												defaultCurrent: 1,
												defaultPageSize:
													parseInt(GameList && GameList?.perPage) || 10,
												pageSize: GameList && GameList?.perPage,
												pageSizeOptions: ["10", "20", "30", "50", "100"],
												showQuickJumper: true,
												showSizeChanger: true,
												position: "bottom",
											}}
											onChange={(pagination, filters, sort) => {
												setSortState(sort);
												effect(NGame, EGet, {
													...searchValue,
													page: pagination?.current,
													perPage: pagination?.pageSize,
													field: sort?.column && sort?.field,
													isDesc:
														(sort?.column &&
															sort?.order &&
															sortType[sort?.order]) ||
														null,
												});
											}}
										/>
									</Col>
								</Row>
							</div>
						</Spin>
					</>
				) : (
					customizeRenderEmpty()
				)
			) : (
				<>
					{/* <div className="GameBox mt50">
						<Row className="RowBox mt30">
							<Col span={24}>
								<div className="home_Card_Row BoxPadding">
									<Card className="home_Card_Box">
										<Image
											style={{ width: "62px", height: "64px" }}
											preview={false}
											src={
												require("../../../assets/images/game/ICO_TOLTAL.png")
													?.default
											}
											fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMIAAADDCAYAAADQvc6UAAABRWlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSSwoyGFhYGDIzSspCnJ3UoiIjFJgf8LAwSDCIMogwMCcmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsis7PPOq3QdDFcvjV3jOD1boQVTPQrgSkktTgbSf4A4LbmgqISBgTEFyFYuLykAsTuAbJEioKOA7DkgdjqEvQHEToKwj4DVhAQ5A9k3gGyB5IxEoBmML4BsnSQk8XQkNtReEOBxcfXxUQg1Mjc0dyHgXNJBSWpFCYh2zi+oLMpMzyhRcASGUqqCZ16yno6CkYGRAQMDKMwhqj/fAIcloxgHQqxAjIHBEugw5sUIsSQpBobtQPdLciLEVJYzMPBHMDBsayhILEqEO4DxG0txmrERhM29nYGBddr//5/DGRjYNRkY/l7"
										/>

										<div className="statistic_Card">
											<div className="statistic_title">Total Games</div>
											<div
												className="statistic_value"
												style={{ textAlign: "left" }}
											>
												{gameTitle?.total}
											</div>
										</div>
									</Card>
									<Card className="home_Card_Box">
										<Image
											style={{ width: "70px", height: "55px" }}
											preview={false}
											src={
												require("../../../assets/images/game/ICO_PC.png")
													?.default
											}
											fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMIAAADDCAYAAADQvc6UAAABRWlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSSwoyGFhYGDIzSspCnJ3UoiIjFJgf8LAwSDCIMogwMCcmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsis7PPOq3QdDFcvjV3jOD1boQVTPQrgSkktTgbSf4A4LbmgqISBgTEFyFYuLykAsTuAbJEioKOA7DkgdjqEvQHEToKwj4DVhAQ5A9k3gGyB5IxEoBmML4BsnSQk8XQkNtReEOBxcfXxUQg1Mjc0dyHgXNJBSWpFCYh2zi+oLMpMzyhRcASGUqqCZ16yno6CkYGRAQMDKMwhqj/fAIcloxgHQqxAjIHBEugw5sUIsSQpBobtQPdLciLEVJYzMPBHMDBsayhILEqEO4DxG0txmrERhM29nYGBddr//5/DGRjYNRkY/l7"
										/>
										<div className="statistic_Card">
											<div className="statistic_title">PC Games</div>
											<div
												className="statistic_value"
												style={{ textAlign: "left" }}
											>
												{gameTitle?.pcCount}
											</div>
										</div>
									</Card>
									<Card className="home_Card_Box">
										<Image
											style={{ width: "46px", height: "70px" }}
											preview={false}
											src={
												require("../../../assets/images/game/ICO_MOBILE.png")
													?.default
											}
											fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMIAAADDCAYAAADQvc6UAAABRWlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSSwoyGFhYGDIzSspCnJ3UoiIjFJgf8LAwSDCIMogwMCcmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsis7PPOq3QdDFcvjV3jOD1boQVTPQrgSkktTgbSf4A4LbmgqISBgTEFyFYuLykAsTuAbJEioKOA7DkgdjqEvQHEToKwj4DVhAQ5A9k3gGyB5IxEoBmML4BsnSQk8XQkNtReEOBxcfXxUQg1Mjc0dyHgXNJBSWpFCYh2zi+oLMpMzyhRcASGUqqCZ16yno6CkYGRAQMDKMwhqj/fAIcloxgHQqxAjIHBEugw5sUIsSQpBobtQPdLciLEVJYzMPBHMDBsayhILEqEO4DxG0txmrERhM29nYGBddr//5/DGRjYNRkY/l7"
										/>
										<div className="statistic_Card">
											<div className="statistic_title">Mobile Games</div>
											<div
												className="statistic_value"
												style={{ textAlign: "left" }}
											>
												{gameTitle?.mobileCount}
											</div>
										</div>
									</Card>
									<Card className="home_Card_Box">
										<Image
											style={{ width: "79px", height: "58px" }}
											preview={false}
											src={
												require("../../../assets/images/game/ICO_OTHER.png")
													?.default
											}
											fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMIAAADDCAYAAADQvc6UAAABRWlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSSwoyGFhYGDIzSspCnJ3UoiIjFJgf8LAwSDCIMogwMCcmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsis7PPOq3QdDFcvjV3jOD1boQVTPQrgSkktTgbSf4A4LbmgqISBgTEFyFYuLykAsTuAbJEioKOA7DkgdjqEvQHEToKwj4DVhAQ5A9k3gGyB5IxEoBmML4BsnSQk8XQkNtReEOBxcfXxUQg1Mjc0dyHgXNJBSWpFCYh2zi+oLMpMzyhRcASGUqqCZ16yno6CkYGRAQMDKMwhqj/fAIcloxgHQqxAjIHBEugw5sUIsSQpBobtQPdLciLEVJYzMPBHMDBsayhILEqEO4DxG0txmrERhM29nYGBddr//5/DGRjYNRkY/l7"
										/>
										<div className="statistic_Card">
											<div className="statistic_title">Console Games</div>
											<div
												className="statistic_value"
												style={{ textAlign: "left" }}
											>
												{gameTitle?.otherCount}
											</div>
										</div>
									</Card>
								</div>
							</Col>
						</Row>
					</div> */}
					<div
						className="BlogBox mt50"
						style={{ backgroundColor: "#F7F9FB", padding: "30px 50px" }}
					>
						<Form
							name="advanced_search"
							className="ant-advanced-search-form"
							form={form}
							onFinish={onFinish}
						>
							<Row>
								<Col span={8}>
									<Form.Item
										name="keyword"
										label=" Game"
										rules={[
											{
												required: false,
												message: "Input something!",
											},
										]}
									>
										<Input
											placeholder="Please Enter"
											autoComplete="off"
											style={{ width: "80%" }}
										/>
									</Form.Item>
								</Col>
								{/* <Col span={8}>
									<Form.Item name="Date" label="Date">
										<RangePicker
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											style={{ width: "80%" }}
										/>
									</Form.Item>
								</Col> */}

								<Col span={8}>
									<Form.Item name="genre" label="Genre">
										<Select
											style={{ width: "80%" }}
											allowClear
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											showSearch
											filterOption={false}
											placeholder="Please Select"
											onSearch={debounce((value) => {
												onSearchGenre(value);
											}, 300)}
											mode="multiple"
										>
											{sortData(GenreList?.data, "genre")?.map(
												(item, index) => {
													return (
														<Option key={index} value={item?.lookupValue}>
															{item?.lookupValue}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>

								<Col span={8}>
									<Form.Item name="developer" label="Developer">
										<Select
											style={{ width: "80%" }}
											allowClear
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											showSearch
											filterOption={false}
											placeholder="Please Select"
											onSearch={debounce((value) => {
												onSearchCompany(value);
											}, 300)}
										>
											{sortData(OrganizationCompany?.data)?.map(
												(item, index) => {
													return (
														<Option key={index} value={item?.id}>
															{item?.name}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>

								<Col span={8}>
									<Form.Item name="publisher" label="Publisher">
										<Select
											style={{ width: "79%" }}
											allowClear
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											showSearch
											filterOption={false}
											placeholder="Please Select"
											onSearch={debounce((value) => {
												onSearchCompany(value);
											}, 300)}
										>
											{sortData(OrganizationCompany?.data)?.map(
												(item, index) => {
													return (
														<Option key={index} value={item?.id}>
															{item?.name}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>

								<Col span={8}>
									<Form.Item
										name="platform"
										label="Platform"
										rules={[
											{
												required: false,
												message: "Input something!",
											},
										]}
									>
										<Select
											showArrow
											style={{ width: "79%" }}
											mode="multiple"
											allowClear
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											showSearch
											filterOption={false}
											placeholder="Please Select"
											onSearch={debounce((value) => {
												onSearchPlatform(value);
											}, 300)}
										>
											{sortData(PlatformData?.data, "platform")?.map(
												(record, index) => {
													return (
														<Option key={index} value={record?.lookupValue}>
															{record?.lookupValue}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>

								<Col span={8}>
									<Form.Item label="No. of ISBNs" name="numOfIsbn">
										<Select
											placeholder="Please Select "
											getPopupContainer={(triggerNode) =>
												triggerNode.parentNode
											}
											style={{ width: "79%" }}
										>
											<Option value={"0"}>0</Option>
											<Option value={"1"}>1</Option>
											<Option value={"2"}>2</Option>
											<Option value={"3"}>3</Option>
											<Option value={"4"}>4</Option>
											<Option value={"5"}>5</Option>
											<Option value={">5"}>{">5"}</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Status" name="status">
										<Radio.Group>
											<Radio value={""}>All</Radio>
											<Radio value={"Released"}>Released</Radio>
											<Radio value={"Retired"}>Retired</Radio>
											<Radio value={"In Development / Testing"}>
												In Development / Testing
											</Radio>
										</Radio.Group>
									</Form.Item>
								</Col>
								<Col span={16} style={{ textAlign: "right" }}>
									<Space>
										<Button
											type="primary"
											htmlType="submit"
											className="primarySearch"
										>
											Search
										</Button>
										<Button
											type="default"
											htmlType="button"
											onClick={() => {
												onReset();
												setSortState(null);
											}}
											className="secReset"
										>
											Reset
										</Button>
									</Space>
								</Col>
							</Row>
						</Form>
					</div>

					<Spin spinning={loading} size="large">
						<div className="GameBox">
							<Row className="RowBox mt30">
								<Col span={24}>
									<Table
										locale={{ emptyText: CustomEmpty }}
										showSorterTooltip={false}
										scroll={{ x: "max-content" }}
										dataSource={GameList?.data || []}
										columns={columns}
										className="CompanyTableBox"
										rowKey="id"
										pagination={{
											current: parseInt(GameList && GameList?.page) || 1,
											total: (GameList && GameList?.total) || 0,
											defaultCurrent: 1,
											defaultPageSize:
												parseInt(GameList && GameList?.perPage) || 10,
											pageSize: GameList && GameList?.perPage,
											pageSizeOptions: ["10", "20", "30", "50", "100"],
											showQuickJumper: true,
											showSizeChanger: true,
											position: "bottom",
										}}
										onChange={(pagination, filters, sort) => {
											effect(NGame, EGeTPage, { page: pagination?.current });
											setSortState(sort);
											effect(NGame, EGet, {
												...searchValue,
												page: pagination?.current,
												perPage: pagination?.pageSize,
												field: sort?.column && sort?.field,
												isDesc:
													(sort?.column &&
														sort?.order &&
														sortType[sort?.order]) ||
													null,
											});
										}}
									/>
								</Col>
							</Row>
						</div>
					</Spin>
				</>
			)}
			{/* {} */}
		</div>
	);
};
