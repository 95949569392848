import React, { useState, useEffect } from "react";
import moment from "moment";
import { debounce } from "lodash";
import "../Profile/index.less";
import formatDate from "@/utils/dateFormater";
import "./index.less";
import {
	Button,
	Row,
	Col,
	Space,
	Table,
	Form,
	DatePicker,
	Select,
	Spin,
	Tag,
	Input,
	InputNumber,
} from "antd";
import sortData from "../../../utils/sorter";
import { effect, useStore } from "../../../utils/dva16";
import {
	EGetArea,
	EGet,
	EGetOne,
	EGetCollect,
	NTournament,
	NGame,
	NPlatform,
	EGetType,
	NOrganizationMulti,
	NRegion,
	NLogin,
	NTournamentType,
	NSpecialCharacteristics,
	EGetRegion,
} from "../../../models/contants";
const { RangePicker } = DatePicker;
const { Option } = Select;
import { customizeRenderEmpty } from "../../../utils/utils";
import CustomEmpty from "@/utils/CustomEmpty";
import { Helmet } from "react-helmet";

export default (props) => {
	const { history } = props;
	const {
		location: { state },
	} = history;
	const [form] = Form.useForm();
	const { areaList, regionList, commonAreaList } = useStore(NRegion);
	const [keyword, setKeyword] = useState({});
	const { organizationSearch } = useStore(NOrganizationMulti);
	const { gameSearch } = useStore(NGame);
	const { currentSubscribe, userInfo } = useStore(NLogin);
	const { PlatformData } = useStore(NPlatform);
	const { TournamentList, loading } = useStore(NTournament);
	const { tournamentTypeList } = useStore(NTournamentType);
	const { specialCharacteristicsList } = useStore(NSpecialCharacteristics);
	const [sortState, setSortState] = useState(null);
	let sortType = {
		ascend: "asc",
		descend: "desc",
	};
	const columns = [
		{
			title: "Tournament",
			dataIndex: "tournament",
			key: "tournament",
			width: "250px",
			render: (text, record, index) => (
				<>
					<div className="Tabel_Company">
						<div className="Tabel_Company_Title">
							<p>
								<a className="a_style" href={`#/esports/details/${record?.id}`}>
									{text}
								</a>
							</p>
						</div>
					</div>
				</>
			),
			sortOrder: sortState?.field == "tournament" && sortState?.order,
			sorter: (a, b) => a?.tournament?.localeCompare(b?.tournament),
		},
		{
			title: "Host Area/Region",
			dataIndex: "area",
			key: "area",
			width: "250px",
			render: (text, record) => (
				<div>
					{record?.area?.length
						? // ? record?.area?.map((vo, io) => <div key={io}>{vo}</div>)
						  record?.area?.join(", ")
						: "-"}
					<br />
					{record?.region?.length
						? // record?.region?.map((vo, io) => <div key={io}>{vo}</div>)
						  record?.region?.join(", ")
						: "-"}
				</div>
			),
			sortOrder: sortState?.field == "area" && sortState?.order,
			sorter: (a, b) => {
				a?.area?.[0] &&
					b?.area?.[0] &&
					a?.area?.[0]?.localeCompare(b?.area?.[0]);
			},
		},
		{
			title: "Game",
			dataIndex: "game",
			key: "game",
			width: "150px",
			render: (text, record, index) => (
				<div>
					{record?.withGameList?.map((value, index) => {
						return (
							<Tag
								color="#19AE54"
								key={index}
								onClick={() => {
									history.push(
										`/games_organizations/games/details/${value?.id}`
									);
								}}
								style={{ cursor: "pointer", borderRadius: "20px" }}
							>
								{value?.gameEn + "\n"}
							</Tag>
						);
					})}
				</div>
			),
			sortOrder: sortState?.field == "game" && sortState?.order,
			sorter: (a, b) => {
				a?.withGameList?.[0]?.gameEn &&
					b?.withGameList?.[0]?.gameEn &&
					a?.withGameList?.[0]?.gameEn?.localeCompare(
						b?.withGameList?.[0]?.gameEn
					);
			},
		},
		{
			title: "Prize Pool",
			dataIndex: "prizePool",
			key: "prizePool",
			width: "100px",
			render: (text, value, index) => (
				<>
					{text
						? "$" + text?.toString()?.replace(/(\d)(?=(\d{3})+\b)/g, "$1,")
						: "-"}
				</>
			),
			sortOrder: sortState?.field == "prizePool" && sortState?.order,
			sorter: (a, b) => Number(a?.prizePool) - Number(b?.prizePool),
		},
		{
			title: "Platforms",
			dataIndex: "platforms",
			key: "platforms",
			width: "100px",
			render: (text, value, index) => (
				<>
					{value?.platforms &&
						value?.platforms?.map((item, index) => {
							return <div key={index}>{item + "\n"}</div>;
						})}
				</>
			),
			sortOrder: sortState?.field == "platforms" && sortState?.order,
			sorter: (a, b) =>
				a?.platforms &&
				b?.platforms &&
				a?.platforms?.[0]?.localeCompare(b?.platforms?.[0]),
		},
		{
			title: "Scope",
			dataIndex: "scope",
			key: "scope",
			width: "100px",
			render: (text, record, index) => (
				<>
					<div className="Tabel_Company">
						<div className="Tabel_Company_Title">
							<p>
								<span>{text || "-"}</span>
							</p>
						</div>
					</div>
				</>
			),
			sortOrder: sortState?.field == "scope" && sortState?.order,
			sorter: (a, b) => a?.scope?.localeCompare(b?.scope),
		},
		{
			title: "Venue",
			dataIndex: "venue",
			key: "venue",
			width: "100px",
			render: (text, record, index) => (
				<>
					<div className="Tabel_Company">
						<div className="Tabel_Company_Title">
							<p>
								<span>{text || "-"}</span>
							</p>
						</div>
					</div>
				</>
			),

			sortOrder: sortState?.field == "venue" && sortState?.order,
			sorter: (a, b) => a?.venue?.localeCompare(b?.venue),
		},

		{
			title: "Start Date",
			dataIndex: "startDate",
			key: "startDate",
			width: "200px",
			render: (text, record, index) => {
				const date = new Date(record?.startDate);
				const formattedDate = formatDate(date);
				return <div>{record?.startDate ? formattedDate : ""} </div>;
			},
			sortOrder: sortState?.field == "startDate" && sortState?.order,
			sorter: (a, b) => {
				const dateA = new Date(a?.startDate);
				const dateB = new Date(b?.startDate);
				return dateA - dateB;
			},
		},
	];
	useEffect(() => {
		if (state) {
			form.setFieldsValue({
				...state,
			});
			if (state?.company) {
				form.setFieldsValue({
					company: state?.company,
				});
			}
		}
		effect(NRegion, EGetArea, { page: 1, perPage: 200 });
		effect(NRegion, EGetRegion, { page: 1, perPage: 10 });
		effect(NPlatform, EGet, { page: 1, perPage: 100 });
		effect(NGame, EGetType, { page: 1, perPage: 10 });
		effect(NOrganizationMulti, EGetType, { page: 1, perPage: 10 });
		effect(NTournamentType, EGet, { page: 1, perPage: 100 });
		effect(NSpecialCharacteristics, EGet, { page: 1, perPage: 100 });
		return () => {};
	}, []);
	useEffect(() => {
		let page = sessionStorage.getItem("tournamentPage");
		let pageSize = sessionStorage.getItem("tournamentPageSize");
		if (page && pageSize) {
			effect(NTournament, EGet, { page, pageSize });
		} else {
			effect(NTournament, EGet, { page: 1, pageSize: 10, ...state });
		}
		effect(NTournament, EGetCollect, {});
		effect(NTournament, EGetOne, {});
		effect(NLogin, EGet, {});
	}, []);
	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetType, {
			name: value,
			page: 1,
			perPage: 10,
		});
	};
	const onSearchGame = (value) => {
		effect(NGame, EGetType, { ...{ name: value }, page: 1, perPage: 10 });
	};
	const onSearchTournament = (value) => {
		effect(NTournament, EGetType, {
			...{ tournament: value },
			page: 1,
			perPage: 10,
		});
	};

	const onFinish = (values) => {
		values.startDate = values?.date
			? moment(values?.date[0])?.format("YYYY-MM-DD")
			: null;
		values.endDate = values?.date
			? moment(values?.date[1])?.format("YYYY-MM-DD")
			: null;
		delete values?.date;
		values.area = values.area && [
			...values?.area?.map((areaValue) => {
				return commonAreaList?.data?.find(
					(item) => item?.otherData === areaValue
				)?.id;
			}),
			...values.area,
		];
		setKeyword(values);
		effect(NTournament, EGet, {
			...values,
			page: 1,
			perPage: 10,
			field: sortState?.field,
			isDesc: sortState?.order && sortType[sortState?.order],
		});
	};

	const onReset = () => {
		sessionStorage.setItem("tournamentPage", 1);
		sessionStorage.setItem("tournamentPageSize", 10);
		setKeyword();
		form.resetFields();
		effect(NTournament, EGet, { page: 1, perPage: 10 });
		effect(NOrganizationMulti, EGetType, { page: 1, perPage: 10 });
		effect(NGame, EGetType, { page: 1, perPage: 10 });
		setSortState(null);
	};
	const [regionArr, setRegionArr] = useState([]);
	function findRegionType(arr) {
		const updatedRegionArr = [];

		areaList?.data?.forEach((item) => {
			if (arr?.includes(item?.otherData)) {
				updatedRegionArr?.push(item?.lookupValue);
			}
		});
		setRegionArr(Array.from(new Set(updatedRegionArr)));
		form.setFieldsValue({
			region: Array.from(new Set(updatedRegionArr)),
		});
	}

	return (
		<div>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			{userInfo?.type === "Admin" ||
			(currentSubscribe?.[0]?.module.hasOwnProperty("Esport") &&
				currentSubscribe?.[0]?.module["Esport"]?.length > 0) ? (
				<>
					<div
						className="BlogBox mt50"
						style={{
							backgroundColor: "#F7F9FB",
							padding: "40px 40px 20px 0px",
						}}
					>
						<Form
							name="advanced_search"
							className="ant-advanced-search-form"
							labelCol={{ span: 8 }}
							form={form}
							onFinish={onFinish}
						>
							<Row>
								<Col xl={8} xxl={8} xs={12}>
									<Form.Item name="tournament" label="Tournament">
										<Input placeholder="Please input key words of tournament"></Input>
									</Form.Item>
								</Col>
								<Col xl={8} xxl={8} xs={12}>
									<Form.Item name="area" label="Host Area">
										<Select
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											mode="multiple"
											placeholder="Please Search"
											allowClear
											showSearch
											onChange={(e) => {
												findRegionType(e);
											}}
											onSearch={debounce((value) => {
												const { page, perPage } = areaList;
												effect(NRegion, EGetArea, {
													page,
													perPage,
													area: value,
												});
											}, 300)}
											filterOption={false}
										>
											{sortData(areaList?.data, "area")?.map(
												(record, index) => {
													return (
														<Option key={record?.id} value={record?.otherData}>
															{record?.otherData}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>
								<Col xl={8} xxl={8} xs={12}>
									<Form.Item name="region" label="Host Region">
										<Select
											mode="multiple"
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											placeholder="Please Select"
										>
											{regionList?.map((vo, index) => (
												<Option key={index} value={vo?.lookupValue}>
													{vo?.lookupValue}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Tournament Type" name="tournament_type">
										<Select
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											showArrow={true}
											placeholder="Please Select"
											mode="multiple"
										>
											{sortData(
												tournamentTypeList?.data,
												"tournament_type"
											)?.map((record, index) => {
												return (
													<Option
														key={record?.id}
														value={record?.tournament_type}
													>
														{record?.tournament_type}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
								</Col>
								<Col xl={8} xxl={8} xs={12}>
									<Form.Item name="date" label="Start Date">
										<RangePicker
											format="MMM DD, YYYY"
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											style={{ width: "100%" }}
											placeholder={["From", "To"]}
										/>
									</Form.Item>
								</Col>
								<Col xl={8} xxl={8} xs={12}>
									<Form.Item name="venue" label="Venue">
										<Select
											getPopupContainer={(triggerNode) =>
												triggerNode.parentNode
											}
											placeholder={"Please Select"}
											mode="multiple"
											showArrow={true}
										>
											<Option value="Hybrid">Hybrid</Option>
											<Option value="Offline">Offline</Option>
											<Option value="Online">Online</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xl={8} xxl={8} xs={12}>
									<Form.Item name="scope" label="Scope">
										<Select
											getPopupContainer={(triggerNode) =>
												triggerNode.parentNode
											}
											placeholder={"Please Select"}
											mode="multiple"
											showArrow
										>
											<Option value="Local">Local</Option>
											<Option value="Regional">Regional</Option>
											<Option value="Global">Global</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xl={8} xxl={8} xs={12}>
									<Form.Item name="game" label="Game">
										<Select
											allowClear
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											showSearch
											filterOption={false}
											placeholder="Please Select"
											onSearch={debounce((value) => {
												onSearchGame(value);
											}, 100)}
										>
											{sortData(gameSearch?.data, "gameEn")?.map(
												(item, index) => {
													return (
														<Option key={item?.id} value={item?.id}>
															{item?.gameEn}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Prize Pool">
										<Input.Group compact>
											<Form.Item name="prizeMin" noStyle>
												<InputNumber
													placeholder="Min"
													min={0}
													style={{ width: "50%" }}
												/>
											</Form.Item>
											<Form.Item name="prizeMax" noStyle>
												<InputNumber
													placeholder="Max"
													min={0}
													style={{ width: "50%" }}
												/>
											</Form.Item>
										</Input.Group>
									</Form.Item>
								</Col>
								<Col xl={8} xxl={8} xs={12}>
									<Form.Item label="Platforms" name="platforms">
										<Select
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											mode="multiple"
											showArrow={true}
											placeholder="Please Select"
											filterOption={(input, option) =>
												option?.children
													?.toLowerCase()
													.indexOf(input?.toLowerCase()) >= 0
											}
										>
											{sortData(PlatformData?.data, "platform")?.map(
												(record, index) => {
													return (
														<Option key={record?.id} value={record?.id}>
															{record?.lookupValue}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										name="special_characteristics"
										label="Tournament Characteristics"
									>
										<Select
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											showArrow={true}
											placeholder="Please Select"
											mode="multiple"
										>
											{sortData(
												specialCharacteristicsList?.data,
												"tournament_special_characterisctics"
											)?.map((record, index) => {
												return (
													<Option
														key={record?.id}
														value={record?.tournament_special_characterisctics}
													>
														{record?.tournament_special_characterisctics}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
								</Col>
								<Col xl={8} xxl={8} xs={12}>
									<Form.Item name="hosts" label="Host">
										<Select
											allowClear
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											showSearch
											filterOption={false}
											placeholder="Please Select"
											onSearch={debounce((value) => {
												onSearchCompany(value);
											}, 300)}
										>
											{sortData(organizationSearch?.data)?.map(
												(item, index) => {
													return (
														<Option key={item?.id} value={item?.id}>
															{item?.name}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>

								<Col span={24} style={{ textAlign: "right" }}>
									<Space>
										<Button type="primary" htmlType="submit" className="primarySearch"> 
											Search
										</Button>
										<Button type="default" htmlType="button" onClick={onReset} 	className="secReset">
											Reset
										</Button>
									</Space>
								</Col>
							</Row>
						</Form>
					</div>

					<Spin spinning={loading} size="large">
						<div className="CompanyBox pdb40">
							<Row className="RowBox mt30">
								<Col span={24}>
									<Table
										locale={{ emptyText: CustomEmpty }}
										scroll={{ x: "max-content" }}
										dataSource={TournamentList?.data || []}
										columns={columns}
										className="CompanyTableBox"
										rowKey="id"
										pagination={{
											current:
												parseInt(TournamentList && TournamentList?.page) || 1,
											total: (TournamentList && TournamentList?.total) || 0,
											defaultCurrent: 1,
											defaultPageSize:
												parseInt(TournamentList && TournamentList?.perPage) ||
												10,
											pageSize: TournamentList && TournamentList?.perPage,
											pageSizeOptions: ["10", "20", "30", "50", "100"],
											showQuickJumper: true,
											showSizeChanger: true,
											position: "bottom",
										}}
										onChange={(pagination, filter, sort) => {
											setSortState(sort);
											effect(NTournament, EGet, {
												...keyword,
												page: pagination?.current,
												perPage: pagination?.pageSize,
												field: sort?.column && sort?.field,
												isDesc:
													(sort?.column &&
														sort?.order &&
														sortType[sort?.order]) ||
													null,
											});
											sessionStorage.setItem(
												"tournamentPage",
												pagination?.current
											);
											sessionStorage.setItem(
												"tournamentPageSize",
												pagination?.pageSize
											);
										}}
									/>
								</Col>
							</Row>
						</div>
					</Spin>
				</>
			) : (
				customizeRenderEmpty()
			)}
		</div>
	);
};
