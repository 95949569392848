import { requestGet } from "../utils/dva16";

import {
	NOrganizationMulti,
	EGet,
	EGetChart,
	RSetState,
	EGetCompany,
	EGetDetail,
	EGetAll,
	EGeTPage,
	EGetType,
} from "./contants";

const Organization = {
	namespace: NOrganizationMulti,
	state: {
		OrganizationMultiList: null,
		OrganizationCompany: null,
		OrganizationMultiChart: null,
		loading: false,
		organizationsDetailData: null,
		OrganizationAll: null,
		orgPage: null,
		organizationSearch: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("organizations", payload);
			reducer(RSetState, { OrganizationMultiList: res, loading: false });
		},
		async [EGetAll]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("organizations", payload);
			reducer(RSetState, { OrganizationAll: res });
		},
		async [EGetCompany](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			let res = await requestGet("organizations", payload);
			reducer(RSetState, { OrganizationCompany: res });
		},

		async [EGetChart](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			let res = await requestGet("organizations/company/getChart", payload);
			reducer(RSetState, { OrganizationMultiChart: res });
		},

		async [EGetDetail](
			{ payload, callback },
			{ call, reducer, select, effect }
		) {
			reducer(RSetState, { loading: true });
			let res = await requestGet(`organizations/${payload?.id}`, payload);
			reducer(RSetState, { organizationsDetailData: res, loading: false });
		},
		async [EGeTPage]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });

			let res = payload.page;
			reducer(RSetState, { orgPage: res });
		},
		async [EGetType]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { load: true });
			let res = await requestGet("organizationSearch", payload);
			reducer(RSetState, { organizationSearch: res, load: false });
		},
	},
};
export default Organization;
