import React, { useState, useEffect } from "react";
import "./index.less";
import { Button, Typography, Form, Input } from "antd";
import { effect } from "../../utils/dva16";
import {
	EPost,
	NLogin,
	EPostUser,
	EPostPassWordReset,
} from "../../models/contants";
import FindPassWord from "../Login/component/FindPassWord";
import NewUser from "../Login/component/NewUser";
const { Link } = Typography;
import { RightOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";

export default (props) => {
	const { history } = props;
	const [visible, setVisible] = useState(false); //控制找回密码弹窗 | Control Retrieve Password Popup
	const [Registervisible, setregisterVisible] = useState(false); //控制用户注册弹窗 | Control User Registration Popup
	const isSmallScreen = useMediaQuery({ maxWidth: 768 });

	const onFinish = (values) => {
		effect(NLogin, EPost, values, () => {
			history.replace("/");
		});
	};
	const onSignUp = (values) => {
		setregisterVisible(true);
	};
	const onFind = () => {
		history.push("/ForgetPassword");
	};
	return (
		<div>
			<div className="LoginBox">
				<header>
					<div className="LogoBox">
						<Link herf="">
							<img src={require("@/assets/icon/logo_niko.png")?.default} />
						</Link>
					</div>
					<div>
						<nav className="nav">
							<Link
								style={{
									opacity: "1",
									letterSpacing: "0",
									color: "#2E373C",
									fontWeight: "400",
									fontSize: isSmallScreen ? "1rem" : "1.5rem",
								}}
								onClick={() => {
									history.replace("/FAQ");
								}}
							>
								FAQ
							</Link>
							<Link
								style={{
									opacity: "1",
									letterSpacing: "0",
									color: "#2E373C",
									fontWeight: "400",
									fontSize: isSmallScreen ? "1rem" : "1.5rem",
								}}
								onClick={() => {
									window.open("https://nikopartners.com/about/");
								}}
							>
								About NikoIQ
							</Link>
							<Link
								style={{
									opacity: "1",
									letterSpacing: "0",
									color: "#2E373C",
									fontWeight: "400",
									fontSize: isSmallScreen ? "1rem" : "1.5rem",
								}}
								onClick={() => {
									window.open("https://nikopartners.com/");
								}}
							>
								Niko Partners
							</Link>
						</nav>
					</div>
				</header>
				<div className="LoginFormBox">
					<div className="LoginTitleBox">
						<h2>Login</h2>
					</div>
					<Form name="login" onFinish={onFinish} autoComplete="off">
						<Form.Item
							name="email"
							rules={[
								{
									required: true,
									message: "Please Enter Email",
								},
								{
									type: "email",
									message: "The input is not valid E-mail!",
								},
							]}
						>
							<Input placeholder="Email Address" style={{ width: "100%" }} />
						</Form.Item>

						<Form.Item
							name="password"
							rules={[{ required: true, message: "Please Enter Password" }]}
							style={{ marginTop: "3rem" }}
						>
							<Input.Password
								placeholder="Password"
								visibilityToggle={true}
								style={{
									width: "100%",
									alignItems: "center",
								}}
							/>
						</Form.Item>

						<Form.Item className="Login_Btn_Label">
							<Button
								type="primary"
								size="large"
								htmlType="submit"
								className="Login_Btn_Box"
							>
								Login <RightOutlined />
							</Button>

							<span className="forget" onClick={onFind}>
								Forgot Password?
							</span>
						</Form.Item>
					</Form>
				</div>
				<div className="footer">
					<Link
						className="linkItem"
						onClick={() => history.push("/term_and_conditions")}
					>
						Terms and conditions
					</Link>
					<span style={{ margin: "0 1rem" }}> - </span>
					<Link
						className="linkItem"
						onClick={() => history.push("/privacy_policy")}
					>
						Privacy policy
					</Link>
				</div>
			</div>
			<FindPassWord
				className="find_Password"
				title={`Retrieve Password`}
				centered
				width={800}
				visible={visible}
				footer={null}
				onCancel={() => {
					setVisible(false);
				}}
				onOk={(payload) => {
					setVisible(false);
					effect(NLogin, EPostPassWordReset, payload, () => {});
				}}
			/>
			<NewUser
				className="new_User"
				title={`Sign Up`}
				centered
				width={800}
				footer={null}
				visible={Registervisible}
				onCancel={() => {
					setregisterVisible(false);
				}}
				onOk={(payload) => {
					effect(NLogin, EPostUser, payload, () => {
						setregisterVisible(false);
					});
				}}
			/>
		</div>
	);
};
