import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.less";
import reportWebVitals from "./reportWebVitals";
import { notification } from "antd";
import { initDva, initRequest, bindJWTToken } from "./utils/dva16";
import models from "./models";
import { Config } from "./config";
import { getToken, setToken } from "./utils/utils";

initDva(models, { useImmer: false, printLog: false });
initRequest(Config.SERVER_HOME, (status, data) => {
	console.log("[error]", status, data);
	if (status === 401 || data?.code === 501) {
		window.location.href = "#/login";
		console.log("status: ", status);
		if (getToken() === "" || getToken() === null || getToken() === undefined) {
			window.location.href = "#/login";
		}
		return;
	}
	notification.error({
		message: (
			<span style={{ fontSize: "1.5rem" }}>
				{data?.message ||
					"We encountered an issue while processing your request."}
			</span>
		),
		description: "",
		placement: "bottom",
		style: {
			display: "inline-block",
			width: "100%",
			fontWeight: "bold",
		},
	});
});
bindJWTToken(getToken());

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
