import { requestGet } from "../utils/dva16";

import { NISBN, EGet, EGetOne, RSetState } from "./contants";

const ISBN = {
	namespace: NISBN,
	state: {
		ISBNTitleData: null,
		ISBNData: null,
		loading: false,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			reducer(RSetState, { loading: true });
			let res = await requestGet("v2/isbn", payload);
			reducer(RSetState, { ISBNData: res, loading: false });
		},
		async [EGetOne]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("v2/isbnTitle", payload);
			reducer(RSetState, { ISBNTitleData: res });
		},
	},
};
export default ISBN;
