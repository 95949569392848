import React, { useState, useEffect } from "react";
import moment from "moment";
import { effect, useStore } from "../../../utils/dva16";
import "../Profile/index.less";
import "./index.less";
import formatDate from "@/utils/dateFormater";
import ChangePassword from "../../../layout/components/ChangePassword";
import { Typography, Table, Row, Col, Button } from "antd";
import SubScription from "./component/SubScription";
import CurrentSubscribe from "./component/CurrentSubscribe";
import { EGet, NLogin, EPostPassWordEdit } from "../../../models/contants";
import CustomEmpty from "@/utils/CustomEmpty";
const { Title, Text, Link } = Typography;

export default (props) => {
	const { userInfo, currentSubscribe } = useStore(NLogin);
	const [visible, setVisible] = useState(false);
	const [editItem, setEditItem] = useState(null);
	const [subscribe, setSubscribe] = useState(null);
	const [show, setShow] = useState(false);
	const [showHistory, setShowHistory] = useState(false);
	useEffect(() => {
		effect(NLogin, EGet, {});
		setShowHistory(false);
	}, []);
	const columns = [
		{
			title: "Subscribe Date",
			dataIndex: "subscribeDate",
			key: "subscribeDate",
			width: 100,
			align: "center",
			render: (text, value, index) => (
				<>{text ? `${moment(text)?.format("YYYY-MM-DD")}` : "-"}</>
			),
		},
		{
			title: "Expired data",
			dataIndex: "expiredDate",
			key: "expiredDate",
			width: 100,
			align: "center",
			render: (text, value, index) => (
				<>{text ? `${moment(text)?.format("YYYY-MM-DD")}` : "-"}</>
			),
		},
		{
			title: "Region",
			dataIndex: "region",
			key: "region",
			width: 100,
			align: "center",
			render: (text, value, index) => (
				<>
					{text
						?.map((item, index) => {
							return item ? item : "-";
						})
						?.join(",")}
				</>
			),
		},
		{
			title: "Subscription",
			dataIndex: "Subscription",
			key: "Subscription",
			width: 100,
			align: "center",
			render: (text, record, index) => (
				<div
				// style={{ textAlign: "center" }}
				>
					<Link
						onClick={() => {
							onshow(record);
						}}
					>
						View
					</Link>
				</div>
			),
		},
	];
	const onCreate = (userInfo) => {
		setVisible(true);
		setEditItem(userInfo);
	};
	const onshow = (record) => {
		setShow(true);
		setSubscribe(record);
	};

	// console.log(userInfo);
	// console.log("curr", currentSubscribe);
	const date = new Date(userInfo && userInfo?.createdAt);
	const formattedDate = formatDate(date);
	return (
		<div className="GridBox wd100" style={{ padding: "250px 0 0 0" }}>
			<div className="user_Info_Box" style={{ padding: "0 20px" }}>
				<div className="user_Lft_Label">
					<div className="u_Lft_Head_Info">
						<div
							className="u_Head_Pic"
							style={{
								backgroundImage: `url(${
									userInfo?.profileImage ||
									require("@/assets/images/user_head_pic.png").default
								})`,
							}}
						></div>
						<div className="u_Lft_UserBox_info">
							<div className="u_Lft_User_Name">{userInfo?.username}</div>
							<div
								className="u_Lft_User_Pwd"
								onClick={() => {
									onCreate(userInfo);
								}}
							>
								<img src={require("@/assets/icon/icon_edit.png")?.default} />
								Change Password
							</div>
						</div>
					</div>
					<div className="u_Lft_Lst_Info">
						<ul>
							<li>
								<span>Email：</span>
								<small>{userInfo?.email}</small>
							</li>
							<li>
								<span>Client：</span>
								<small>{userInfo?.clientInfo?.[0]?.clientName}</small>
							</li>
							<li>
								<span>User Type：</span>
								<small>{userInfo?.type}</small>
							</li>
							<li>
								<span>Joined Since</span>
								<small>{formattedDate}</small>
							</li>
						</ul>
					</div>
				</div>
				<div className="user_Rgt_Label">
					<h3>Current Subscription</h3>
					<CurrentSubscribe />
					<div className="historyBtnDiv">
						<Button
							className="primarySearch"
							type="primary"
							onClick={() => {
								setShowHistory(!showHistory);
							}}
						>
							{`${showHistory ? "Hide" : "View"}`} Subscription History
						</Button>
					</div>
					{showHistory && (
						<Table
							locale={{ emptyText: CustomEmpty }}
							scroll={{ x: "max-content" }}
							dataSource={(userInfo?.subscriptionInfo || [])?.filter(
								(sub) => sub?.status === 0
							)}
							columns={columns}
							className="CompanyTableBox"
							rowKey="id"
							pagination={false}
						/>
					)}
				</div>
			</div>
			<Row justify="center" style={{ margin: 20 }}>
				<Col>
					<span style={{ color: "#999", fontSize: "20px" }}>
						Contact：support@nikopartners.com
					</span>
				</Col>
			</Row>
			<ChangePassword
				editItem={editItem}
				className="common"
				footer={null}
				title={`Change Password`}
				centered
				width={800}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
				onOk={(payload) => {
					setVisible(false);
					effect(NLogin, EPostPassWordEdit, payload, () => {
						setregisterVisible(false);
					});
				}}
			/>
			<SubScription
				centered
				subscribe={subscribe}
				width={900}
				visible={show}
				onCancel={() => {
					setShow(false);
				}}
				onOk={() => {
					setShow(false);
				}}
			/>
		</div>
	);
};
