import React, { useState, useEffect } from "react";
import moment from "moment";
import { debounce } from "lodash";
import "./index.less";
import {
	Button,
	Row,
	Col,
	Space,
	Tag,
	Form,
	Input,
	DatePicker,
	Select,
	Pagination,
	Empty,
	Spin,
} from "antd";
import sortData from "../../../utils/sorter";
import { effect, useStore } from "../../../utils/dva16";
import {
	EGet,
	EGetType,
	NBlog,
	NCompany,
	NGame,
	NGenre,
	NPlatform,
	NRegion,
	EGetArea,
	NInsightType,
	EGetTypeSelect,
	NOrganizationMulti,
	EGetAll,
	NLogin,
	EGetRegion,
} from "../../../models/contants";
import { customizeRenderEmpty } from "../../../utils/utils";
import formatDate from "@/utils/dateFormater";
const { RangePicker } = DatePicker;
const { Option } = Select;
export default (props) => {
	const { currentSubscribe, userInfo } = useStore(NLogin);
	const [form] = Form.useForm();
	const { BlogList, loading } = useStore(NBlog);
	const { OrganizationAll } = useStore(NOrganizationMulti);
	const { gameSearch } = useStore(NGame);
	const { GenreList } = useStore(NGenre);
	const { PlatformData, TypeList } = useStore(NPlatform);
	const { areaList, regionList, commonAreaList } = useStore(NRegion);
	const { TypeSelectlist } = useStore(NInsightType);
	const { history } = props;
	const {
		location: { state },
	} = history;
	const [searchValue, setSeachValue] = useState({});
	const [bigType, setBigType] = useState(null);
	useEffect(() => {
		effect(NLogin, EGet, {});
		effect(NGenre, EGet, { page: 1, perPage: 10 });
		effect(NCompany, EGet, { page: 1, perPage: 10 });
		effect(NPlatform, EGet, { page: 1, perPage: 10 });
		effect(NPlatform, EGetType, {});
		effect(NGame, EGetType, { page: 1, perPage: 10 });
		effect(NGame, EGet, { page: 1, perPage: 10, id: state?.game });
		effect(NRegion, EGet, { page: 1, perPage: 10 });
		effect(NRegion, EGetRegion, { page: 1, perPage: 10 });
		effect(NRegion, EGetArea, { page: 1, perPage: 200 });
		effect(NInsightType, EGetTypeSelect, { page: 1, perPage: 50 });
		effect(NOrganizationMulti, EGetAll, {
			page: 1,
			perPage: 10,
			id: state?.company,
		});
		return () => {};
	}, []);

	useEffect(() => {
		let page = sessionStorage.getItem("blogClientPage");
		let pageSize = sessionStorage.getItem("blogClientPageSize");
		if (state) {
			form.setFieldsValue({
				...state,
			});
			setSeachValue({ ...searchValue, ...state });
		}
		if (page && pageSize) {
			effect(NBlog, EGet, { page, pageSize });
		} else {
			effect(NBlog, EGet, { page: 1, perPage: 10, ...state });
		}
	}, []);

	const onPageChange = (page, pageSize) => {
		effect(NBlog, EGet, { page, perPage: 10 });
		sessionStorage.setItem("blogClientPage", page);
		sessionStorage.setItem("blogClientPageSize", pageSize);
	};
	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetAll, { name: value, page: 1, perPage: 10 });
	};
	const onSearchGame = (value) => {
		effect(NGame, EGetType, { ...{ name: value }, page: 1, perPage: 10 });
	};
	const onSearchGenre = (value) => {
		effect(NGenre, EGet, { ...{ genre: value }, page: 1, perPage: 10 });
	};
	const onSearchPlatform = (value) => {
		effect(NPlatform, EGet, { ...{ platform: value }, page: 1, perPage: 10 });
	};
	const onFinish = (values) => {
		if (values.date) {
			values.startDate = moment(values?.date[0])?.format("YYYY-MM-DD");
			values.endDate = moment(values?.date[1])?.format("YYYY-MM-DD");
		}
		values.area = values.area && [
			...values?.area?.map((areaValue) => {
				return commonAreaList?.data?.find(
					(item) => item?.otherData === areaValue
				)?.id;
			}),
			...values.area,
		];
		// values.platform = PlatformData?.data?.find(
		// 	(item) => item.lookupValue === values?.platform
		// )?.id;
		delete values?.date;
		effect(NBlog, EGet, { page: 1, perPage: 10, ...values });
	};

	const toDetails = (value) => {
		history.push(`/news_articles/blog_posts/details/${value}`);
	};
	const onReset = () => {
		sessionStorage.setItem("blogClientPage", 1);
		sessionStorage.setItem("blogClientPageSize", 10);
		setBigType(null);
		form.resetFields();
		effect(NBlog, EGet, { page: 1, perPage: 10 });
	};
	const [regionArr, setRegionArr] = useState([]);
	function findRegionType(arr) {
		const updatedRegionArr = [];
		areaList?.data?.forEach((item) => {
			if (arr?.includes(item?.otherData)) {
				updatedRegionArr?.push(item?.lookupValue);
			}
		});
		setRegionArr(Array.from(new Set(updatedRegionArr)));
		form.setFieldsValue({
			region: Array.from(new Set(updatedRegionArr)),
		});
	}
	const handleTypeSearch = (e) => {
		setBigType(e);
		effect(NInsightType, EGetTypeSelect, { page: 1, BigType: e });
	};
	function removeItalicsMarkdown(text) {
		let result = text
			.normalize("NFKD")
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "");
		return result;
	}

	return (
		<>
			{userInfo?.type == "Admin" ||
			(currentSubscribe?.[0]?.module.hasOwnProperty("BlogPosts") &&
				currentSubscribe?.[0]?.module["BlogPosts"]?.length > 0) ? (
				<div>
					<div
						className="BlogBox mt50"
						style={{
							backgroundColor: "#F7F9FB",
							padding: "40px 40px 20px 0px",
						}}
					>
						<Form
							name="advanced_search"
							className="ant-advanced-search-form"
							labelCol={{ span: 8 }}
							form={form}
							onFinish={onFinish}
						>
							<Row>
								<Col span={8}>
									<Form.Item name="area" label="Area">
										<Select
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											mode="multiple"
											placeholder="Please Search"
											allowClear
											showSearch
											onChange={(e) => {
												findRegionType(e);
											}}
											onSearch={debounce((value) => {
												const { page, perPage } = areaList;
												effect(NRegion, EGetArea, {
													page,
													perPage,
													area: value,
												});
											}, 300)}
											filterOption={false}
										>
											{sortData(areaList?.data, "area")?.map(
												(record, index) => {
													return (
														<Option key={record?.id} value={record?.otherData}>
															{record?.otherData}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="region" label="Region">
										<Select
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											mode="multiple"
											filterOption={false}
											placeholder="Please Search"
											allowClear
											showSearch
										>
											{regionList?.map((vo, index) => (
												<Option key={index} value={vo?.lookupValue}>
													{vo?.lookupValue}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="keyWord" label="Keywords">
										<Input placeholder="Please Enter" autoComplete="off" />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="date" label="Published Date">
										<RangePicker
											format="MMM DD, YYYY"
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											style={{ width: "100%" }}
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="type" label="Category">
										<Select
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											placeholder="Please Select"
											showSearch
											allowClear
											onChange={(value) => {
												handleTypeSearch(value);
											}}
										>
											{sortData(TypeList?.mianType, "type")?.map(
												(item, index) => {
													return (
														<Option key={item?.id || index} value={item?.type}>
															{item?.type}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="subType" label="Sub-Category">
										<Select
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											showSearch
											allowClear
											placeholder="Please Select"
											disabled={bigType ? false : true}
										>
											{sortData(TypeSelectlist?.subType, "type")?.map(
												(item, index) => (
													<Option key={item?.id || index} value={item?.subType}>
														{item?.subType}
													</Option>
												)
											)}
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="game" label="Game">
										<Select
											notFoundContent={null}
											showArrow={false}
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											placeholder="Please Search"
											allowClear
											showSearch
											filterOption={false}
											onSearch={debounce((value) => {
												onSearchGame(removeItalicsMarkdown(value));
											}, 100)}
										>
											{gameSearch &&
												sortData(gameSearch?.data, "gameEn")?.map(
													(record, index) => {
														return (
															<Option value={record?.id} key={index}>
																{record?.gameEn}
															</Option>
														);
													}
												)}
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="company" label="Organization">
										<Select
											allowClear
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											showSearch
											filterOption={false}
											placeholder="Please Select"
											onSearch={debounce((value) => {
												onSearchCompany(value);
											}, 300)}
										>
											{sortData(OrganizationAll?.data)?.map((item, index) => {
												return (
													<Option key={index} value={item?.id}>
														{item?.name}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
								</Col>

								<Col span={8}>
									<Form.Item name="genre" label="Genre">
										<Select
											allowClear
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											showSearch
											filterOption={false}
											placeholder="Please Select"
											onSearch={debounce((value) => {
												onSearchGenre(value);
											}, 300)}
										>
											{sortData(GenreList?.data, "genre")?.map(
												(item, index) => {
													return (
														<Option key={index} value={item?.id}>
															{item?.lookupValue}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="platform" label="Platforms">
										<Select
											allowClear
											getPopupContainer={(triggerNode) =>
												triggerNode?.parentNode
											}
											showSearch
											filterOption={false}
											placeholder="Please Select"
											onSearch={debounce((value) => {
												onSearchPlatform(value);
											}, 300)}
										>
											{sortData(PlatformData?.data, "platform")?.map(
												(record, index) => {
													return (
														<Option key={record?.id} value={record?.id}>
															{record?.lookupValue}
														</Option>
													);
												}
											)}
										</Select>
									</Form.Item>
								</Col>
								<Col span={16} style={{ textAlign: "right" }}>
									<Space>
										<Button type="primary" htmlType="submit" className="primarySearch">
											Search
										</Button>
										<Button type="default" htmlType="button" onClick={onReset}	className="secReset">
											Reset
										</Button>
									</Space>
								</Col>
							</Row>
						</Form>
					</div>

					<Spin spinning={loading} size="large">
						<div className="BlogBox">
							<Row className="RowBox mt30">
								<Col span={24}>
									<ul className="ProlistBox">
										{currentSubscribe?.[0]?.module["BlogPosts"]?.length > 0 ? (
											BlogList?.error == 1009 ||
											BlogList?.total > 0 || <Empty />
										) : (
											<></>
										)}

										{BlogList?.data.length > 0 ? (
											BlogList?.data?.map((vo, i) => {
												const date = new Date(vo?.date);
												const formattedDate = formatDate(date);
												return (
													<li key={i}>
														<div
															className="insight_list_cont"
															style={{ width: "100%" }}
														>
															<h3>
																<div>
																	<div
																		style={{ cursor: "pointer" }}
																		onClick={() => {
																			toDetails(vo?.id);
																		}}
																	>
																		{vo?.title}
																	</div>
																</div>

																<div className="insight_list_date_style">
																	{/* {moment(vo?.date).format("YYYY-MM-DD")} */}
																	{formattedDate}
																</div>
															</h3>
															<div className="Tag_List_Box">
																{vo?.withCompanys?.map((vi, ii) => (
																	<Tag
																		key={ii}
																		color="#9E219E"
																		onClick={() => {
																			history.push(
																				`/games_organizations/organizations/details/${vi?.id}`
																			);
																		}}
																		style={{
																			cursor: "pointer",
																			borderRadius: "20px",
																			marginBottom: ".3rem",
																		}}
																	>
																		{vi?.name}
																	</Tag>
																))}
																{vo?.withGames?.map((vi, iii) => (
																	<Tag
																		key={iii}
																		color="#19AE54"
																		value={vi?.id}
																		onClick={() => {
																			history.push(
																				`/games_organizations/games/details/${vi?.id}`
																			);
																		}}
																		style={{
																			cursor: "pointer",
																			borderRadius: "20px",
																			marginBottom: ".3rem",
																		}}
																	>
																		{vi?.gameEn}
																	</Tag>
																))}
															</div>
															<div
																className="insght_lst_info"
																onClick={() => {
																	toDetails(vo?.id);
																}}
															>
																{(() => {
																	const description = vo?.description?.replace(
																		/<[^>]+>/g,
																		""
																	);
																	const words = description?.split(" ");

																	if (words?.length > 50) {
																		return (
																			words?.slice(0, 50)?.join(" ") + "..."
																		);
																	} else {
																		return description;
																	}
																})()}
															</div>
														</div>
													</li>
												);
											})
										) : (
											<Empty />
										)}
									</ul>
									{/* </div> */}
								</Col>
								<Col span={24} style={{ textAlign: "right" }}>
									{BlogList?.error != 1009 && BlogList?.total
										? BlogList?.total && (
												<Pagination
													current={BlogList?.page || 1}
													total={BlogList?.total || 0}
													pageSize={BlogList?.perPage}
													onChange={onPageChange}
													showSizeChanger
													showQuickJumper
												/>
										  )
										: ""}
								</Col>
							</Row>
						</div>
					</Spin>
				</div>
			) : (
				customizeRenderEmpty()
			)}
		</>
	);
};
