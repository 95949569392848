import React, { useEffect } from "react";
import { Modal, Form, Input, Button, Row, Col } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import ".././styles.less";
export default (props) => {
	const [editForm] = Form.useForm();
	const { visible, onOk, onCancel, editItem } = props;
	useEffect(() => {
		if (visible) {
		} else {
			editForm?.resetFields();
		}
	}, [visible]);
	const onOks = async () => {
		try {
			await editForm?.validateFields();
			let values = editForm?.getFieldValue();
			delete values?.confirm;
			values.username = editItem?.username;
			onOk && onOk(values);
		} catch (e) {}
	};
	return (
		<Modal {...props}>
			<Form
				form={editForm}
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 12 }}
				className="change_Password"
			>
				<Form.Item
					label="Original Password"
					name="oldPassword"
					rules={[
						{
							required: true,
							message: "Please input your password!",
						},
					]}
				>
					<Input.Password
						placeholder="Input Password"
						iconRender={(visible) =>
							visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
						}
					/>
				</Form.Item>
				<Form.Item
					label="New Password"
					name="newPassword"
					rules={[
						{
							required: true,
							message: "Please input your password!",
						},
					]}
				>
					<Input.Password
						placeholder="Input Password"
						iconRender={(visible) =>
							visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
						}
					/>
				</Form.Item>
				<Form.Item
					label="Confirm Password"
					name="confirm"
					rules={[
						{
							required: true,
							message: "Please confirm your password!",
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue("newPassword") === value) {
									return Promise.resolve();
								}
								return Promise.reject(
									new Error("The two passwords that you entered do not match!")
								);
							},
						}),
					]}
				>
					<Input.Password
						placeholder="Input Password"
						iconRender={(visible) =>
							visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
						}
					/>
				</Form.Item>
			</Form>
			<Row style={{ marginTop: 28 }}>
				<Col style={{ margin: "0 auto" }}>
					<Button
						
						className="btn_1"
						htmlType="submit"
						onClick={() => {
							onCancel && onCancel();
						}}
					>
						Cancel
					</Button>
					<Button
						className="btn_2"
						style={{ marginLeft: 25 }}
						htmlType="submit"
						onClick={() => {
							onOks();
						}}
					>
						OK
					</Button>
				</Col>
			</Row>
		</Modal>
	);
};
