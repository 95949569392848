import { requestGet } from "../utils/dva16";
import { NOrganizationMultiType, EGet, RSetState } from "./contants";
const OrganizationMultiType = {
	namespace: NOrganizationMultiType,
	state: {
		OrganizationMultiTypeList: null,
	},
	reducers: {
		[RSetState](state, { payload }) {
			return {
				...state,
				...payload,
			};
		},
	},
	effects: {
		async [EGet]({ payload, callback }, { call, reducer, select, effect }) {
			let res = await requestGet("industry", payload);
			reducer(RSetState, { OrganizationMultiTypeList: res?.data });
		},
	},
};
export default OrganizationMultiType;
